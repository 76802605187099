import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Card, CardContent, Typography, Container, Grid, Link, CardMedia, List, ListItem, TextField, MenuItem, Hidden, ListItemText, Tooltip } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { getUrl } from '../helper/ApiAction';
import { useQuery } from '../helper/Tools';
import useNotificationLoading from '../helper/useNotificationLoading';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import WebpImg from './Layouts/WebpImg';
import TitleBar from './Layouts/TitleBar';


import { FiChevronLeft } from 'react-icons/fi';

export default function Categories() {
    const [collections, setCollections] = useState([]);
    const [categoryProducts, setCategoryProducts] = useState([]);
    // const [categories, setCategories] = useState(null);
    const [category, setCategory] = useState('all');
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);
    const history = useHistory();

    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    let { id } = useParams();
    let query = useQuery();
    const pageNumber = query.get("page");
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        // getUrl('categories?level=1').then(categoryList => {
        //     if (isMountedRef.current) {
        //         setCategories(categoryList.data);
        //     }
        // }).catch(error => {
        //     addAlert(JSON.stringify(error.message));
        // });

        getUrl(`collections/${id}/products?cat=${category}&page=${pageNumber ? pageNumber : 1}`)
            .then(response => {
                if (response) {
                    if (isMountedRef.current) {
                        let { current_page, last_page } = response.data.meta;
                        setCategoryProducts(response.data.data);
                        setTotal(last_page);
                        setPage(current_page ? current_page : 1);
                        setLoading(false);
                    }
                    setCollections(response.collection);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, pageNumber, addAlert, category]);


    const handleChange = (event, value) => {
        setPage(value);
        getUrl(`collections/${id}/products?cat=${category}&page=${value}`)
            .then(response => {
                if (response) {
                    let { last_page } = response.data;
                    setCategoryProducts(response.data.data);
                    setTotal(last_page);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
    };

    const handleCategoryChange = (event) => {
        setPage(1);
        setCategory(event.target.value);

        // history.push(`/collection/${id}?cat=${event.target.value}&page=1`);
        setLoading(true);
        getUrl(`collections/${id}/products?cat=${event.target.value}&page=1`)
        .then(response => {
            if (response) {
                if (isMountedRef.current) {
                    let { last_page } = response.data.meta;
                    setCategoryProducts(response.data.data);
                    setTotal(last_page);
                    setLoading(false);
                }
                setCollections(response.collection);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        history.push(`/collection/${id}`);
    };
    const handleCategoryChange2 = (event, selectedId) => {
        setPage(1);
        setCategory(selectedId);

        setLoading(true);
        getUrl(`collections/${id}/products?cat=${selectedId}&page=1`)
        .then(response => {
            if (response) {
                if (isMountedRef.current) {
                    let { last_page } = response.data.meta;
                    setCategoryProducts(response.data.data);
                    setTotal(last_page);
                    setLoading(false);
                }
                setCollections(response.collection);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        history.push(`/collection/${id}`);
    };

    const {currency,currencyRate,currencydisplay} = useSelector(state => state.general);
    let currency_rate = currencyRate;
    let currency_code = [];
    _.map(currencydisplay,(currency_detail,index)=>{
        if(currency_detail.code == currency){
            currency_code = currency_detail.desc.split('|');
        }
    });

    return (
        <Box className={styles.root2} >
            <TitleBar height={70} title={t('item.products')} displayInfo   currencyButton displayCart back backtext />
        
            <Box className='fixed-header-padding'>
                <Container fixed className={styles.breadcrumbRoot}>
                    <Grid container spacing={1} justify="center">
                        <Box style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <Grid item xs={12} sm={12} style={{ padding: '0 30px' }}>
                                {/* <Hidden mdUp>
                                    <TextField style={{marginTop:'20px'}}
                                        id="outlined-select-currency"
                                        select
                                        value={category}
                                        onChange={handleCategoryChange}
                                        // variant="outlined"
                                        fullWidth
                                    >
                                        <MenuItem key="all" value="all">
                                            {i18n.language === 'cn' ? "所有" : "All"}
                                        </MenuItem>
                                        {
                                            _.size(categories) > 0 && categories !== null ?
                                                _.map(categories, (category) => {
                                                    const category_translate = _.split(category.cat_name, '|');
                                                    return (
                                                        <MenuItem key={category.id} value={category.id}>
                                                            {i18n.language === 'cn' ? (category_translate[1] ? category_translate[1] : category_translate[0]) : category_translate[0]}
                                                        </MenuItem>
                                                    )
                                                })
                                                :
                                                <MenuItem key={0} value=""></MenuItem>
                                        }
                                    </TextField>
                                </Hidden> */}
                                <List>
                                    <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <Typography color="primary" style={{ fontWeight: 'bold', fontSize:24 }}>{ collections[`title_${i18n.language}`] }</Typography>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Box>
                    </Grid>
                </Container>
                <Container className={styles.root}>
                    <Grid container justify="center">
                        {/* <Grid xs md lg item display={{ xs: 'none', md: 'block' }} component={Box}>
                            <ThemeProvider theme={hoverSelTheme}>
                                <List component="nav" aria-label="categories">
                                    <ListItem
                                        button
                                        selected={category === 'all' ? true : false}
                                        onClick={(event) => handleCategoryChange2(event, 'all')}
                                    >
                                        <ListItemText primary={ t('cart.all') } />
                                    </ListItem>
                                    {
                                        _.size(categories) > 0 && categories !== null ?
                                            _.map(categories, (category_details) => {
                                                const category_translate = _.split(category_details.cat_name, '|');
                                                return (
                                                    <ListItem
                                                        button
                                                        key={category_details.id}
                                                        selected={parseInt(category) === category_details.id}
                                                        onClick={(event) => handleCategoryChange2(event, category_details.id)}
                                                    >
                                                        <ListItemText primary={i18n.language === 'cn' ? (category_translate[1] ? category_translate[1] : category_translate[0]) : category_translate[0]} />
                                                    </ListItem>
                                                )
                                            })
                                            :
                                            null
                                    }
                                </List>
                            </ThemeProvider>
                        </Grid> */}

                        <Grid xs={12} item container spacing={1} justify="center">
                            {categoryProducts.length > 0 ?
                                <Grid item xs={12} sm={12} style={{ padding: '0 20px' }}>
                                    <Grid container spacing={1}>
                                        {_.map(categoryProducts, (product) => {
                                            let showRetail = false;
                                            let discountPercent = 0;
                                            if(parseFloat(product.retail_price) > 0 && parseFloat(product.retail_price) > parseFloat(product.sell_price)) {
                                                showRetail = true;
                                                discountPercent = ((parseFloat(product.retail_price) - parseFloat(product.sell_price)) / parseFloat(product.retail_price)) * 100;
                                                discountPercent = Math.round(discountPercent);
                                            }
                                            const title_translate = _.split(product.title, '|');
                                            return (
                                                <Grid item xs={6} sm={6} key={product.id} className={styles.cardContentRootStyle}>
                                                    <Link underline='none' to={`/item/${product.id}`} component={RouterLink}>
                                                        <Card className='productHover' variant="outlined" >
                                                            <CardMedia style={{ padding: 10, textAlign: "center" }}>
                                                                {/* <WebpImg src={product ? product.images_array[0] : ''} style={{ height: 150 }} /> */}
                                                                <WebpImg
                                                                    containerStyle={{ height: 150,  maxHeight: 150, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                                    src={product ? product.images_array[0] : ''}
                                                                    style={{ width: 'fit-content', borderRadius:15, padding: '0', display: 'block', objectFit: 'cover', height: 195, maxHeight:150, margin: "auto" }}
                                                                    alt={product[`title_${i18n.language}`]}
                                                                />        
                                                                {/* {
                                                                    product.user_wishlist ?
                                                                    <FavoriteIcon fontSize="small" style={{ color: 'red', position: 'absolute', right: 10 }} />
                                                                    : null
                                                                }                                                 */}
                                                            </CardMedia>
                                                            <CardContent style={{ padding: '10px', textAlign: "center" }}>
                                                                <Tooltip title={product[`title_${i18n.language}`]}>
                                                                    <Typography variant="body2" color="textSecondary" noWrap style={{}}>
                                                                        {product[`title_${i18n.language}`]}
                                                                    </Typography>
                                                                </Tooltip>
                                                                <div style={{display:'flex', justifyContent:'space-around', alignItems:'center', marginTop:8}}>
                                                                {/* { _.size(product.ipoint) > 0 && parseFloat(product.ipoint[0].ipoint) > 0 ? 
                                                                    <div style={{ marginLeft: 2, marginRight: 2, backgroundColor: theme.palette.primary.main, width:'49%', borderTopLeftRadius: 11, borderBottomRightRadius: 11, boxShadow:'2px 3px 2px 0 #ead4d4' }}>
                                                                        <Typography style={{ color: '#fff', fontSize: '.625rem', padding:'3px 5px' }} variant="body2">
                                                                            <NumberFormat value={parseFloat(product.ipoint[0].ipoint)} decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` IP`} />
                                                                        </Typography>
                                                                    </div>
                                                                : null } */}
                                                                {/* { _.size(product.bv) > 0 && parseFloat(product.bv) > 0 ? 
                                                                    <div style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#fa9f2d', width:'49%', borderTopLeftRadius: 11, borderBottomRightRadius: 11, boxShadow:'2px 3px 2px 0 #ffe3bf' }}>
                                                                        <Typography style={{ color: '#fff', fontSize: '.625rem', padding:'3px 5px' }} variant="body2">
                                                                            <NumberFormat value={parseFloat(product.bv)} decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` BV`} />
                                                                        </Typography>
                                                                    </div>
                                                                : null } */}
                                                                </div>
                                                                {
                                                                    showRetail
                                                                    ?
                                                                    <Typography align="center" variant="body2" style={{ fontSize: 10, height:18, fontWeight: 'bold', textDecoration: 'line-through' }}>
                                                                        <NumberFormat value={product.retail_price*currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                    </Typography>
                                                                    :
                                                                    <Typography style={{ height: 18 }}></Typography>
                                                                }
                                                                <Typography align="center" variant="body1" color="secondary" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                                                    <NumberFormat value={product.sell_price*currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                </Typography>
                                                            </CardContent>
                                                            {
                                                                showRetail ?
                                                                <Box position="absolute" top={0} right={0} style={{ backgroundColor: 'rgba(255,212,36,.9)' }} padding={1}>
                                                                    <Typography variant="caption" style={{ fontWeight: 'bold' }} color="error">{`${discountPercent}% ${t('general.discount')}`}</Typography>
                                                                </Box>
                                                                : null
                                                            }
                                                            {/* <Box display="flex" alignItems="center" paddingY={.5} marginBottom={1.5} justifyContent="center" style={{ width: '100%', backgroundColor:'#f75757' }}>
                                                            {
                                                                _.map(product.ipoint, (idata, key) => {
                                                                    if(idata.warehouse_id === 0){
                                                                        return (
                                                                            <Typography key={key} style={{ color: '#fff', fontSize:16,  }} variant="body2"><NumberFormat value={ parseFloat(idata.ipoint) } decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` i-Point`} /></Typography>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            </Box> */}
                                                        </Card>
                                                    </Link>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    <Grid container spacing={1} justify="center" >
                                        <Pagination count={total} shape="rounded" page={page}
                                            onChange={handleChange}
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    component={RouterLink}
                                                    to={`/collection/${id}${item.page === 1 ? '' : `?page=${item.page}`}`}
                                                    {...item}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                :
                                // <h1>{t('product.noResult')}</h1>
                                <img src='/images/no-image.png' style={{width:120}}/>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0px 30px 0px',
    },
    cardContentRootStyle: {
        padding: '10px !important',
    },
    root2: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg,
        // padding:'0 0 80px',
        minHeight: '100%'
    }
}));

const hoverSelTheme = createMuiTheme({
    overrides: {
        MuiListItem: {
            button: {
                color: '#797979',
                // backgroundColor: '#222',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    color: '#000000',
                    borderRadius:'0 24px 24px 0',
                },
            },
            root:{
                "&$selected": {
                    backgroundColor: '#113052',
                    color: '#fff',
                    borderRadius:'0 24px 24px 0',
                },
            },
        },
    },
});

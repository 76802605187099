export const ENV = 'liv'; // 'dev';
export const APP_NAME = 'Mstore';
export const PROJECT_TYPE = 'mall'; // 'mall/catalog';

export const BASE_URL = ENV === 'liv' ? '' : '';

const BACKEND_URL_LIST = {
    liv: `https://api.mstore.shopping`,
    stage: `https://dapi.mstore.shopping`,
    dev: 'http://127.0.0.1:8000',
};

const LOCAL_URL_LIST = {
    liv: `https://www.mstore.shopping`,
    stage: `https://dmall.mstore.shopping`,
    dev: `http://localhost:3000`,
};

const SELLER_URL_LIST = {
    liv: 'https://seller.mstore.shopping/',
    stage: 'https://dseller.mstore.shopping/',
    dev: 'http://localhost:3005/',
};

export const BACKEND_URL = BACKEND_URL_LIST[ENV];
export const LOCAL_URL = LOCAL_URL_LIST[ENV];
export const SELLER_URL = SELLER_URL_LIST[ENV];

export const CLIENT_SECRET = 'EykDL0NJzr2GydtmeMnTC2DI5xjwRUAv6adPEBes';

export const API_URL = `${BACKEND_URL}/api/`;

export const EXTERNAL_MEMBER_SITE = ENV === 'liv' ? 'https://mib88.com/' : 'https://mib88.com/';
export const EXTERNAL_LOGIN = ENV === 'liv' ? 'https://mib88.com/live/login-authentication?src=mib_mall' : 'https://mib88.com/demo/login-authentication?src=mib_mall';
export const EXTERNAL_ABOUTUS = ENV === 'liv' ? 'https://mib88.com/' : 'https://mib88.com/';

export const UPAY_URL = ENV === 'liv' ? 'https://www.upay2us.com/iServeGateway/transaction_window' : 'https://uat.upay2us.com/iServeGateway/transaction_window';
export const PAYPAL_URL = ENV === 'liv' ? 'https://www.paypal.com/sdk/js?client-id=Aaq7A28-Kgp1PF3nV9ITbTVYLrmMXa9N724dc1TxG_ggQt1BrSotwXY9zimouxKPofAfGf2VTsZVc7uz&disable-funding=credit,card&currency=USD' : 'https://www.paypal.com/sdk/js?client-id=ATmcw_7BZ11ecNZBgC8F-UHP93-aj2wuKEsgJV4EpGhxbH-TVlvCpxPecWEd-WgZ6L74vwWNayzzlqIh&disable-funding=credit,card&currency=USD';
export const STRIPE_KEY = ENV === 'liv' ? 'pk_live_51H7jIYE5DDnYa3UmpwPlgUV8zyM85kdyd4Gm6xPyiUXLMGD5RA8YSgqmssynS8moH4Y89Pi9JQ9uJ0b7afn6T9Qh007vZw5Jsv' : 'pk_test_51H7jIYE5DDnYa3UmBqHIcm1taXM6ecCgfdZDo7w2Ly0sXkKiv2l3b6bbZyqD0Ebqb13TMM1BsFD6DtBMVJ9rt6CU00vZ9Kcxme';

export const CONTACT_US_EMAIL = 'info@mstore.shopping';

export const PUSHER_KEY = ENV === 'liv' ? '300a8ddcd263bda445fc' : '4055f5d563df469ce07a';
export const PUSHER_CLUSTER = 'ap1';
export const PUSHER_AUTH = `${BACKEND_URL}/broadcasting/auth`;
export const PUSHER_MESSAGE_CHANNEL = 'chat';
export const PUSHER_MESSAGE_EVENT = 'MessageSent';

export const FACEBOOK_APPID = "616126255894876"; // 1neway
export const DEFAULT_LANGUAGE = "en"; // "en"
export const DEFAULT_CURRENCY = "USD"; // "USD"

export const LOGIN_SECRET_KEY = "VSgwUGPVQvN4z88s0hUVo9mb";

export const ISHOPPING_LOGIN = ENV === 'liv' ? 'https://www.i-shopping.app/login?src=mistore' : 'https://dmall.i-shopping.app/login?src=mistore';

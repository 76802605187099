import _ from 'lodash';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { Box, Button, Dialog, DialogContent, Link, List, ListItem, ListItemText, Slide, Typography } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme, makeStyles, useTheme } from '@material-ui/core/styles';

import { getUrl } from '../helper/ApiAction';
import { currencyFormat, stringToLang } from '../helper/Tools';
import useNotificationLoading from '../helper/useNotificationLoading';

import TitleBar from './Layouts/TitleBar';
import {FiArrowRight, FiChevronRight} from "react-icons/fi";

export default function Setting() {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogInfo, setDialogInfo] = useState([]);
    const [dialogInfoDetail, setDialogInfoDetail] = useState([]);
    const [recipient, setRecipient] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [maxCap, setMaxcap] = useState(0);
    const [listing, setListing] = useState([]);
    const [totalProfitLimit, setTotalProfitLimit] = useState({profit_limit: 0, profit_limit_balance: 0});

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const { addAlert } = useNotificationLoading();
    const { walletList } = useSelector(state => ({
        walletList: state.wallet.walletList,
    }));

    const isMountedRef = useRef(null);

    const getApi = (page=1) => {
        getUrl(`user/get_order_with_profit?page=${page}&per_page=10`).then(response => {
            if(response.status && isMountedRef.current) {
                const { last_page, data } = response.data;
                setTotalPage(last_page);
                if(page === 1){
                    setListing(data);
                }else{
                    setListing(_.concat(listing, data));
                }
                setTotalProfitLimit(response.total_profit_limit || 0);
            }
        }).catch(err => {
            addAlert(JSON.stringify(err.message));
        })

        getUrl(`user/total/profit_limit`).then(response => {
            if(response.status && isMountedRef.current) {
                if(response.status === 1){
                    setMaxcap(response.data)
                }
                
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })

    }
    useEffect(() => {
        isMountedRef.current = true;

        getApi(page);
        
        return () => isMountedRef.current = false;
    }, [page]);

    const loadTransaction = () => {
        setPage(page + 1);
    }

    const openDialog = dialogInfo => {
        setDialogInfo(dialogInfo);
        setDialogInfoDetail(dialogInfo.order)
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogInfo([]);
        setDialogOpen(false);
    }

    const combinationIdLabel = (_detail) => {
        let label = "";
        let labels = [];
        _.map(_detail, (_amount, _wallet) => {
            let existWallet = _.find(walletList, { code: _wallet });
            label += label ? " + " : "";
            let decimal_cal = existWallet?.decimal ? 10**existWallet.decimal : 100;
            let rounded_amount = Math.round(_amount*decimal_cal)/decimal_cal;
            label += ((existWallet?.wallet_name || _wallet) + ": "+currencyFormat(rounded_amount));

            labels.push(
                <React.Fragment key={_wallet}>
                    <p>{(existWallet?.wallet_name || _wallet)}</p>
                    <p className='txt-right'>{currencyFormat(rounded_amount)}</p>
                </React.Fragment>
            )
        })
        return labels;
    }


    return (

        <Box style={{ background: theme.palette.white.mobileBkg, paddingBottom: 80, minHeight: '100%' }}>
            <TitleBar height={70} title={t('title.package')} displayInfo displayQr currencyButton displayCart linkMall back pagetitle />
            
            <Box className={classes.root} style={{ padding: '100px 20px 30px', minHeight: '100%' }}>
                <div style={{ padding: '8px 5px' }}>
                    <div className='package-card shadow-out p-all-15 w-full flex-c-m'>
                        <div className='txt-center clwhite txt-upper'>
                            <p className='fs-25 p-b-5 m-all-0'><b>{Number(maxCap).toFixed(2)}</b></p>
                            <p className='txt-center clwhite m-all-0'>{t('package.availablePoint')}</p>
                            {
                                (maxCap>0) ? 
                                <Link underline='none' to={"/cap-convert"} component={RouterLink} >
                                <Button variant="contained" className='button-theme' style={{ width: 350, maxWidth: '50%', boxShadow: 'rgb(255 255 255 / 56%) -3px -3px 3px 0px, rgba(0, 0, 0, 0.19) 3px 3px 4px 0px', borderRadius:48, marginTop: 10 }} >
                                    {t('wallet.convert')}
                                </Button>
                                </Link> : null
                            }
                        </div>
                    </div>
                </div>

                <Box className='p-tb-16'>
                    <p className='fs-18 p-b-5 m-all-0' >{t('title.transaction')}</p>
                    <List className={classes.listRoot}>
                        {_.map(listing, transactionItem => (
                            <TransactionItem key={transactionItem.id} data={transactionItem} openDialog={openDialog} />
                        ))}
                        {/* <ListItem onClick={openDialog} className='flex-m shadow-out m-b-15' style={{ cursor: 'pointer', background:'#f2f5f9', borderRadius: 10, padding: 15 }} >
                            <Box className='w-full'>
                                <div className='flex-sb-m fs-15 w-full'>
                                    <p className='p-b-8 m-all-0 p-r-15'><b>Package Name Here</b></p>
                                    <FiChevronRight/>
                                </div>
                                <div className='flex-sb-m fs-15 w-full' style={{ color: theme.palette.text.primary }}>
                                    <p className='p-b-8 m-all-0'>{t('package.reentryCap')}</p>
                                    <p className='txt-right p-b-8 m-all-0'>350 / 1500</p>
                                </div>
                                <div className='flex-sb-m fs-15 w-full'>
                                    <p className='txt-upper m-all-0'>Username Here</p>
                                    <p className='txt-right fs-12 m-all-0'>2023-05-25 12:06:50</p>
                                </div>
                            </Box>
                        </ListItem> */}
                    </List>
                    {page < totalPage ?
                        <Button onClick={loadTransaction} fullWidth>{t('transaction.loadMore')}</Button>
                        : <Typography style={{ textAlign: 'center' }}>{t('transaction.endOfList')}</Typography>
                    }
                </Box>
                <MuiThemeProvider theme={DialogStyle}>
                    <Dialog
                        open={dialogOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        fullWidth
                        onClose={closeDialog}
                        style={{ padding: '0 15px' }}
                    >
                        <div style={{ background: '#e8e9f3' }}>
                            <DialogContent className='w-full max-w-full p-t-20 dialog-text' style={{ width: 350, maxWidth: '100%', paddingBottom: 0, }}>

                                <center><img src="/images/logo-mistore.png" alt="logo-icon" style={{ width: 60 }} /></center>
                                <p className='fs-21 txt-center p-b-15' style={{ color: theme.palette.primary.main }}><b>{t('package.package')} {dialogInfo.title_display}</b></p>

                                <div className='w-full' style={{ borderTop: '1px dashed #9ea1b7' }} />

                                <div className='p-tb-15' style={{ color: theme.palette.text.primary }}>
                                    <div className='flex-sb-m w-full p-b-15'>
                                        <p><b>{t('package.subcriptonId')}</b></p>
                                        <p>{dialogInfoDetail.order_no}</p>
                                    </div>
                                    <div className='flex-sb-m w-full p-b-15'>
                                        <p><b>{t('package.username')}</b></p>
                                        <p style={{ textTransform: "uppercase" }}>{dialogInfo.title_display}</p>
                                    </div>
                                    <div className='flex-sb-m w-full p-b-15'>
                                        <p><b>{t('package.createDate')}</b></p>
                                        <p>{dialogInfo.created_at}</p>
                                    </div>
                                    <div className='w-full' style={{ borderTop: '1px dashed #9ea1b7' }} />
                                    <div className='flex-sb-m w-full p-tb-15'>
                                        <p><b>{t('package.reentryCap')}</b></p>
                                        <p>+ {dialogInfo.profit_limit}</p>
                                    </div>
                                    <div className='w-full' style={{ borderTop: '1px dashed #9ea1b7' }} />
                                    <div className='flex-sb-m w-full p-b-15 p-t-5'>
                                        <p><b>{t('package.total')}</b></p>
                                        <p>{Number(dialogInfo.price*dialogInfo.quantity).toFixed(2)}</p>
                                    </div>

                                    {/* <p style={{ lineHeight: 1.3, paddingBottom: 10 }}>
                                    <b>{t('package.reentryCap')}</b><br />
                                    {currencyFormat(dialogInfo.profit_limit-dialogInfo.profit_limit_balance)}/{currencyFormat(dialogInfo.profit_limit)}
                                </p> */}
                                </div>
                            </DialogContent>
                            <Box classes={{ root: classes.cancelBox }} style={{ paddingBottom: 20 }}>
                                <Button variant="contained" className='button-theme' style={{ width: 350, maxWidth: '50%', boxShadow: 'rgb(255 255 255 / 56%) -3px -3px 3px 0px, rgba(0, 0, 0, 0.19) 3px 3px 4px 0px', borderRadius:48 }} onClick={closeDialog}>
                                    {t('transaction.done')}
                                </Button>
                            </Box>
                        </div>
                        <div style={{ marginTop: -2 }}>
                            <img src="/images/general/receipt/receipt.png" alt="receipt-texture" className='w-full' />
                        </div>
                    </Dialog>
                </MuiThemeProvider>
            </Box>
        </Box>
    );
}


const TransactionItem = ({ data, openDialog }) => {
    const { title_display ,profit_limit,profit_limit_total,created_at,order} = data;
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    return (
        <ListItem onClick={() => openDialog(data)} className='flex-m shadow-out m-b-15' style={{ cursor: 'pointer', background: '#f2f5f9', borderRadius: 10, padding: 15 }} >
            <Box className='w-full'>{/* className={classes.listItemTextRoot} */}
                <div className='flex-sb-m fs-15 w-full'>
                    <p className='p-b-8 m-all-0'><b>{title_display}</b></p>
                </div>
                <div className='flex-sb-m fs-15 w-full' style={{ color: theme.palette.text.primary }}>
                    <p className='p-b-8 m-all-0'>{t('package.reentryCap')}</p>
                    <p className='txt-right p-b-8 m-all-0'>{profit_limit}/{profit_limit_total}</p>
                </div>
                <div className='flex-sb-m fs-15 w-full'>
                    <p className='txt-upper m-all-0'>{order['recipient']?order['recipient']['username']:null}</p>
                    <p className='txt-right fs-12 m-all-0'>{created_at}</p>
                </div>
            </Box>
        </ListItem>
    )
}

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 3,
    },
    buttonRoot: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 10,
        marginTop: 15, 
        marginLeft: 20,
        marginRight: 20,
        "&:hover":{
            backgroundColor: theme.palette.secondary.secondary,
        }
    },
    buttonRoot2: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        borderRadius: 10,
        marginTop: 0, 
        marginLeft: 20,
        marginRight: 20,
        "&:hover":{
            backgroundColor: theme.palette.primary.secondary,
        }
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        fontWeight:'bold',
        textTransform:'uppercase',
        padding:'0 10px',
    },
    remarkStyle:{
        color: theme.palette.silver.text,
        fontSize: 15,
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        width:'100%',
        overflow:'hidden'
    },
    freeremarkStyle:{
        fontSize: 15,
        display: '-webkit-box',
        fontWeight: 'bold',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        width:'100%',
        overflow:'hidden'
    },
    balanceStyle: {
        fontSize: 30, 
        color: "white",//theme.palette.secondary.main, 
        fontWeight: 'bold',
        textAlign:'center',
        textTransform:'uppercase'
    },
    listRoot: {
        width: '100%',
    },
    listItemTextRoot: {
        flex: 4
    },
    listItemAmountRoot: {
        // flex: 1,
        // display: 'flex',
        // justifyContent: 'flex-end',
        //alignSelf: 'center',
        textAlign:'right',
    },
    listItemAmountPrimary: {
        fontWeight: 'bold',
        fontSize: 16
    },
    listItemTextPrimary: {
        fontSize: 14,
        fontWeight:'bold',
    },
    listItemTextSecondary: {
        fontSize: 10,
    },
    listItemRoot: {
        cursor: 'pointer'
    },
    cancelBox: {
        marginLeft: 20,
        marginRight: 20,
        display: "flex",
        justifyContent: "center",
    },
    yesButton: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        borderRadius: 20,
        marginLeft: 10,
        marginRight: 10,
        width: "50% !important",
        "&:hover":{
            backgroundColor: theme.palette.primary.secondary,
        }
    },
    noButton: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 20,
        marginLeft: 10,
        marginRight: 10,
        width: "50% !important",
        "&:hover":{
            backgroundColor: theme.palette.secondary.secondary,
        }
    },
    watermark: {
        position: 'absolute',
        opacity: 0.2,
        fontSize: '8em',
        width: '100%',
        zIndex: 1,
        color: '#bbbbbb',
        height: '100%',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        transform: 'rotate(-45deg)',
        pointerEvents: 'none'
    }
}));

const DialogStyle = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
               width:420,
               maxWidth:'100%',
               margin:'0 auto',
               backgroundColor:'rgba(0, 0, 0, 0.7)'
            },
           
        },
        MuiDialog: {
            paper: {
                width: 320,
                maxWidth:'90%',
                minHeight:'24vh',
                padding: '0px 0 0',
                borderRadius: '15px 15px 0 0',
                background:'transparent'
            },
            paperFullWidth: {
                width: 320,
                maxWidth: '85%',
                margin: '0 auto'
            }
        },
        MuiDialogContent:{
            root: {
                padding:'8px 24px 24px'
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#fff',
                position: 'absolute',
                top: 10,
                right: 10,
                width: 25,
                height: 25,
                background: '#0008',
                borderRadius: 48,
                minWidth: 'unset',
                padding: 0,
            }
        },
        MuiPaper: {
            elevation24:{
                boxShadow:'none',
                filter:'drop-shadow(7px 5px 5px #0005)'
            }
        }
        
    },
});


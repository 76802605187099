import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import useNotificationLoading from '../helper/useNotificationLoading';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, Button, Grid, Box, Typography, Link, Modal, Backdrop, Fade, } from '@material-ui/core';

import TitleBar from './Layouts/TitleBar';
import WebpImg from './Layouts/WebpImg';

import { FiCopy, FiSettings } from 'react-icons/fi';
import './css/styles.css';


export default function Recruit() {

    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const shareLink = useRef(null);
    const { id, max_rank } = useSelector(state => state.user);
    const { addAlert } = useNotificationLoading();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const copyShareLink = () => {
        if(max_rank.code>0 || true){
            var textField = document.createElement('textarea');
            textField.innerText = `${window.location.origin}/register?r=${btoa(id)}`;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
    
            addAlert(t('profile.copySuccess'), "success");
        }
    };
    
    return (
        <Box className={styles.root} >
            <TitleBar height={70} title={t('profile.recruit')} displayInfo   currencyButton displayCart logoIcon />
            <div className='fixed-header-padding'>
            <Link underline='none' to="/placement-setting" target='blank' component={RouterLink} style={{ color: 'black' }} >
                <div style={{ padding: '20px 30px 0', width:'100%', display:'flex', justifyContent:'flex-end', alignItems:'center' }}>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', width:'fit-content'}}>
                        <div className={styles.settingIcon}>
                            {/* <FiSettings style={{ fontSize: 28 }} /> */}
                            <img src="/images/general/icon/invite.png" alt="card" style={{ width: '100%', borderRadius: 15 }} />
                        </div>
                        
                        {/* <Typography style={{ textAlign: 'center', fontSize: 14, color:'#777', paddingTop:5, textTransform:'uppercase'}}><b>{t('title.group')}</b></Typography> */}
                        <Typography style={{ textAlign: 'center', fontSize: 14, color:'#777', paddingTop:5, textTransform:'uppercase'}}><b>{t('profile.settings')}</b></Typography>
                    </div>
                </div>
            </Link>
            <div className={styles.letterPosition}>
                <div style={{position:'relative'}}>
                    <img src="/images/general/invite/invitation-full.png" alt="invite" style={{ width:'100%', bottom:0}}/>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', position: 'absolute', bottom: 15 }}>
                        <div className={styles.paperStyle}>
                            <Typography className={styles.inviteTitle}>{t('eventPromo.inviteFriends')}</Typography>
                            <div style={{ color: theme.palette.text.dtext, display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 15,  borderRadius: 15, boxShadow:'0 0 6px 0 #0003', width:'100%', }}>
                                <Typography style={{ textAlign: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', }} ref={shareLink} >{(max_rank.code > 0 || true) ? `${window.location.origin}/register?r=${btoa(id)}` : t('eventPromo.activeUserOnly')}</Typography>
                                <FiCopy style={{ fontSize: 20 }} onClick={copyShareLink} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src="/images/general/invite/invitation-front2.png" alt="invite" style={{ width: '100%', bottom: 8, position: 'absolute' }} />
                    </div>
                    <div className={styles.shareButtonPosition}>
                        <div style={{ padding: '10px 15px', width: 150, maxWidth: '75%', margin: '0 auto'}} className='red-button-style' onClick={copyShareLink} >
                            <Typography style={{ textAlign: 'center', fontSize: 18 }}><b>{t('eventPromo.inviteNow')}</b></Typography>
                        </div>
                    </div>
                </div>
                <Modal
                    className={styles.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={styles.paper}>
                            <div style={{ textAlign: "center" }}>
                                <h2 style={{ marginBottom: 0 }}>{t('eventPromo.tncTitle')}</h2>
                                <p style={{ marginTop: 5 }}>
                                    <b>{t('eventPromo.tncDes')}</b>
                                </p>
                            </div>
                            <p>{t('eventPromo.tncDes1')}</p>
                            <p>{t('eventPromo.tncDes2')}</p>
                            <p>{t('eventPromo.tncDes3')}</p>
                            <p>{t('eventPromo.tncDes4')}</p>
                            <p><i><i style={{ paddingTop: 5 }}>{t('eventPromo.tncDes5')}</i></i></p>
                        </div>
                    </Fade>
                </Modal>
            </div>
            </div>
        
        </Box>

    )
}

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      paperStyle: {
        width: 324,
        maxWidth: '80%',
        height: 331,
        borderRadius: 15,
        boxShadow: 'rgba(0, 0, 0, 0.2) 3px 6px 9px 0px',
        padding:20,
        background: '#fafcff',
        borderTop: '2px solid #ffffff',
        borderLeft: '2px solid #ffffff',
        [theme.breakpoints.down('xs')]: {
            height: 307,
        },
      },
      inviteTitle:{
        fontSize:21,
        color:theme.palette.gray.dtext,
        textAlign:'center',
        fontWeight:'bold',
        paddingBottom:15
      },
      settingIcon:{
        borderRadius: 15,
        boxShadow: '0 0 6px 0 #0003',
        padding: 10,
        width: 60,
        height: 60,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        color:theme.palette.primary.main
    },
    shareButtonPosition:{
        position:'absolute',
        bottom:152,
        width:'100%',
        [theme.breakpoints.down('xs')]: {
            bottom: 130,
        },
    },
    letterPosition:{
        [theme.breakpoints.down('xs')]: {
            padding: 35,
        },
        [theme.breakpoints.up('sm')]: {
            padding: '77px 50px 0',
        },
    },
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        borderRadius:15,
        boxShadow: theme.shadows[5],
        padding: 20,
        width: 480,
        maxWidth: '80%',
        margin: '0px auto',
      },

    root: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg, 
        padding:0,
        minHeight: '100%'
    },

    containerPadding: {
        
        [theme.breakpoints.down('xs')]: {
            padding:'80px 20px 0'
        },
        [theme.breakpoints.up('sm')]: {
            padding:'80px 40px 0'
        },    
    },

    // prizePosition: {
    //     position: 'absolute',
    //     left: 0,
    //     width:'100%',    
    // },
    titleFontSize:{
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 22,
        },
    },
    descriptionFont:{
        fontSize: 14,
        maxWidth: '83%',
        margin: '0px auto',
        color: '#dedede',
        width: 350,
    },
}));
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { storeProfile } from '../actions';

import { Link as RouterLink } from 'react-router-dom';
import { TextField, Button, Typography, Grid, Box, Divider, List, ListItem, Link, Snackbar, Card, CardContent, FormHelperText } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import _ from 'lodash';
import { putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

import TitleBar from './Layouts/TitleBar';
import './css/styles.css';

export default function ProfileBankInfo() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();

    const { bank_account, bank_holder_name, bank_name, bank_swift} = useSelector(state => state.user);
    const [profileFormState, setProfileFormState] = useState({ bank_account, bank_holder_name, bank_name, bank_swift });
    const [errorFormState, setErrorFormState] = useState({
        bank_account: '',bank_info: '', bank_swift: '', bank_holder_name: ''
    });
    const [state, setState] = useState({
        snackbarShow: false,
        error: false,
        message: '',
    });

    const [disabledState, setDisabledState] = useState({
        bank_holder_name: false
    });

    const {  setLoading } = useNotificationLoading();

    useEffect(() => {
        if(bank_holder_name){
            setDisabledState({ ...disabledState, bank_holder_name: true });
        }
        // eslint-disable-next-line
    }, [bank_holder_name]);

    const handleInfoChange = (event) => {
        const name = event.target.name;
        setProfileFormState({ ...profileFormState, [name]: event.target.value });
    }

    const updateInfo = () => {
        setLoading(true);
        putUrl('user/bank', profileFormState).then(result => {
            if(result.error) {
                setLoading(false);
                setState( state => ({ ...state, snackbarShow: true, error: true, message: result.message }) );
                let allErrorField = [];
                _.map(result.error, (errorItem, errorField) => {
                    allErrorField[errorField] = errorItem[0];
                })
                setErrorFormState(allErrorField);
            } else {
                setLoading(false);
                setState( state => ({ ...state, snackbarShow: true, error: false, message: result.message }) );
                dispatch(storeProfile(result.data));
                setErrorFormState({});
            }
        })
    }

    return (
        <Box className={styles.root} style={{ padding: '0 0 80px', }}>
            <TitleBar height={70} title={t('profile.editBankInfo')} displayInfo back currencyButton displayCart />
            <div className='fixed-header-padding'>
                <Grid container spacing={1} justify="center" style={{ padding: '20px 15px 0' }}>
                    <Grid item xs={12}>
                        <List className={styles.listStyle} style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
                            <ListItem style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center' }}>
                                <Box style={{ color: theme.palette.primary.main }}>
                                    <Typography variant="h6">
                                        {t('profile.bankInfo')}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        {t('profile.bankInfoSubtitle')}
                                    </Typography>
                                </Box>
                            </ListItem>
                            <Divider variant="middle" light />

                            <div style={{ padding: '20px 15px' }}>
                                <div>
                                    <Typography className={styles.textFieldTitle}>{t('profile.bankName')}</Typography>
                                    <TextField
                                        error={errorFormState.bank_name ? true : false}
                                        helperText={errorFormState.bank_name}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ name: 'bank_name' }}
                                        onChange={handleInfoChange}
                                        value={profileFormState.bank_name ?? ""}
                                    />
                                </div>
                                <div style={{ paddingTop: 25 }}>
                                    <Typography className={styles.textFieldTitle}>{t('profile.bankHolderName')}</Typography>
                                    <TextField
                                        error={errorFormState.bank_holder_name ? true : false}
                                        helperText={errorFormState.bank_holder_name}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ name: 'bank_holder_name', readOnly: disabledState.bank_holder_name }}
                                        onChange={handleInfoChange}
                                        value={profileFormState.bank_holder_name ?? ""}
                                    />
                                </div>
                                <div style={{ paddingTop: 25 }}>
                                    <Typography className={styles.textFieldTitle}>{t('profile.bankAccount')}</Typography>
                                    <TextField
                                        error={errorFormState.bank_account ? true : false}
                                        helperText={errorFormState.bank_account}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ name: 'bank_account' }}
                                        onChange={handleInfoChange}
                                        value={profileFormState.bank_account ?? ""}
                                    />
                                </div>
                                <div style={{ paddingTop: 25 }}>
                                    <Typography className={styles.textFieldTitle}>{t('profile.bankSwift')}</Typography>
                                    <TextField
                                        error={errorFormState.bank_swift ? true : false}
                                        helperText={errorFormState.bank_swift}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ name: 'bank_swift' }}
                                        onChange={handleInfoChange}
                                        value={profileFormState.bank_swift ?? ""}
                                    />
                                </div>
                            </div>
                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 30 }}>
                                <Button variant="contained" onClick={updateInfo} style={{ width: 167, padding: '8px 9px', maxWidth: '80%', borderRadius: 48, }} color="secondary">{t('button.update')}</Button>
                            </Box>
                        </List>
                        <Snackbar
                            open={state.snackbarShow}
                            autoHideDuration={2000}
                            onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        >
                            <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                                <CardContent style={{ padding: 10 }}>
                                    <Typography style={{ color: 'white' }}>{state.message}</Typography>
                                </CardContent>
                            </Card>
                        </Snackbar>
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    textFieldTitle:{
        color:theme.palette.gray.text,
        fontSize:16,
        paddingBottom:5,
        paddingLeft:10
    }
}));
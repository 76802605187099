import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateCartTotal, updateShareLinkRef, updateShareLinkProduct } from '../actions';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import _ from 'lodash';
import { PROJECT_TYPE } from '../configs/Config';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Breadcrumbs, FormControl, Card, CardContent, Button, Select, MenuItem, Snackbar, Typography, Container, Grid, Tabs, Tab, AppBar, Link, Box, Hidden, Divider, List, ListItem, CardMedia, Input, InputAdornment, IconButton, Tooltip, Avatar, Dialog, DialogContent, DialogActions, ListItemText, Badge, Chip } from '@material-ui/core';
import { FacebookShareButton, FacebookIcon, FacebookMessengerShareButton, FacebookMessengerIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from "react-share";

import { Rating, Alert } from '@material-ui/lab';
import SwipeableViews from 'react-swipeable-views';

import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
// import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { LinkOutlined } from '@material-ui/icons';
import { Share } from '@material-ui/icons';
import TitleBar from './Layouts/TitleBar';
import TitleBarTransparent from './Layouts/TitleBarTransparent';

import ImageGallery from 'react-image-gallery';
import "../image-gallery.css";
import { getUrl, postUrl, deleteUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';
import './css/styles.css';
import WebpImg from './Layouts/WebpImg';
import Question from './Question';
import Review from './Review';
import TabPanel from './Layouts/TabPanel';
import { FormatListNumberedRtlOutlined } from '@material-ui/icons';
import { FACEBOOK_APPID } from '../configs/Config';
import { Helmet } from "react-helmet";

const INITIAL_STATE = {
    tabIndex: 0,
    variant: 0,
    warehoustId: 0,
    multiPricingId: 0,
    wid: 0,
    open: false,
    error: false,
    quantity: 1,
    message: "",
    product_options: true,
    // shippingRegionDialog: false,
    user_wishlist: false
};

export default function Item(props) {
    const [state, setState] = useState(INITIAL_STATE);
    const [product, setProduct] = useState({});
    const [review, setReview] = useState({});
    const [productReviewSummary, setProductReviewSummary] = useState({
        all_count: 0,
        average_rating: 0,
        comment_count: 0,
        total_sold: 0,
        star_count: {
            1: 0, 2: 0, 3: 0, 4: 0, 5: 0,
        }
    });
    const [merchantCompany, setMerchantCompany] = useState({});
    // const [productShippingVariant, setProductShippingVariant] = useState({});
    const [recommended, setRecommended] = useState({});

    const [category, setCategory] = useState([]);
    const [oriPrice, setOriPrice] = useState(0);
    const [sellPrice, setSellPrice] = useState(0);
    const [retailPrice, setRetailPrice] = useState(0);
    const [warehouse, setWarehouse] = useState([]);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [hideAddToCart, setHideAddToCart] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [BasecurrencyDisplay, setBaseCurrencyDisplay] = useState('');
    const [shareLink, setShareLink] = useState(window.location.href.split('?')[0]);

    const dispatch = useDispatch();
    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken, availableEvent2, shareLinkProductId, cartTotal:cartTotal2 } = useSelector(state => state.general);
    const { source, id:user_id, rank:user_rank, max_rank } = useSelector(state => state.user);
    let history = useHistory();
    const query = useQuery();
    const encoded_ref_id = query.get('r');
    const {currency,currencyRate,currencydisplay } = useSelector(state => state.general);
    let currency_code = [];
    _.map(currencydisplay,(currency_detail,index)=>{
        if(currency_detail.code == currency){
            currency_code = currency_detail.desc.split('|');
        }
        
    });

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        setState(INITIAL_STATE);
        setWarehouse([]);

        let pointList = [];
        getUrl(`point_list`)
        .then(response => {
            if(response.status) {
                pointList = response.data;
            }
        })
        .catch((error) => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });

        getUrl(`products/${id}`)
        .then(productDetail => {
            if (isMountedRef.current) {
                setLoading(false);
                if(productDetail.status) {
                    const { data, merchant_company_info, wallets:walletsName } = productDetail;
                    // const { options } = data;

                    setWallets(walletsName);
                    
                    if(_.size(data.voucher_header) > 0) {
                        history.push(`/voucher/${id}`);
                    }

                    // setBaseCurrencyDisplay(data[`currency_display_${i18n.language}`]);

                    if (_.size(data.images_array) <= 0) {
                        data['images_array'] = ['/images/no-image.png'];
                    }
                    
                    // filter product's points
                    let tempPoint = [];
                    let visible = false;
                    let filteredArray = [];
                    setState(state => ({ ...state, user_wishlist: data.user_wishlist }));
                    if(_.size(data.points) > 0){
                        tempPoint = [];
                        _.map(data.points, (point, key) => {
                            visible = false;
                            if(_.size(pointList) > 0){
                                _.map(pointList, visiblePoint => {
                                    if(visiblePoint.id === point.point_id){
                                        visible = true;
                                    }
                                })
                                if(visible){
                                    tempPoint[key] = point;
                                }
                            }
                        })
                        filteredArray = tempPoint.filter(item => item !== null);
                        data.points = filteredArray;
                    }

                    if (_.size(data.options) <= 0) {
                        setState(state => ({ ...state, product_options: false }));
                    } else {
                        selectVariant(data.options[0]);
                        setWarehouse(warehouse => (data.options[0].pricing));
                        let priceModDisplay = 'price_mod';
                        if (_.size(data.options[0].pricing) === 1) {
                            setState({ ...state, variant: data.options[0].id, warehoustId: data.options[0].pricing[0].id });
                            if (parseFloat(data.options[0].pricing[0][priceModDisplay]) > 0) {
                                setSellPrice((parseFloat(data.options[0].pricing[0][priceModDisplay])).toFixed(2));
                            }
                            if (parseFloat(data.options[0].pricing[0].retail_price) > 0) {
                                setRetailPrice(data.options[0].pricing[0].retail_price);
                            }
                        }
                        if (_.size(data.options[0].pricing) > 0) {
                            _.map(data.options[0].pricing, warehouseOption => {
                                if (warehouseOption.warehouse_id === state.wid) {
                                    setState({ ...state,variant: data.options[0].id, warehoustId: warehouseOption.id });
                                    if (parseFloat(warehouseOption[priceModDisplay]) > 0) {
                                        setSellPrice((parseFloat(warehouseOption[priceModDisplay])).toFixed(2));
                                    }
                                    if (parseFloat(warehouseOption.retail_price) > 0) {
                                        setRetailPrice(warehouseOption.retail_price);
                                    }
                                }
                            })
                        }
                        // setProductShippingVariant({
                        //     weight: parseFloat(options) ? options[0]['volume_weight'] : 0,
                        //     length: parseFloat(options) ? options[0]['package_length'] : 0,
                        //     width: parseFloat(options) ? options[0]['package_width'] : 0,
                        //     height: parseFloat(options) ? options[0]['package_height'] : 0
                        // });

                        // filter options's points
                        _.map(data.options, (option,opKey) => {
                            if(_.size(option.points) > 0){
                                tempPoint = [];
                                _.map(option.points, (point, key) => {
                                    visible = false;
                                    if(_.size(pointList) > 0){
                                        _.map(pointList, visiblePoint => {
                                            if(visiblePoint.id === point.point_id){
                                                visible = true;
                                            }
                                        })
                                        if(visible){
                                            tempPoint[key] = point;
                                        }
                                    }
                                })
                                filteredArray = tempPoint.filter(item => item !== null);
                                data.options[opKey].points = filteredArray;
                            }
                        })
                    }

                    setRetailPrice(data.retail_price);
                    setProduct(data);
                    setMerchantCompany(merchant_company_info);
                    if (_.size(data)) {
                        setBaseCurrencyDisplay(data[`currency_display_${i18n.language}`]);
                    }

                    if (data.category_id) {
                        getUrl(`categories/${data.category_id}/breadcrumb`).then(categoryBreadcrumb => {
                            setCategory(categoryBreadcrumb.data);
                        })
                    }

                    // let starter = user_rank ? ((user_rank === null || data.rank.code > user_rank.code) ? true : false) : true;
                    // let sellPrice = data.type === 'rank' ? (starter ? data.price_starter : data.price_repurchase) : data.sell_price;
                    let sellPrice = data.sell_price;
                    if(_.size(data.options) <= 0){
                        setOriPrice(sellPrice);
                        setSellPrice(sellPrice);
                    }
                    if (_.size(data.multi_pricings) > 0) {
                        // console.log("multipricing", data.multi_pricings);
                        setState(state => ({ ...state, multiPricingId: data.multi_pricings[0]['id'] }));
                    }
                    // if (_.size(data.multi_pricings) > 0) {
                    //     setState(state => ({ ...state, multiPricingId: data.multi_pricings[0]['id'] }));
                    //     setOriPrice(data.multi_pricings[0]['cash_amount']);
                    //     setSellPrice(data.multi_pricings[0]['cash_amount']);
                    // } else {
                    //     setOriPrice(data.sell_price);
                    //     setSellPrice(data.sell_price);
                    // }
                    if(data.event_id_list.includes(String(data.category_id))){
                        setHideAddToCart(true);
                    }else{
                        setHideAddToCart(false);
                    }
                    
                } else {
                    addAlert(JSON.stringify(t('general.invalidProduct')));
                }
            }
        }).catch((error) => {
            addAlert(JSON.stringify(t('general.invalidProduct')));
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, addAlert, history, user_rank]);

    useEffect(() => {
        if(user_id){
            setShareLink(`${window.location.href.split('?')[0]}?r=${btoa(user_id)}`);
        }
        // eslint-disable-next-line
    }, [user_id]);

    useEffect(() => {
        if(encoded_ref_id && !accessToken){
            dispatch(updateShareLinkRef(encoded_ref_id));
            dispatch(updateShareLinkProduct(id));
        }
        // eslint-disable-next-line
    }, [encoded_ref_id, accessToken, id]);

    useEffect(() => {
        if(accessToken && shareLinkProductId == id){
            dispatch(updateShareLinkProduct(""));
        }
        // eslint-disable-next-line
    }, [accessToken, id, shareLinkProductId]);

    useEffect(() => {
        if(_.size(product)){
            setBaseCurrencyDisplay(product[`currency_display_${i18n.language}`]);
        }
        // eslint-disable-next-line
    }, [i18n.language]);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        // for mobile only
        getUrl(`reviews/${id}?per_page=2`)
            .then(reviewData => {
                if (isMountedRef.current) {
                    if(reviewData.status) {
                        setLoading(false);
                        setReview(reviewData.data.data);
                    } else {
                        addAlert(JSON.stringify(t('general.invalidProduct')));
                    }
                }
            }).catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, setLoading, addAlert])

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        getUrl(`products/${id}/recommend`)
            .then(productDetail => {
                if (isMountedRef.current) {
                    setLoading(false);
                    const { data } = productDetail;
                    // console.log("recommend", data);
                    setRecommended(data);
                }
            }).catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, setLoading, addAlert]);

    useEffect(() => {
        isMountedRef.current = true;
        if (isMountedRef.current) {
            getUrl(`reviews/${id}/summary`)
            .then(reviewSummary => {
                if(reviewSummary.status) {
                    setProductReviewSummary(reviewSummary.data);
                    // console.log("reviewSummary", reviewSummary);
                }
            })
            .catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [id, setLoading, addAlert])

    useEffect(() => {
        let disable = false;
        if(parseInt(product.category_id) === parseInt(product.event2_cat_id)){
            disable = availableEvent2?false:true;
        }
        setButtonDisable(disable);
        // eslint-disable-next-line
    }, [id, setLoading, addAlert, availableEvent2, product])

    const handleChange = (event, newValue) => {
        setState({ ...state, tabIndex: newValue });
    };

    const handleChangeIndex = index => {
        setState({ ...state, tabIndex: parseInt(index) });
    };

    const onButtonClick = condition => {
        const { variant, warehoustId, quantity, product_options } = state;
        let multiPricingId = state.multiPricingId;
        if (product_options) {
            if (!variant) {
                return setState({ ...state, open: true, error: true, message: t('item.selectVariantAddCart') });
            } else if (!warehoustId) {
                return setState({ ...state, open: true, error: true, message: t('item.selectWarehouseAddCart') });
            }
        }

        if (quantity <= 0) {
            return setState({ ...state, open: true, error: true, message: t('item.selectQuantityAddCart') });
        }
        if (_.size(product.multi_pricings) > 0) {
            if(multiPricingId){
                if(product_options && variant){
                    let available = false;
                    // let starter = (user_rank && product.rank) ? ((user_rank === null || product.rank.code > user_rank.code) ? true : false) : true;
                    // let walletType = (_.find(product.multi_pricings, {id: parseInt(multiPricingId)})).type;
                    _.map(product.options, (option, key) => {
                        if(variant === option.id){
                            if(_.size(option.multi_pricing_id) > 0){
                                _.map(option.multi_pricing_id , id => {
                                    if(parseInt(multiPricingId) === parseInt(id)){
                                        available = true;
                                        // if((product.type === 'rank' && starter && walletType !== 'starter') ||
                                        // (product.type === 'rank' && !starter && walletType !== 'repurchase') ||
                                        // (product.type === 'normal' && walletType !== 'normal')){
                                        //     available = false;
                                        // }
                                    }
                                })
                            }else{
                                multiPricingId = 0;
                                available = true;
                            }
                        }
                    })
                    if(!available){
                        return setState({ ...state, open: true, error: true, message: t('item.selectPricingAddCart') });
                    }
                }
            }else{
                return setState({ ...state, open: true, error: true, message: t('item.selectPricingAddCart') });
            }
        }

        if (condition === 'buynow') {
            if (accessToken) {
                postUrl(`carts`, {
                    product_id: id,
                    product_warehouse_id: warehoustId,
                    quantity,
                    multi_pricing_id: multiPricingId,
                    buyNow: true,
                }).then(response => {
                    if (response.status === 1) {
                        history.push('/checkout');
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            } else {
                // setState({ ...state, open: true, error: true, message: t('item.loginFirst') });
                postUrl(`carts`, {
                    product_id: id,
                    product_warehouse_id: warehoustId,
                    quantity,
                    multi_pricing_id: multiPricingId,
                    buyNow: true,
                }).then(response => {
                    if (response.status === 1) {
                        if(encoded_ref_id){
                            dispatch(updateShareLinkProduct(""));
                        }
                    } else {
                        setState({ ...state, open: true, error: true, message: response.error.variant[0] });
                    }
                }).then(() => history.push('/cart')
                ).catch((error) => {
                    setState({ ...state, open: true, error: true, message: t('item.addedToCartFail') });
                });
            }
        } else {
            postUrl(`carts`, {
                product_id: id,
                product_warehouse_id: warehoustId,
                quantity,
                multi_pricing_id: multiPricingId
            }).then(response => {
                if (response.status === 1) {
                    if(PROJECT_TYPE === "catalog"){
                        getUrl('mystore/products').then(cartList => {
                            dispatch(updateCartTotal(cartList.data.total));
                        }).catch(error => {
                            addAlert(JSON.stringify(error.message));
                        });
                    }else{
                        getUrl(`carts`)
                            .then(cartList => {
                                let cartTotal = 0;
                                _.map(cartList.data, cartItem => {
                                    cartTotal += parseInt(_.size(cartItem.items.source));
                                })
                                dispatch(updateCartTotal(cartTotal));
                            }).catch(error => {
                                addAlert(JSON.stringify(error.message));
                            });
                    }
                    setState({ ...state, open: true, error: false, message: t('item.addedToCart'), warehoustId: 0, variant: 0, quantity: 1, wid: 0 });
                    setWarehouse([]);

                    if(encoded_ref_id){
                        dispatch(updateShareLinkProduct(""));
                    }
                } else {
                    setState({ ...state, open: true, error: true, message: response.error.variant[0] });
                }
            }).catch((error) => {
                setState({ ...state, open: true, error: true, message: t('item.addedToCartFail') });
            });
        }
    }

    const renderVideo = (item) => {
        return (
            <div className='video-wrapper'>
                <div className="iframe-container">
                    <iframe
                        className="responsive-iframe"
                        src={item.embedUrl}
                        frameBorder='0'
                        allowFullScreen
                    >
                    </iframe>
                </div>
            </div>
        );
    }
    const windowSize = useRef([window.innerWidth, window.innerHeight.current]);
    const scrolled = document.documentElement.scrollTop
    const [visible,setVisible]=useState(false)
    useEffect(()=>{
        window.addEventListener('scroll', toggleVisible);
    },[])
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 75) {
            setVisible(true);
        }
        else if (scrolled <= 75) {
            setVisible(false);
        }
    };
    

    const generateImageSlide = ({ images_array, video, embed_video }) => {
        let imgList = [];

        if(_.size(video) > 0 || _.size(embed_video) > 0) {
            if(_.size(embed_video) > 0) video = embed_video;
            const defaultImage = _.size(images_array) > 0 ? images_array[0] : "/images/no-image.png";
            const videoProps = {
                original: defaultImage, 
                thumbnail: defaultImage,
                embedUrl: video,
            }
            imgList.push({ 
                original: defaultImage, 
                thumbnail: defaultImage,
                embedUrl: video,
                renderItem: () => renderVideo(videoProps),
            });
        }

        // imgList.push({ 
        //     original: 'https://images.unsplash.com/photo-1457732815361-daa98277e9c8?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTh8fGdpcmx8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60', 
        //     thumbnail: 'https://images.unsplash.com/photo-1457732815361-daa98277e9c8?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTh8fGdpcmx8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
        //     embedUrl: 'https://www.youtube.com/embed/_26h8SErA0c?autoplay=1&showinfo=0',
        //     description: 'Render custom slides within the gallery',
        //     renderItem: renderVideo,
        // });
        // console.log()
        
        _.map(images_array, (url) => {
            imgList.push({ original: url, thumbnail: url });
        });
        // console.log("imgList", imgList)
        return imgList;
    }

    const selectVariant = ({ id, volume_weight, package_length, package_width, package_height, pricing }) => {
        setState({ ...state, variant: id, open: false });
        setProduct({
            ...product, variantInfo: {
                weight: parseFloat(volume_weight) ? volume_weight : 0,
                length: parseFloat(package_length) ? package_length : 0,
                width: parseFloat(package_width) ? package_width : 0,
                height: parseFloat(package_height) ? package_height : 0,
            }
        });
        setSellPrice(oriPrice);
    }

    const changeQuantity = condition => {
        let newQuantity = state.quantity;
        if (condition === 'deduct') {
            newQuantity = newQuantity === 1 ? 1 : parseInt(newQuantity) - 1;
        } else {
            newQuantity = parseInt(newQuantity) + 1;
        }
        setState({ ...state, quantity: newQuantity });
    }

    const lastIndex = _.findLastIndex(category);

    const chatWithMerchant = merchantId => {
        if(accessToken && merchantId) {
            postUrl(`chats`, {
                'merchant_id': merchantId,
            }).then(response => {
                if (response.status === 1) {
                    history.push('/chat');
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const generateVariant = option => {
        let variant_txt = "";
        if(_.size(option.color) > 0) {
            variant_txt = `${(i18n.language === 'cn' ? (option.color_cn ? option.color_cn : option.color_en) : option.color_en)}`;
        }
        
        if(_.size(option.size) > 0) {
            if(_.size(variant_txt) > 0) {
                variant_txt = `${variant_txt} ( ${(i18n.language === 'cn' ? (option.size_cn ? option.size_cn : option.size_en) : option.size_en )} )`;
            } else {
                variant_txt = `${(i18n.language === 'cn' ? (option.size_cn ? option.size_cn : option.size_en) : option.size_en )}`;
            }
        }

        if(_.size(option.attribute_value) > 0) {
            if(_.size(variant_txt) > 0) {
                variant_txt = `${variant_txt} ( ${(i18n.language === 'cn' ? (option.attribute_value_cn ? option.attribute_value_cn : option.attribute_value_en) : option.attribute_value_en )} )`;
            } else {
                variant_txt = `${(i18n.language === 'cn' ? (option.attribute_value_cn ? option.attribute_value_cn : option.attribute_value_en) : option.attribute_value_en )}`;
            }
        }

        return variant_txt;
    }
    const vpRewardDisplay = (reward_list,sell_price,multi_pricings,optionMultiPricing)=>{
        let total_vp_amount;
        let wallet_exist;
        if(reward_list.type == "percent"){
            total_vp_amount = sell_price * reward_list.amount /100;
        }else {
            total_vp_amount = reward_list.amount;
        }
        if (optionMultiPricing){
            const current_multi_pricings = _.find(optionMultiPricing, { id: multi_pricings});
            if (current_multi_pricings){
                let wallet_setting =[];
                wallet_setting = current_multi_pricings.wallet_settings;
                for (const wallet_detail of wallet_setting) {
                    const isFound = wallet_detail.wallet_ids.some(element => {
                        if (element === 6) {
                          return true;
                        }
                        return false;
                      });
                    if(isFound){
                        wallet_exist = true;
                    }
                }
            }
        }
        return (
                <Grid container spacing={0}>
                    {
                        (wallet_exist)
                        ?
                        null
                        : 
                        //<Grid item xs={9} md={10}>
                            <div style={{ marginLeft: 5, marginRight: 5, backgroundColor: theme.palette.primary.main, width:'100%', borderTopLeftRadius: 11, borderBottomRightRadius: 11, boxShadow:'2px 3px 2px 0 #ffe3bf' }}>
                            <Typography variant="body1" className={styles.vpwithoutlinethrough}>{ t('product.vpreward', {'vp':parseFloat(total_vp_amount).toFixed(2) })} </Typography>
                            </div>
                           
                       //</Grid>
                    }
                </Grid>
        )
    }
    const priceDisplay = (optionId, optionMultiPricing) => {
        // let starter = (user_rank && product.rank) ? ((user_rank === null || product.rank.code > user_rank.code) ? true : false) : true;
        // let sellPriceDisplay = product.type === 'rank' ? (starter ? product.price_starter : product.price_repurchase) : product.sell_price;
        let sellPriceDisplay = product.sell_price;
        return (
            <>
            <ListItem>
                <Grid container spacing={1}>
                    <Grid item xs={3} >
                        <Typography variant="subtitle1" className={styles.subtitleTypography}>{t('product.price')}</Typography>
                    </Grid>
                    {
                        (_.size(product.multi_pricings) > 0 && (!optionId || (optionId && _.size(optionMultiPricing) > 0)))
                        ?
                            <Grid item xs={9}  style={{ marginTop: -18 }}>
                                <Select
                                    fullWidth
                                    value={state.multiPricingId ? state.multiPricingId : ''}
                                    onChange={({ target }) => setState({ ...state, multiPricingId: target.value })}
                                    inputProps={{ name: 'price' }}
                                    style={{ paddingTop: 10 }}
                                >
                                    {
                                        _.map(product.multi_pricings, (pricingItem, pricingIndex) => {
                                            let pricingText = '';
                                            _.map(pricingItem.wallet_settings, (setting, key) => {
                                                let minAmount = parseFloat(setting.type === 'percent' ? sellPrice*(setting.min_amount/100) : setting.min_amount).toFixed(2);
                                                let maxAmount = parseFloat(setting.type === 'percent' ? sellPrice*(setting.max_amount/100) : setting.max_amount).toFixed(2);
                                                let label = setting.pricing_name + ' : ' + (minAmount + (setting.min_amount === setting.max_amount ? '' : (' - ' + maxAmount)));
                                                pricingText = pricingText ? (pricingText + ' + [ ' + label + ' ]') : ('[ ' + label + ' ]');
                                            })
                                            let available = false;
                                            if(optionId){
                                                _.map(optionMultiPricing, (id) => {
                                                    if(parseInt(pricingItem.id) === parseInt(id)){
                                                        available = true;
                                                    }
                                                })
                                            }else{
                                                available = true;
                                            }
                                            // if(available){
                                            //     if((product.type === 'rank' && starter && pricingItem.type !== 'starter') ||
                                            //     (product.type === 'rank' && !starter && pricingItem.type !== 'repurchase') ||
                                            //     (product.type === 'normal' && pricingItem.type !== 'normal')){
                                            //         available = false;
                                            //     }
                                            // }
                                            if (available) {
                                                return (
                                                    <MenuItem key={pricingIndex} value={pricingItem.id}>
                                                        
                                                        <Typography variant="subtitle1" style={{ paddingRight: 10, paddingLeft: 10, color: '#f36c48', fontWeight: 'bold', fontSize: 11, lineHeight:1.3 }}>{pricingText}</Typography>
                                                    </MenuItem>
                                                )
                                            }
                                        })
                                    }
                                </Select>
                            </Grid>
                        :
                        <Grid item xs={9} md={10}>
                            <Box display="flex" flexDirection="row" style={{ alignItems: 'center' }}>
                                <NumberFormat value={sellPrice*currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, paddingLeft: 10, color: '#f36c48', fontSize: 24, fontWeight: 'bold' }} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                {
                                    parseFloat(product.retail_price) > parseFloat(sellPriceDisplay) ?
                                    <Box style={{ backgroundColor: 'red', borderRadius: 5, padding: 5 }}><Typography style={{ fontSize: 12, color: '#FFF', fontWeight: 'bold' }}>{`${isNaN(Math.round(((parseFloat(retailPrice) - parseFloat(sellPrice)) / parseFloat(retailPrice)) * 100)) ? '-' : Math.round(((parseFloat(retailPrice) - parseFloat(sellPrice)) / parseFloat(retailPrice)) * 100)}% ${t('general.discount')}`}</Typography></Box>
                                    : null
                                }
                            </Box>
                        </Grid>
                    }
                </Grid>
            </ListItem>
            </>
        )
    }

    const likeProduct = () => {
        if (accessToken) {
            postUrl(`wishlist/product`, {
                product_id: id
            }).then(response => {
                if (response.status === 1) {
                    setState({ ...state, user_wishlist: true, open: true, error: false, message: t('item.productLiked') });
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const dislikeProduct = () => {
        if (accessToken) {
            deleteUrl(`wishlist/product/${id}`).then(response => {
                if (response) {
                    if (isMountedRef.current) {
                        setState({ ...state, user_wishlist: false, open: true, error: false, message: t('item.productDislike') });
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    };

    const rewardDisplay = (reward) => {
        let availableSource = "all";
        if(reward[source]) availableSource = source;
        if(!reward[availableSource]) return null;
        let langIndex = 0;
        if(i18n.language == "cn") langIndex = 1;
        return (
            <ListItem>
                <Grid container spacing={1}>
                    <Grid item xs={3} md={3}>
                        <Typography variant="subtitle1" className={styles.subtitleTypography}>{t('product.rewards')}</Typography>
                    </Grid>
                    <Grid item xs={9} md={9}>
                        <Typography variant="subtitle1" className={styles.subtitleTypography}>
                        {   
                            Object.keys(reward[availableSource]['wallet_amount']).map(function ( key, index ) {
                                let walletName = _.split(wallets[key], "|");
                                return (
                                    <span key={index}>{index > 0 ? " + " : ""}{reward[availableSource]['wallet_amount'][key]}{reward[availableSource]['amount_type'] === "percent" ? "%" : ""} {walletName[langIndex] ? walletName[langIndex] : walletName[0]} </span>
                                )
                            })                        
                        }
                        </Typography>
                    </Grid>
                </Grid>
            </ListItem>
        )
    }

    return(
        <Box className={styles.root2} >
            {/* <Box style={{ height: 50, backgroundColor:theme.palette.primary.main, display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', }}>
                <Link underline='none' onClick={() => history.goBack()} component="button" style={{zIndex:3}} >
                    <FiChevronLeft style={{color:theme.palette.white.main, marginLeft:15, fontSize:21}}/>
                </Link>
                <Typography style={{ textAlign: 'center', color: theme.palette.white.main, width:'100%', marginLeft:-34 }}>{t('item.products')}</Typography>
                <Link underline='none' to="/cart" component={RouterLink} style={{marginRight: 15 }}>
                    <Badge badgeContent={cartTotal2} classes={{ badge: styles.badgeStyle }}>
                        <ShoppingCartIcon style={{color: "white"}}/>
                    </Badge>
                </Link>
            </Box> */}
            {/* <div className='mobile-width-fixed' style={{zIndex:2}}>
                <TitleBarWhite height={0} title={t('item.products')} displayCartTop back  displayInfo={false} />
            </div> */}
            {/* <TitleBar height={0} title={t('item.products')} displayCartTop back  displayInfo={false} /> */}
            {visible == true?
            <div className='item'>
            <TitleBar height={70} title={t('title.products')} displayInfo currencyButton displayCart back backtext   />
            </div>
            :
            <div className='item'>
            <TitleBarTransparent height={70} title={t('title.products')} displayInfo currencyButton displayCart back backtext />
            </div>
            }
            
            <Box>
                <Helmet>
                    <title>{ i18n.language === 'cn' ? product.title_cn : product.title_en }</title>
                    <meta name="description" content={ i18n.language === 'cn' ? product.title_cn : product.title_en } />
                    <meta name="og:url" content={ window.location.href } />
                    <meta name="og:title" content={ i18n.language === 'cn' ? product.title_cn : product.title_en } />
                    <meta name="og:description" content={ i18n.language === 'cn' ? product.title_cn : product.title_en } />
                    { _.size(product.images_array) > 0 ? 
                        <meta property="og:image" content={product.images_array[0]} />
                    : null }
                </Helmet>
                {/* <Container fixed className={styles.breadcrumbRoot}>
                    <Grid container spacing={1} justify="center" className={styles.breadcrumbRoot} style={{ maxWidth: '100%' }}>
                        <Grid item xs={10}>
                            <Breadcrumbs separator="›" maxItems={2} aria-label="breadcrumb" style={{ fontSize: 12, padding: '0 10px' }}>
                                {
                                    _.map(category, (categoryItem) => {
                                        return (
                                            <Link key={categoryItem.id} underline='none' color="secondary" href={`/category/${categoryItem.id}`} >
                                                { categoryItem[`name_${i18n.language}`] }
                                            </Link>
                                        )
                                    })
                                }
                                <Typography style={{ fontSize: 12, color: 'light' }}>{i18n.language === 'cn' ? product.title_cn : product.title_en}</Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                </Container> */}
                <Box style={{ backgroundColor: '#fff' }}>
                    <Grid item xs={12} style={{ width: '100%', maxWidth: '100%', padding: '0' }}>
                        <ImageGallery
                            showBullets
                            items={generateImageSlide(product)}
                            autoPlay={false}
                            onErrorImageURL="/images/no-image.png"
                            renderItem={(item) => {
                                return <WebpImg className='image-gallery-image' style={{ objectFit: 'cover', height: 370, width: 420, maxWidth: '100%', maxHeight:'100vw' }} src={item.original} />
                            }}
                            renderThumbInner={(item) => {
                                return <WebpImg className='image-gallery-image' style={{ objectFit: 'cover', height: 370, width: 420, maxWidth: '100%', maxHeight:'100vw' }} src={item.original} />
                            }}
                            showThumbnails={false}
                            showPlayButton={false}
                        />
                    </Grid>
                    <Container fixed className={styles.breadcrumbRoot} style={{padding:0}}>
                        <Box bgcolor="white" className={styles.root}  style={{padding:0}}>
                            <Grid container spacing={1} justify="center" style={{ maxWidth: '100%', margin:0 }}>
                                
                                <Grid item xs={12}>
                                    <List>
                                        <div style={{padding:'0 15px'}}>
                                            <Typography variant="subtitle1" className='txt_truncate2' style={{ fontWeight: 'bold', color: theme.palette.gray.text, fontSize: '20px', height:'unset', lineHeight:1.3 }}>{product[`title_${i18n.language}`]}</Typography>
                                            <NumberFormat value={(sellPrice*currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ fontWeight: 'bold', color: theme.palette.primary.main, fontSize: '20px'  }} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                        </div>
                                        {
                                            parseInt(product.category_id) === parseInt(product.event2_cat_id)
                                            ?
                                            <ListItem>
                                                <Typography variant="caption" style={{ color: '#808080' }}>
                                                    {t('item.event2Reminder')}
                                                </Typography>
                                            </ListItem>
                                            :
                                            null
                                        }
                                        <Box style={{ padding: '0 16px', }}>
                                            <Hidden only={['xs']}>
                                                <Box display="flex" alignItems="center" justifyContent="space-between" padding={1} style={{ backgroundColor: '#f7f7f7' }}>
                                                    <Box flex={3} display="flex" flexDirection="row" alignItems="center">
                                                        <Box display="flex" alignItems="center">
                                                            <Typography style={{ margin: 0, paddingRight: 7, fontSize: 18, lineHeight: 1.5, color: '#777' }}>{ productReviewSummary.average_rating }</Typography>
                                                            <Rating name="half-rating-read" value={productReviewSummary.average_rating} defaultValue={productReviewSummary.average_rating} precision={0.1} readOnly />
                                                        </Box>

                                                        <Typography style={{ margin: '0 10px', fontSize: 18, lineHeight: 1.5, color: '#cecece' }}>|</Typography>

                                                        <Box display="flex" alignItems="center">
                                                            <Typography style={{ margin: 0, paddingRight: 7, fontSize: 18, lineHeight: 1.5, color: '#ea8012' }}>
                                                                { productReviewSummary.all_count }
                                                            </Typography>
                                                            <Typography style={{ margin: 0, paddingRight: 7, lineHeight: 1.5, color: '#a0a0a0', fontSize: 14 }}>
                                                                { t('item.rating') }
                                                            </Typography>
                                                        </Box>

                                                        <Typography style={{ margin: '0 10px', fontSize: 18, lineHeight: 1.5, color: '#cecece' }}>|</Typography>

                                                        <Box display="flex" alignItems="center">
                                                            { product.rank_id==0 ?
                                                            <Typography style={{ margin: 0, paddingRight: 7, fontSize: 14, lineHeight: 2.3, color: '#777' }}>{ `${productReviewSummary.total_sold} ${t('item.sold')}` }</Typography> : null }
                                                            {/* <Link underline='none' to={'/review'} component={RouterLink}>
                                                                <Typography style={{ margin: 0, paddingRight: '7px', fontSize: '14px', lineHeight: '2.3', color: '#777' }}>{ t('item.viewRating') }</Typography>
                                                            </Link> */}
                                                        </Box>
                                                    </Box>
                                                    {/* <Box flex={1} display="flex" justifyContent="flex-end">
                                                        <Button variant="text" onClick={() => setState({ ...state, shippingRegionDialog: true })}>
                                                            <Typography variant="body2">{ t('item.shippingRegionRestriction') }</Typography>
                                                        </Button>
                                                    </Box> */}
                                                </Box>
                                            </Hidden>

                                            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                                <Box display="flex" alignItems="center" justifyContent="space-between" padding={1} style={{ backgroundColor: '#f7f7f7' }}>
                                                    <Box>
                                                        <Box flex={3} display="flex" flexDirection="row">
                                                            <Typography style={{ margin: 0, paddingRight: 7, fontSize: 18, lineHeight: 1.5, color: '#777' }}>{productReviewSummary.average_rating}</Typography>
                                                            <Rating value={productReviewSummary.average_rating} defaultValue={productReviewSummary.average_rating} precision={0.1} readOnly />
                                                        </Box>
                                                        {product.rank_id==0 ? 
                                                        <Typography style={{ margin: 0, paddingRight: 7, fontSize: 14, lineHeight: 2.3, color: '#777' }}>{`${productReviewSummary.total_sold} ${t('item.sold')}`}</Typography>
                                                        : null}
                                                    </Box>
                                                    {/* <Typography style={{ margin: '0 10px', fontSize: 18, lineHeight: 1.5, color: '#cecece' }}>|</Typography> */}
                                                    {/* <Box flex={1} display="flex" justifyContent="flex-end">
                                                        <Button variant="text" onClick={() => setState({ ...state, shippingRegionDialog: true })}>
                                                            <Typography variant="body2" style={{ fontSize: 12, color:'#77797b' }}>{ t('item.shippingRegionRestriction') }</Typography>
                                                        </Button>
                                                    </Box> */}
                                                </Box>
                                            </Hidden>
                                        </Box>

                                        
                                        {/* Shipping detail */}
                                        <ListItem>
                                            <Grid container spacing={1}>
                                                <Grid item xs={3} >
                                                    <Typography variant="subtitle1" className={styles.subtitleTypography} style={{paddingTop:10}}>{t('item.shippingRegionRestriction')}</Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2" color="primary" style={ { fontSize: 16,paddingTop:10}}>{ t('item.standardDelivery') }</Typography>  
                                                    <ListItemText
                                                        primary={t('item.prepareTime')}
                                                        secondary={t('item.prepareTimeSub', { 'day': product.prepare_time })}
                                                        primaryTypographyProps={{
                                                            variant: "subtitle1",
                                                            style: { fontWeight: "bold" , fontSize: 14}
                                                        }}
                                                        secondaryTypographyProps={{
                                                            style: {
                                                                color: theme.palette.gray.main
                                                            },
                                                            variant: "caption"
                                                        }}
                                                    />
                                                    <ListItemText
                                                        primary={t('item.deliveryTime')}
                                                        secondary={t('item.deliveryTimeSub')}
                                                        primaryTypographyProps={{
                                                            variant: "subtitle1",
                                                            style: { fontWeight: "bold", fontSize: 14 }
                                                        }}
                                                        secondaryTypographyProps={{
                                                            style: {
                                                                color: theme.palette.gray.main
                                                            },
                                                            variant: "caption"
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ListItem>

                                        {/* Product detail */}
                                        {
                                            parseFloat(product.retail_price) > parseFloat(product.sell_price) ?
                                                <ListItem>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={3} >
                                                            <Typography variant="subtitle1" className={styles.subtitleTypography}>{t('product.oriPrice')}</Typography>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <NumberFormat value={retailPrice*currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} style={{ paddingRight: 10, paddingLeft: 10, fontSize: 18, textDecoration: 'line-through' }} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                            {/* <Typography variant="subtitle1" style={{ paddingRight: 10, paddingLeft: 10, fontSize: 18, textDecoration: 'line-through' }}>{ `${currencyDisplay} ${retailPrice}` }</Typography> */}
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                : null
                                        }
                                        
                                        {
                                            (_.size(product.options) > 0 && state.variant)
                                            ?
                                            _.map(product.options, (option) => {
                                                if(option.id === state.variant){
                                                    return (
                                                        <Box key={option.id}>
                                                            {priceDisplay(option.id, option.multi_pricing_id)}
                                                        </Box>
                                                    )
                                                }
                                            })
                                            :
                                            priceDisplay(0, [])
                                        }
                                        <ListItem style={{    paddingTop: 0, paddingBottom: 26}}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} >
                                                    <Box display="flex" flexDirection="row">
                                                        {/* {
                                                            _.map(product.ipoint, (idata, index) => {
                                                                if(idata.warehouse_id === state.warehoustId){
                                                                    return (
                                                                        <Chip 
                                                                            key={index}
                                                                            variant="default"
                                                                            className={styles.chipStyle}
                                                                            label={<NumberFormat value={idata.ipoint} decimalScale={2} displayType={'text'} thousandSeparator={true} suffix=' I-POINT' />}
                                                                        />
                                                                    )
                                                                }
                                                            })
                                                        } */}
                                                        {/* { _.size(product.ipoint) > 0 ? 
                                                            _.map(product.ipoint, (idata, index) => {
                                                                if(idata.warehouse_id === state.warehoustId) {
                                                                    return (
                                                                        <div key={index} style={{ marginLeft: 5, marginRight: 5, backgroundColor: theme.palette.primary.main, width:'20%', borderTopLeftRadius: 11, borderBottomRightRadius: 11, boxShadow:'2px 3px 2px 0 #ead4d4' }}>
                                                                            <Typography style={{ color: '#fff', padding:'3px 5px', textAlign: 'center' }} variant="body1">
                                                                                <NumberFormat value={parseFloat(idata.ipoint)} decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` IP`} />
                                                                            </Typography>
                                                                        </div>
                                                                    )
                                                                }
                                                            }) 
                                                        : null } */}
                                                        {/* { 
                                                            (product.type === 'rank' ? ((user_rank && product.rank) ? ((user_rank === null || product.rank.code > user_rank.code) ? (product.bv_starter > 0) : (product.bv_repurchase > 0)) : (product.bv_starter > 0)) : (product.bv > 0))
                                                            ? 
                                                            <div style={{ marginLeft: 5, marginRight: 5, backgroundColor: '#fa9f2d', width:'20%', borderTopLeftRadius: 11, borderBottomRightRadius: 11, boxShadow:'2px 3px 2px 0 #ffe3bf' }}>
                                                                <Typography style={{ color: '#fff', padding:'3px 5px', textAlign: 'center' }} variant="body1">
                                                                    <NumberFormat value={parseFloat((product.type === 'rank' ? ((user_rank && product.rank) ? ((user_rank === null || product.rank.code > user_rank.code) ? product.bv_starter : product.bv_repurchase) : product.bv_starter) : (product.bv)))} decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` BV`} />
                                                                </Typography>
                                                            </div>
                                                        : null } */}
                                                        { 
                                                            product.bv > 0
                                                            ? 
                                                            <div style={{ marginLeft: 5, marginRight: 5,backgroundColor: '#fa9f2d', width:'100%', borderTopLeftRadius: 11, borderBottomRightRadius: 11, boxShadow:'2px 3px 2px 0 #ffe3bf' }}>
                                                                <Typography style={{ color: '#fff', padding:'3px 5px', textAlign: 'center' }} variant="body1">
                                                                    <NumberFormat value={parseFloat(product.bv)} decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` BV`} />
                                                                </Typography>
                                                            </div>
                                                        : null }
                                                        {(_.size(product.mp_reward) > 0 && product.mp_reward.amount > 0)?
                                                              vpRewardDisplay(product.mp_reward,product.sell_price,state.multiPricingId,product.multi_pricings)
                                                            : null
                                                        }
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider />
                                        {
                                            _.size(product.options) > 0 ?
                                                <>
                                                    <ListItem>
                                                        <Grid container spacing={1} >
                                                            <Grid item xs={3}>
                                                                <Typography variant="subtitle1" className={styles.subtitleTypography}>{t('product.variants')}</Typography>
                                                            </Grid>
                                                            <Grid item xs={9} >
                                                                {
                                                                    _.map(product.options, (option) => {
                                                                        // let starter = (user_rank && product.rank) ? ((user_rank === null || product.rank.code > user_rank.code) ? true : false) : true;
                                                                        // let priceModDisplay = product.type === 'rank' ? (starter ? 'price_mod_starter' : 'price_mod_repurchase') : 'price_mod';
                                                                        let priceModDisplay = 'price_mod';
                                                                        return (
                                                                            <Button
                                                                                key={option.id}
                                                                                onClick={() => {
                                                                                    selectVariant(option);
                                                                                    setWarehouse(warehouse => (option.pricing));

                                                                                    if (_.size(option.pricing) === 1) {
                                                                                        setState({ ...state, variant: option.id, warehoustId: option.pricing[0].id });
                                                                                        if (parseFloat(option.pricing[0][priceModDisplay]) > 0) {
                                                                                            setSellPrice((parseFloat(option.pricing[0][priceModDisplay])).toFixed(2));
                                                                                        }
                                                                                        if (parseFloat(option.pricing[0].retail_price) > 0) {
                                                                                            setRetailPrice(option.pricing[0].retail_price);
                                                                                        }
                                                                                    } else {
                                                                                        setRetailPrice(product.retail_price);
                                                                                        setState(state => ({ ...state, variant: option.id, warehoustId: 0 }));
                                                                                    }

                                                                                    if (_.size(option.pricing) > 0) {
                                                                                        _.map(option.pricing, warehouseOption => {
                                                                                            if (warehouseOption.warehouse_id === state.wid) {
                                                                                                setState({ ...state, variant: option.id, warehoustId: warehouseOption.id });
                                                                                                if (parseFloat(warehouseOption[priceModDisplay]) > 0) {
                                                                                                    setSellPrice((parseFloat(warehouseOption[priceModDisplay])).toFixed(2));
                                                                                                }
                                                                                                if (parseFloat(warehouseOption.retail_price) > 0) {
                                                                                                    setRetailPrice(warehouseOption.retail_price);
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                }}
                                                                                style={{ borderRadius: 0, fontSize: 12, margin: 5, borderColor: state.variant === option.id ? theme.palette.button.main : '' }}
                                                                                variant='outlined'
                                                                                color='default'
                                                                            >
                                                                                <Typography variant="caption" style={{ color: state.variant === option.id ? theme.palette.button.main : '#000000', fontWeight: 'bold' }}>
                                                                                    { generateVariant(option) }
                                                                                </Typography>
                                                                            </Button>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                    <ListItem>
                                                        <Grid container spacing={1} >
                                                        {
                                                            _.map(product.options, options => {
                                                                if(options.id === state.variant){
                                                                    return (
                                                                        _.size(options.points) > 0 ?
                                                                            <Grid item xs={12} key={options.id}>
                                                                                <Grid container spacing={1} >
                                                                                    <Grid item xs={3} md={3}>
                                                                                        <Typography variant="subtitle1" className={styles.subtitleTypography}>{t('item.point')}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={9} md={9}>
                                                                                        <Grid container spacing={1} >
                                                                                        {
                                                                                            _.map(options.points, point => {
                                                                                                const name = _.split(point.point_name, '|');
                                                                                                return (
                                                                                                    <Grid item xs={12} key={point.id}>
                                                                                                        <Typography variant="caption" className={styles.subtitleTypography}>
                                                                                                            {(i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) + ': ' + point.value + (point.point_prefix?' (' + point.point_prefix + ')':'')}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        :
                                                                        null
                                                                    )
                                                                }else{
                                                                    return null;
                                                                }
                                                            })
                                                        }
                                                            <Grid item xs={3} md={3}>
                                                                <Typography variant="subtitle1" className={styles.subtitleTypography}>
                                                                    {_.size(warehouse) > 1 ? t('product.warehouse') : t('item.stock')}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={9} md={9}>
                                                                {
                                                                    _.size(warehouse) !== 1 ?
                                                                        _.size(warehouse) > 0 ?
                                                                            _.map(warehouse, (option) => {
                                                                                // let starter = (user_rank && product.rank) ? ((user_rank === null || product.rank.code > user_rank.code) ? true : false) : true;
                                                                                // let priceModDisplay = product.type === 'rank' ? (starter ? 'price_mod_starter' : 'price_mod_repurchase') : 'price_mod';
                                                                                let priceModDisplay = 'price_mod';
                                                                                return (
                                                                                    <Button
                                                                                        key={option.id}
                                                                                        onClick={() => {
                                                                                            setState({ ...state, warehoustId: option.id, wid: option.warehouse_id });
                                                                                            if (parseFloat(option[priceModDisplay]) > 0) {
                                                                                                setSellPrice((parseFloat(option[priceModDisplay])).toFixed(2));
                                                                                            }
                                                                                            if (parseFloat(option.retail_price) > 0) {
                                                                                                setRetailPrice(option.retail_price);
                                                                                            }
                                                                                        }}
                                                                                        style={{ borderRadius: 0, fontSize: 12, margin: 5, borderColor: state.wid === option.warehouse_id ? theme.palette.button.main : '' }}
                                                                                        variant='outlined'
                                                                                        color='default'
                                                                                        disabled={option.quantity > 0 ? false : true}
                                                                                    >
                                                                                        <Typography variant="caption" style={{ color: state.wid === option.warehouse_id ? theme.palette.button.main : '#000000', fontWeight: 'bold' }}>{`${(option.warehouse_name) ? option.warehouse_name : t('item.warehouse')} (${t('item.stock')}: ${option.quantity})`}</Typography>
                                                                                    </Button>
                                                                                )
                                                                            })
                                                                        : 
                                                                        <Typography variant="overline">{product.quantity}</Typography>
                                                                    : 
                                                                    _.size(warehouse) > 0 ? 
                                                                    <Typography variant="overline">{warehouse[0]['quantity']}</Typography> 
                                                                    : 
                                                                    <Typography variant="overline">-</Typography>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                </>
                                                :
                                                <ListItem>
                                                    <Grid container spacing={1} >
                                                    {
                                                        _.size(product.points) > 0 ?
                                                        <>
                                                            <Grid item xs={3} md={3}>
                                                                <Typography variant="subtitle1" className={styles.subtitleTypography}>{t('item.point')}</Typography>
                                                            </Grid>
                                                            <Grid item xs={9} md={9}>
                                                                <Grid container spacing={1} >
                                                                {
                                                                    _.map(product.points, point => {
                                                                        const name = _.split(point.point_name, '|');
                                                                        return (
                                                                            <Grid item xs={12} key={point.id}>
                                                                                <Typography variant="caption" className={styles.subtitleTypography}>
                                                                                    {(i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]) + ': ' + point.value + (point.point_prefix?' (' + point.point_prefix + ')':'')}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                }
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    {product.rank_id==0 ?
                                                    <>
                                                        <Grid item xs={3} md={3}>
                                                            <Typography variant="subtitle1" className={styles.subtitleTypography}>{t('item.stock')}</Typography>
                                                        </Grid>
                                                        <Grid item xs={9} md={9}>
                                                            <Typography variant="caption" className={styles.subtitleTypography}>{product.quantity}</Typography>
                                                        </Grid>
                                                    </> :  null}
                                                    </Grid>
                                                </ListItem>
                                        }
                                        <ListItem>
                                            <Grid container spacing={1}>
                                                <Grid item xs={3} md={3}>
                                                    <Typography variant="subtitle1" className={styles.subtitleTypography}>{t('product.quantity')}</Typography>
                                                </Grid>
                                                <Grid item xs={9} md={9}>
                                                    <FormControl>
                                                        <Input
                                                            type="text"
                                                            value={state.quantity}
                                                            disableUnderline={true}
                                                            fullWidth={true}
                                                            classes={{ input: styles.inputStyle }}
                                                            onChange={({ target }) => {
                                                                const newQuantity = parseInt(target.value) ? parseInt(target.value) : 1;
                                                                setState({ ...state, quantity: newQuantity });
                                                            }}
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <IconButton
                                                                        style={{ color: 'black', backgroundColor: theme.palette.background.default, borderRadius: 0 }}
                                                                        onClick={() => changeQuantity('deduct')}
                                                                    >
                                                                        <RemoveIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        style={{ color: 'black', backgroundColor: theme.palette.background.default, borderRadius: 0 }}
                                                                        onClick={() => changeQuantity('add')}
                                                                    >
                                                                        <AddIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        {/* <ListItem>
                                            <Grid container spacing={1}>
                                                {
                                                    accessToken || !accessToken?
                                                        <Button size="small" style={{ margin: 10, borderRadius: 30, borderWidth: 2, borderColor: theme.palette.button.main }} onClick={() => onButtonClick("buynow")} variant="outlined" aria-label="plus" disabled={buttonDisable}>
                                                            <Typography variant="overline" style={{ color: theme.palette.button.main }}>{t('item.buyNow')}</Typography>
                                                        </Button>
                                                        : null
                                                }
                                                {
                                                    hideAddToCart
                                                    ?
                                                    null
                                                    :
                                                    <Button size="small" className={styles.addCartStyle} onClick={() => onButtonClick("add")} variant="contained" aria-label="plus" disabled={buttonDisable}>
                                                        <Typography variant="overline" style={{ color: '#FFFFFF' }}>{t('item.addToCart')}</Typography>
                                                    </Button>
                                                }
                                                {
                                                    accessToken && !state.user_wishlist ?
                                                        <IconButton aria-label="favorite" onClick={likeProduct}>
                                                            <FavoriteBorderIcon />
                                                        </IconButton>
                                                        : null
                                                }
                                                {            
                                                    accessToken && state.user_wishlist ?                                    
                                                        <IconButton aria-label="favorite" onClick={dislikeProduct}>
                                                            <FavoriteIcon style={{ color: 'red' }} />
                                                        </IconButton>
                                                        :null
                                                }
                                                <Box display="flex" flexDirection="row">
                                                    <FacebookShareButton
                                                        className="network__share-button"
                                                        url={shareLink}
                                                        quote={i18n.language === 'cn' ? product.title_cn : product.title_en}
                                                    >
                                                        <FacebookIcon size={32} />
                                                    </FacebookShareButton>
                                                    <FacebookMessengerShareButton
                                                        className="network__share-button"
                                                        appId={FACEBOOK_APPID}
                                                        url={shareLink}
                                                    >
                                                        <FacebookMessengerIcon size={32} />
                                                    </FacebookMessengerShareButton>
                                                    <WhatsappShareButton
                                                        className="network__share-button"
                                                        url={shareLink}
                                                        title={i18n.language === 'cn' ? product.title_cn : product.title_en}
                                                    >
                                                        <WhatsappIcon size={32} />
                                                    </WhatsappShareButton>
                                                    <TwitterShareButton
                                                        className="network__share-button"
                                                        url={shareLink}
                                                        title={i18n.language === 'cn' ? product.title_cn : product.title_en}
                                                    >
                                                        <TwitterIcon size={32} />
                                                    </TwitterShareButton>
                                                    {
                                                        _.size(max_rank) > 0 && 
                                                        <IconButton
                                                            onClick={()=> {navigator.clipboard.writeText(shareLink); addAlert(t('profile.copySuccess'), "success"); }}
                                                        >
                                                            <Share size={32} /><Typography style={{ fontSize: 10 }}>{t('profile.copyLink')}</Typography>
                                                        </IconButton>
                                                    }  
                                                </Box>
                                            </Grid>
                                        </ListItem> */}
                                        {
                                            accessToken && product.reward ?
                                            rewardDisplay(product.reward)
                                            : null
                                        }
                                    </List>
                                    {
                                        state.open ?
                                            <Snackbar
                                                open={state.open}
                                                autoHideDuration={3000}
                                                onClose={() => setState({ ...state, open: false, error: false })}
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            >
                                                <Alert elevation={6} variant="filled" severity={state.error ? 'error' : 'success'}>
                                                    {state.message}
                                                </Alert>
                                            </Snackbar>
                                            : null
                                    }

                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
                <Container fixed className={styles.breadcrumbRoot}>
                    <Grid container spacing={1} justify="center" style={{ maxWidth: '100%', display:'none' }}>
                        <Grid item xs={12} md={12} style={{ paddingTop: 20, paddingBottom: 20 }}>
                            <Card style={{ boxShadow: 'none' }}>
                                {/* <CardContent style={{ padding: '16px 30px 16px 30px' }}> */}
                                    <Grid container style={{ padding: 16, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        <Grid item xs={12} sm={12}>
                                       

                                        <Box display="flex" flexDirection="column" justifyContent="center" style={{ width: '100%', paddingTop: 10 }}>
                                                {
                                                    _.size(merchantCompany.merchant_company_icon) > 0 ?

                                                        <Box borderColor="primary" overflow="hidden" style={{ overflow: 'hidden', padding:10, justifyContent: 'center', display: 'flex', maxWidth: 280, margin: '0 auto', marginBottom: 10 }}>
                                                            <WebpImg src={merchantCompany.merchant_company_icon.file_name} alt="company_logo" style={{ objectFit: 'cover', width: 'fit-content', height: 100 }} />
                                                        </Box>
                                                        :
                                                        <Box borderColor="primary" overflow="hidden" style={{ overflow: 'hidden', justifyContent: 'center', display: 'flex', maxWidth: 280, margin: '0 auto', marginBottom: 10 }}>
                                                            <WebpImg src="/images/general/shop-nologo2.png" alt="company_logo" style={{ objectFit: 'cover', width: 'fit-content', height:90 }} />
                                                        </Box>
                                                        // <Avatar style={{ width: theme.spacing(8), height: theme.spacing(8), backgroundColor: theme.palette.gray.border }}>
                                                        //     <PersonOutlineIcon style={{ color: theme.palette.gray.main }} />
                                                        // </Avatar>
                                                }
                                                {
                                                    _.size(product) > 0 ?
                                                        <Grid container style={{ flexDirection: 'column', width: '100%', justifyContent: 'center' }}>
                                                            <Box style={{ maxWidth: '100%' }}>
                                                                <p className='txt_truncate' style={{ fontWeight: 'bold', margin: '0', textAlign: 'center' }}>{merchantCompany[`shop_name_${i18n.language}`]}</p>
                                                            </Box>
                                                            <Box style={{ marginTop: 5, display:'none', }}>
                                                                {
                                                                    !_.isUndefined(merchantCompany.id) ?
                                                                        <Box style={{ textAlign: 'center' }}>
                                                                            <Link underline='none' to={`/shop/${merchantCompany.slug}`} component={RouterLink}>
                                                                                <Button
                                                                                    // onClick={ () => history.push(`/shop/${merchantCompany.id}`) } 
                                                                                    style={{ borderRadius: 48, fontSize: 12, borderColor: theme.palette.button.main, padding: '3px 9px', margin: 2 }}
                                                                                    variant='outlined'
                                                                                    color='default'
                                                                                >
                                                                                    <Typography variant="caption" style={{ color: theme.palette.button.main }}>{t('item.viewShop')}</Typography>
                                                                                </Button>
                                                                            </Link>
                                                                            {
                                                                                accessToken ?
                                                                                    <Button
                                                                                        onClick={() => chatWithMerchant(merchantCompany.id)}
                                                                                        style={{ borderRadius: 48, display:'none', fontSize: 12, borderColor: theme.palette.button.main, padding: '3px 9px', margin: 2 }}
                                                                                        variant='outlined'
                                                                                        color='default'
                                                                                    >
                                                                                        <Typography variant="caption" style={{ color: theme.palette.button.main, textAlign: 'center' }}>{t('item.chat')}</Typography>
                                                                                    </Button>
                                                                                    : null
                                                                            }
                                                                        </Box>
                                                                        : null
                                                                }
                                                            </Box>
                                                        </Grid>
                                                        : null
                                                }
                                            </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} style={{marginTop:'10px'}}>
                                        <Box display="flex" flexDirection="row" textAlign="center" paddingTop={1} paddingBottom={1}>
                                            <Box flex={1}>
                                                <Typography color="primary" style={{ fontWeight: 'bold', fontSize: 18 }}>{merchantCompany.total_product}</Typography>
                                                <Typography style={{ color: theme.palette.gray.main, fontSize: 12 }}>{t('item.products')}</Typography>
                                            </Box>
                                            {/* <Box flex={1} borderLeft={1}>
                                                    <Typography color="primary" style={{ fontWeight: 'bold', fontSize: 18 }}>4.9</Typography>
                                                    <Typography style={{ color: theme.palette.gray.main, fontSize: 12 }}>{t('item.ratings')}</Typography>
                                                </Box> */}
                                            <Box flex={1} styles={{borderLeft:'1px solid #d5d5d5'}}>
                                                <Typography color="primary" style={{ fontWeight: 'bold', fontSize: 18 }}>{merchantCompany.joined_day > 31 ? merchantCompany.joined_month : merchantCompany.joined_day}</Typography>
                                                <Typography style={{ color: theme.palette.gray.main, fontSize: 12 }}>{merchantCompany.joined_day > 31 ? t('shop.joinedMonth') : t('shop.joinedDay')}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {/* </CardContent> */}
                            </Card>
                        </Grid>
                    </Grid>

                    <Hidden only={['md', 'lg', 'xl']}>
                        <Box marginBottom={3} padding={3} style={{ backgroundColor: '#fff' }}>
                            <Box padding={1} display="flex" flexDirection="column">
                                <Typography variant="body1" style={{ margin: 0, fontSize: 13, textTransform:'uppercase', fontWeight: 'bold' }}>{ t('product.productRating') }</Typography>
                                <Box display="flex" flexDirection="row" justifyContent="space-between">
                                    <Box display="flex" flexDirection="row">
                                        <Typography style={{ margin: 0, paddingRight: 7, fontSize: 18, lineHeight: 1.5, color: '#777' }}>{ productReviewSummary.average_rating }</Typography>
                                        <Rating value={productReviewSummary.average_rating} defaultValue={productReviewSummary.average_rating} precision={0.1} readOnly />
                                    </Box>
                                    <Typography style={{ margin: '0 10px', fontSize: 18, lineHeight: 1.5, color: '#cecece' }}>|</Typography>
                                    <Link underline='none' to={`/review/${id}`} component={RouterLink}>
                                        <Typography style={{ margin: 0, paddingRight: 7, fontSize: 14, lineHeight: 2.3, color: '#777' }}>{ t('item.viewAll') }</Typography>
                                    </Link>
                                </Box>
                            </Box>
                            <Divider style={{ marginBottom:'15px'}}/>
                            {
                                _.size(review) > 0 ?
                                    _.map(review, reviewItem => {
                                        return (
                                            <Box key={reviewItem.id}>
                                                <Box display="flex" alignItems="center">
                                                    <Avatar style={{ height: 56, width: 56, marginRight: 10 }}>
                                                        <PersonIcon fontSize="default" />
                                                    </Avatar>
                                                    <Box>
                                                        <Typography style={{ margin: 0 }}>{ reviewItem.anonymous ? `${reviewItem.user.name[0]}*****` : reviewItem.user.name }</Typography>
                                                        <Rating value={reviewItem.rating} defaultValue={reviewItem.rating} precision={0.1} readOnly />
                                                    </Box>
                                                </Box>
                                                <Box style={{ marginTop: 10 }}>
                                                    <Typography style={{ fontSize: 13, paddingBottom: 10 }}>
                                                        { reviewItem.comment }
                                                    </Typography>
                                                </Box>
                                                {
                                                    _.size(reviewItem.response) > 0 ?
                                                    <Box padding={2} borderRadius={10} marginBottom={1} style={{ backgroundColor: '#f1f1f1' }}>
                                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                                            <Typography style={{ fontSize: 13, margin: 0, fontWeight: 'bold' }}>{ t('item.merchantReply') }</Typography>
                                                            <Typography style={{ fontSize: 13, margin: 0, fontWeight: 'bold' }}>{ reviewItem.updated_date_display }</Typography>
                                                        </Box>
                                                        <Typography style={{ fontSize: 13 }}>
                                                            { reviewItem.response }
                                                        </Typography>
                                                    </Box>
                                                    : null
                                                }
                                                <Typography style={{ margin: 0, fontSize: 13, color: '#989898' }}>{ reviewItem.created_date_display }</Typography>

                                                <Divider style={{ margin: '20px 0' }} />
                                            </Box>
                                        )
                                    })
                                : 
                                <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                                    <WebpImg src="/images/general/nodata3.png" alt="noimage" style={{ width: '150px', maxWidth: '100%' }} />
                                    <Typography variant="body2">{t('item.noRatingReview')}</Typography>
                                </Box>
                                // <Box display="flex" justifyContent="center" alignItems="center" minHeight={400}>
                                //     <Typography variant="body2">{ t('item.noRatingReview') }</Typography>
                                // </Box>
                            }
                        </Box>
                    </Hidden>

                    <Grid container spacing={1} justify="center" style={{ maxWidth: '100%' }}>
                         <Hidden smDown>
                            <Grid item xs={12} md={2} style={{ paddingTop: 20, display: 'none' }}>
                                {
                                    _.map(recommended, (value, index) => {
                                        const title_translate = _.split(value.title, '|');

                                        let showRetail = false;
                                        let discountPercent = 0;
                                        if (parseFloat(value.retail_price) > 0 && parseFloat(value.retail_price) > parseFloat(value.sell_price)) {
                                            showRetail = true;
                                            discountPercent = ((parseFloat(value.retail_price) - parseFloat(value.sell_price)) / parseFloat(value.retail_price)) * 100;
                                            discountPercent = Math.round(discountPercent);
                                        }

                                        return (
                                            <Link underline='none' key={index} to={`/item/${value.id}`} component={RouterLink}>
                                                <Card style={{ marginBottom: 10, borderRadius: 15, position: 'relative' }} variant="outlined">
                                                    <CardMedia style={{ padding: 10 }}>
                                                        {/* <WebpImg src={value.images_array ? value.images_array[0] : ""} style={{ height: 150 }} /> */}
                                                        <WebpImg containerStyle={{ height: 150, maxHeight: 150, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={value.images_array ? value.images_array[0] : ""} style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', height: 195, maxHeight:150 }}/>
                                                    </CardMedia>
                                                    <CardContent style={{ textAlign: 'center' }}>
                                                        <Tooltip title={i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0]}>
                                                            <Typography variant="body2" color="textSecondary" className='txt_truncate2'>
                                                                {i18n.language === 'cn' ? (title_translate[1] ? title_translate[1] : title_translate[0]) : title_translate[0]}
                                                            </Typography>
                                                        </Tooltip>
                                                        {
                                                            showRetail ?
                                                                <NumberFormat value={value.retail_price} decimalScale={2} displayType={'text'} style={{ fontWeight: 'bold', textDecoration: 'line-through' }} thousandSeparator={true} prefix={BasecurrencyDisplay} />
                                                            : <Typography style={{ height: 18 }}></Typography>
                                                        }
                                                        <NumberFormat value={value.sell_price} decimalScale={2} displayType={'text'} color="secondary" style={{ fontWeight: 'bold', color: theme.palette.secondary.main }} thousandSeparator={true} prefix={BasecurrencyDisplay} />
                                                    </CardContent>
                                                    {
                                                        showRetail ?
                                                            <Box position="absolute" top={0} right={0} style={{ backgroundColor: 'rgba(255,212,36,.9)' }} padding={1}>
                                                                <Typography variant="caption" style={{ fontWeight: 'bold' }} color="error">{`${discountPercent}% ${t('general.discount')}`}</Typography>
                                                            </Box>
                                                        : null
                                                    }
                                                    {/* <Box display="flex" alignItems="center" paddingY={.5} marginBottom={1.5} justifyContent="center" style={{ width: '100%', backgroundColor:'#f75757' }}>
                                                        {
                                                            _.map(value.ipoint, (idata, key) => {
                                                                if(idata.warehouse_id === 0){
                                                                    return (
                                                                        <Typography key={key} style={{ color: '#fff', fontSize:16,  }} variant="body2"><NumberFormat value={ parseFloat(idata.ipoint) } decimalScale={2} displayType={'text'} thousandSeparator={true} suffix={` i-Point`} /></Typography>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </Box> */}
                                                </Card>
                                            </Link>
                                        )
                                    })
                                }
                            </Grid>
                        </Hidden>
                        
                        <Grid item xs={12} >
                            <Hidden smDown>
                                <Box style={{ paddingTop: 20, paddingLeft: 20, display:'none' }}>
                                    <AppBar position="static" color="default" elevation={0} style={{ backgroundColor: 'transparent' }}>
                                        <Tabs
                                            value={state.tabIndex}
                                            onChange={handleChange}
                                            // variant="scrollable"
                                            scrollButtons="on"
                                            indicatorColor="secondary"
                                            textColor="secondary"
                                        >
                                            <Tab style={{ backgroundColor: state.tabIndex === 0 ? '#FFFFFF' : '', borderTopLeftRadius: 10, borderTopRightRadius: 10, fontWeight: 'bold' }} label={ t('product.productDescription') } />
                                            {/* <Tab style={{ backgroundColor: state.tabIndex === 1 ? '#FFFFFF' : '', borderTopLeftRadius: 10, borderTopRightRadius: 10, fontWeight: 'bold' }} label={ t('product.specsPackaging') } /> */}
                                            <Tab style={{ backgroundColor: state.tabIndex === 1 ? '#FFFFFF' : '', borderTopLeftRadius: 10, borderTopRightRadius: 10, fontWeight: 'bold' }} label={ t('product.productRating') } />
                                            <Tab style={{ backgroundColor: state.tabIndex === 2 ? '#FFFFFF' : '', borderTopLeftRadius: 10, borderTopRightRadius: 10, fontWeight: 'bold' }} label={ t('product.productQuestion') } />
                                        </Tabs>
                                    </AppBar>
                                    <SwipeableViews
                                        axis='x'
                                        index={state.tabIndex}
                                        onChangeIndex={handleChangeIndex}
                                    >
                                        <TabPanel value={state.tabIndex} index={0}>
                                            <div dangerouslySetInnerHTML={{ __html: product[`descr_${i18n.language}`] }} />
                                        </TabPanel>
                                        {/* <TabPanel value={state.tabIndex} index={1}>
                                            <Typography variant="body2">{t('product.weight')}: {productShippingVariant.weight} KG</Typography>
                                            <Typography variant="body2">{t('product.length')}: {productShippingVariant.length} CM</Typography>
                                            <Typography variant="body2">{t('product.width')}: {productShippingVariant.width} CM</Typography>
                                            <Typography variant="body2">{t('product.height')}: {productShippingVariant.height} CM</Typography>
                                        </TabPanel> */}
                                        <TabPanel value={state.tabIndex} index={1}>
                                            <Review productId={id} />
                                        </TabPanel>
                                        <TabPanel value={state.tabIndex} index={2}>
                                            <Question productId={id} />
                                        </TabPanel>
                                    </SwipeableViews>
                                </Box>
                            </Hidden>

                            
                                <Box style={{backgroundColor: '#fff', padding: 20 }}>
                                    <Box style={{borderBottom: '1px dashed #222', paddingBottom: 20 }}>
                                        <Typography style={{ marginBottom: 10, fontSize: 17, fontWeight: 'bold', color: '#f58826' }}>{t('product.specsPackaging')}</Typography>
                                        <Grid container className={styles.paddingVertical2}>
                                            <Typography variant="body2" style={{ color: theme.palette.gray.main, paddingRight: 10 }}>{t('item.category')}: </Typography>
                                            <Box display="flex" flexDirection="row">
                                                {
                                                    _.map(category, (categoryItem, categoryIndex) => {
                                                        return (
                                                            <Box key={categoryItem.id} display="flex" flexDirection="row">
                                                                <Link underline='none' color="primary" href={`/category/${categoryItem.id}`} >
                                                                    <Typography variant="body2">
                                                                        {categoryItem[`name_${i18n.language}`]}
                                                                    </Typography>
                                                                </Link>
                                                                {
                                                                    categoryIndex === lastIndex ?
                                                                        null
                                                                        :
                                                                        <Typography variant="body2" style={{ paddingLeft: 5, paddingRight: 5 }}>{' > '}</Typography>
                                                                }
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid container className={styles.paddingVertical2}>
                                            <Typography variant="body2" style={{ color: theme.palette.gray.main, minWidth: '50px' }}>{t('item.brand')}: </Typography>
                                            <Typography style={{ paddingLeft: '10px' }} variant="body2"> {product.goods_brand ? product.goods_brand : t('item.noBrand')}</Typography>
                                        </Grid>
                                        {
                                            // _.map(['weight', 'length', 'width', 'height'], variantItem => {
                                            //     return (
                                            //     <Grid container className={ styles.paddingVertical2 } key={variantItem}>
                                            //         <Grid item xs={2} className={ styles.paddingVertical2 }>
                                            //             <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{ t(`product.${variantItem}`) }: </Typography>
                                            //         </Grid>
                                            //         <Grid item xs={10}>
                                            //             <Typography variant="body2">{ `${ _.isUndefined(productShippingVariant[variantItem]) ? 0 : productShippingVariant[variantItem] } ${ variantItem === 'weight' ? 'KG' : 'CM' }` }</Typography>
                                            //         </Grid>
                                            //     </Grid>
                                            //     )
                                            // })
                                        }
                                    </Box>

                                    <Box>
                                        <Typography style={{ marginBottom: 10, marginTop: 20, fontSize: 17, fontWeight: 'bold', color: '#f58826' }}>{t('product.productDescription')}</Typography>
                                        <div dangerouslySetInnerHTML={{ __html: product[`descr_${i18n.language}`] }} />
                                    </Box>
                                </Box>

                            
                        </Grid>
                    </Grid>
                    
                    <Hidden only={['md', 'lg', 'xl']}>
                        <Box paddingTop={1} paddingBottom={1}>
                            <Question productId={id} />
                        </Box>
                    </Hidden>

                </Container>
                <Box className={styles.stickyFooter}  style={{ textAlign: 'right', zIndex:1299, minHeight:0, height:68 }}>
                    <Box style={{ alignItems: 'center', display:'flex', flexDirection:'row', justifyContent:'flex-end', padding:'0 20px', height:'100%' }}>
                        {
                            _.size(max_rank) > 0 &&
                            <IconButton
                                onClick={() => { navigator.clipboard.writeText(shareLink); addAlert(t('profile.copySuccess'), "success"); }}
                            >
                                <Share size={32} /><Typography style={{ fontSize: 10 }}>{t('profile.copyLink')}</Typography>
                            </IconButton>
                        } 
                        {
                            accessToken || !accessToken ?
                                <Button size="small" style={{ margin: 10, borderRadius: 30, borderWidth: 2, borderColor: theme.palette.button.main }} onClick={() => onButtonClick("buynow")} variant="outlined" aria-label="plus" disabled={buttonDisable}>
                                    <Typography variant="overline" style={{ color: theme.palette.button.main }}>{t('item.buyNow')}</Typography>
                                </Button>
                                : null
                        }
                        {
                            hideAddToCart
                                ?
                                null
                                :
                                <Button size="small" className={styles.addCartStyle} onClick={() => onButtonClick("add")} variant="contained" aria-label="plus" disabled={buttonDisable}>
                                    <Typography variant="overline" style={{ color: '#FFFFFF' }}>{t('item.addToCart')}</Typography>
                                </Button>
                        }
                    </Box>
                </Box>
                {/* <Dialog
                    fullWidth
                    open={state.shippingRegionDialog}
                    onClose={ () => setState({ ...state, shippingRegionDialog: false }) }
                >
                    <DialogContent> */}
                        {/* <Typography>{ t('item.shippingRegionRestriction') }</Typography>
                        <Box marginTop={2}>
                            {
                                _.size(merchantCompany.shipping_region_restriction) > 0 ?
                                    _.size(merchantCompany.shipping_region_restriction.countries_array) > 0 ?
                                        <Box>
                                            <Typography color="secondary" style={{ fontSize: 12 }}>{ `*${t('item.shippingRegionRestrictionDesc')}` }</Typography>
                                            <Box paddingTop={1}>
                                                {
                                                    merchantCompany.shipping_region_restriction.type === 'allow' ?
                                                    <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>{ t('item.allowedList') }</Typography>
                                                    :
                                                    <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>{ t('item.restrictedList') }</Typography>
                                                }
                                            {
                                                _.map(merchantCompany.shipping_region_restriction.countries_array_display, countryCode => {
                                                    return (
                                                        <Accordion key={countryCode.code}>
                                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                                <Typography>{ i18n.language === 'cn' ? countryCode.name_cn : countryCode.name }</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                                                                {
                                                                    _.size(merchantCompany.shipping_region_restriction.states_array) > 0 ?
                                                                        _.map(merchantCompany.shipping_region_restriction.states_array_display, stateCountryCode => {
                                                                            return _.map(stateCountryCode, stateCode => {
                                                                                return <Typography key={stateCode.code}>{ `${i18n.language === 'cn' ? stateCode.name_cn : stateCode.name}` }</Typography>
                                                                            })
                                                                        })
                                                                    : null
                                                                }
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    )
                                                })
                                            }
                                            </Box>
                                        </Box>
                                    : null
                                : <Typography color="primary" variant="caption">{ t('item.shippingNationWide') }</Typography>
                            }
                        </Box> */}
                        {/* <Typography>{ t('item.standardDelivery') }</Typography> */}
                        {/* <List>
                            <ListItem display="flex">
                                <ListItemText 
                                    primary="DHL eCommerce"
                                    secondary={t('item.deliverySub')}
                                    primaryTypographyProps={{
                                        variant: "body1",
                                        style: { fontWeight: "bold" }
                                    }}
                                    secondaryTypographyProps={{ 
                                        style: { 
                                            color: theme.palette.gray.main
                                        },
                                        variant: "caption"
                                    }}
                                />
                            </ListItem>
                            <Divider />
                            <ListItem display="flex">
                                <ListItemText 
                                    primary="Poslaju"
                                    secondary={t('item.deliverySub')}
                                    primaryTypographyProps={{
                                        variant: "body1",
                                        style: { fontWeight: "bold" }
                                    }}
                                    secondaryTypographyProps={{ 
                                        style: { 
                                            color: theme.palette.gray.main
                                        },
                                        variant: "caption"
                                    }}
                                />
                            </ListItem>
                        </List> */}
                        {/* <List>
                            <ListItem display="flex">
                                <ListItemText 
                                    primary={t('item.prepareTime')}
                                    secondary={t('item.prepareTimeSub', {'day': product.prepare_time})}
                                    primaryTypographyProps={{
                                        variant: "body1",
                                        style: { fontWeight: "bold" }
                                    }}
                                    secondaryTypographyProps={{ 
                                        style: { 
                                            color: theme.palette.gray.main
                                        },
                                        variant: "caption"
                                    }}
                                />
                            </ListItem>
                            <Divider />
                            <ListItem display="flex">
                                <ListItemText 
                                    primary={t('item.deliveryTime')}
                                    secondary={t('item.deliveryTimeSub')}
                                    primaryTypographyProps={{
                                        variant: "body1",
                                        style: { fontWeight: "bold" }
                                    }}
                                    secondaryTypographyProps={{ 
                                        style: { 
                                            color: theme.palette.gray.main
                                        },
                                        variant: "caption"
                                    }}
                                />
                            </ListItem>
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            autoFocus 
                            onClick={ () => setState({ 
                                ...state, 
                                shippingRegionDialog: false, 
                            }) } 
                            color="default"
                        >
                            <Typography variant="overline">{ t('button.close') }</Typography>
                        </Button>
                    </DialogActions>
                </Dialog> */}
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0 10px 0',
        backgroundColor: 'white',
    },
    iconButton: {
        padding: 0,
        borderRadius: 0,
    },
    input: {
        width: 100,
        textAlign: 'center',
    },
    subtitleTypography: {
        paddingRight: 10,
        paddingLeft: 10,
        fontSize: 12
    },
    vpwithoutlinethrough: {
        color: '#fff', 
        padding:'3px 20px', 
        textAlign: 'center' 
    },
    inputStyle: {
        textAlign: 'center',
        width: 100,
        fontSize: 24
    },
    addCartStyle: {
        margin: 10,
        borderRadius: 30,
        borderWidth: 2,
        borderColor: theme.palette.button.main,
        backgroundColor: theme.palette.button.main,
    },
    buyNowStyle: {
        margin: 10,
        borderRadius: 30,
        borderWidth: 2,
    },
    paddingVertical2: {
        paddingTop: 2,
        paddingBottom: 2,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    root2: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg,
        // padding:'0 0 80px',
        minHeight: '100%'
    },
    badgeStyle: {
        backgroundColor: theme.palette.background.default,
        color: "#000000"
    },
    chipStyle: {
        backgroundColor: 'red',
        color: 'white'
    },
    stickyFooter: {
        width: '100%',
        maxWidth: 420,
        position: 'fixed',
        // Top: 'calc(5% + 60px)',
        bottom: 0,
        backgroundColor:'#f2f2f2',
        boxShadow: '0 -5px 11px 0 #0000001a',
        zIndex: 1,
        minHeight: 70,
    },
}));
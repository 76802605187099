import React, { Suspense, Fragment,useState, useRef, useEffect} from "react";
import { Switch, BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import ScrollToTop from '../helper/ScrollToTop';
import { LastLocationProvider } from 'react-router-last-location';
import useNotificationLoading from '../helper/useNotificationLoading';
import { getUrl, deleteUrl, putUrl } from '../helper/ApiAction';

import NotificationLoadingProvider from '../providers/NotificationLoadingProvider';
import NotificationLoading from '../components/Layouts/NotificationLoading';

import Header from './Layouts/Header';
import Footer from './Layouts/Footer';
import ChatButton from './Layouts/ChatButton';

import Home from './Home';

import Account from './Account';
import AdminChat from './AdminChat';
import Announcement from './Announcement';
import BindSubAccount from './BindSubAccount';
import Categories from './Categories';
import AffiliantPartner from './AffiliantProgram';
import Collections from './Collections';
import ContactUs from './ContactUs';
import ContactUsForm from './ContactUsForm';
import ComingSoon from './ComingSoon';
import Callback from './Callback';
import CategoryList from './CategoryList';
import Contract from './Contract';
import ContractDetails from './ContractDetails';
import Cart from './Cart';
import Checkout from './Checkout';

import Event from './Event';

import Register from './Register';
import Item from './Item';
import VoucherItem from './VoucherItem';
import Payment from './Payment';
import PaymentCallback from './PaymentCallback';

import Faq from './Faq';
import Search from './Search';
import Shop from './Shop';
import Setting from './Setting';
import Policies from './Policies';

import GenealogySponsor from './GenealogySponsor';
import Genealogy from './Genealogy';
import Review from './Review';

import Profile from './Profile';
import ProfileBankInfo from './ProfileBankInfo';
import ProfilePassword from './ProfilePassword';
import ProfileSecurityPassword from './ProfileSecurityPassword';
import PasswordForgot from './PasswordForgot';
import PasswordReset from './PasswordReset';
import ProfileAddress from "./ProfileAddress";
import PlacementSetting from "./PlacementSetting";
import Notification from "./Notification";
import NotificationDetails from "./NotificationDetails";
import NotificationCategory from "./NotificationCategory";

import MerchantServiceAgreement from './MerchantServiceAgreement';
import PrivacyAgreement from './PrivacyAgreement';
import RefundAndReturnAgreement from './RefundAndReturnAgreement';
import TermOfUse from './TermOfUse';
import Migrate from './Migrate';


import Recruit from './Recruit';
import MyStore from './MyStore';

import VoucherRedemption from './VoucherRedemption';
import VoucherCart from './VoucherCart';
import VoucherCheckout from './VoucherCheckout';

import MyPackage from './MyPackage';
import CapConvert from './CapConvert';

import Wallet from './Wallet';
import Transfer from "./Transfer";
import Convert from "./Convert";
import Transaction from "./Transaction";
import Withdrawal from "./Withdrawal";
import WithdrawalHistory from "./WithdrawalHistory";
import OrderHistory from "./OrderHistory";
import Bonus from "./Bonus";

import Kyc from './Kyc';
import AccountLink from './AccountLink';
import AccountLinkVerify from './AccountLinkVerify';

import Chat from './Chat';
import ChatHome from './ChatHome';
import Chatting from './Chatting';

import Login from './Login';

import { BASE_URL, STRIPE_KEY, PROJECT_TYPE } from '../configs/Config';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import './css/styles.css';
import './css/util.css';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const promise = loadStripe(STRIPE_KEY);

// function GenericNotFound() {
//     return (
//         <div>Not Found</div>
//     );
// }
const NewPayment = () => {
    return (
        <Elements stripe={promise}>
            <Payment />
        </Elements>
    )
}

const Loading = () => {
    return (
        <div style={{ position: "fixed", top: '50%', left: '50%' }}>
            <CircularProgress disableShrink />
        </div>
    );
};


const routes = [
    {
        path: "/home",
        component: <Home />,
        exact: true
    },
    {
        path: "/checkout",
        component: <Checkout />,
        exact: true
    },
    {
        path: "/contract/:username",
        component: <ContractDetails />,
        exact: true
    },
    {
        path: "/contract",
        component: <Contract />,
        exact: true
    },
    {
        path: "/payment",
        component: <NewPayment />,
        exact: true
    },  
    {
        path: "/payment/:oid",
        component: <NewPayment />,
        exact: true
    },
    {
        path: "/account/:page/:oid",
        component: <Account />,
        exact: true
    },
    {
        path: "/account/:page",
        component: <Account />,
        exact: true
    },
    {
        path: "/account",
        component: <Account />,
        exact: true
    },
    {
        path: "/chat",
        component: <Chat />,
        exact: true
    },
    {
        path: "/chatHome",
        component: <ChatHome />,
        exact: true
    },
    {
        path: "/chatting",
        component: <Chatting />,
        exact: true
    },
    {
        path:"/bindSubAccount",
        component: <BindSubAccount />,
        exact: true
    },
    // {
    //     path:"/genealogySponsor",
    //     component:<GenealogySponsor />,
    //     exact: true,
    // },
    {
        path: "/community/:page",
        component: <Genealogy/>,
        exact: true
    },
    {
        path:"/profile",
        component: <Profile />,
        exact: true
    },
    {
        path:"/profile-bank-info",
        component: <ProfileBankInfo />,
        exact: true
    },
    {
        path:"/profile-password",
        component: <ProfilePassword />,
        exact: true
    },
    {
        path:"/profile-security-password",
        component: <ProfileSecurityPassword />,
        exact: true
    },
    {
        path:"/profile-address",
        component: <ProfileAddress />,
        exact: true
    },
    {
        path:"/recruit",
        component: <Recruit />,
        exact: true
    },
    {
        path:"/customerservice",
        component:<AdminChat />,
        exact:true,
    },
    {
        path:"/voucher_checkout",
        component: <VoucherCheckout />,
        exact: true
    },
    {
        path: "/myPackage",
        component: <MyPackage />,
        exact: true
    },
    {
        path: "/cap-convert",
        component: <CapConvert />,
        exact: true
    },
    {
        path: "/wallet",
        component: <Wallet />,
        exact: true
    },
    {
        path: "/transfer/:wallet",
        component: <Transfer />,
        exact: true
    },
    {
        path: "/convert/:wallet",
        component: <Convert />,
        exact: true
    },
    {
        path:"/transaction/:wallet",
        component: <Transaction />,
        exact: true
    },
    {
        path:"/withdrawal/:wallet",
        component: <Withdrawal />,
        exact: true
    },
    {
        path:"/withdrawal-history/:wallet",
        component: <WithdrawalHistory />,
        exact: true
    },
    {
        path:"/announcement",
        component: <Announcement />,
        exact: true
    },
    {
        path:"/announcement/:id",
        component: <Announcement />,
        exact: true
    },
    {
        path: "/order",
        component: <OrderHistory />,
        exact: true
    },
    {
        path: "/order/:oid",
        component: <OrderHistory />,
        exact: true
    },
    {
        path:"/bonus/:bonus",
        component: <Bonus />,
        exact: true
    },
    {
        path:"/bonus",
        component: <Bonus />,
        exact: true
    },
    {
        path:"/profile-linking",
        component: <AccountLink />,
        exact: true
    },
    {
        path:"/placement-setting",
        component: <PlacementSetting />,
        exact: true
    },
    {
        path:"/notification",
        component: <Notification />,
        exact: true
    },
    {
        path:"/notification-category",
        component: <NotificationCategory />,
        exact: true
    },
    {
         path:"/migrate",
         component: <Migrate />,
         exact: true
    },
    {
        path:"/event",
        component: <Event />,
        exact: true
   },
];
    
function PrivateRoute({ children, ...rest }) {
    const { accessToken } = useSelector(state => state.general);
    const { kycRequired, verified } = useSelector(state => state.user);
    const { id } = useSelector(state => state.user);
    const  [migrateuser,setmigrateuser] = useState([])
    getUrl(`migrate/${id}`).then(result => {
        setmigrateuser(result.downline)
    }).catch(error => {
    });
    return (
        <Route
            {...rest}
            render={({ location }) =>
                accessToken && kycRequired === true && verified < 3 ?
                    <Redirect
                        to={{
                            pathname: "/kyc",
                            state: { from: location }
                        }}
                    />
                    :
                    accessToken? (
                        migrateuser === "true" && location.pathname !=='/migrate'?
                            <Redirect
                                to={{
                                 pathname: "/migrate",
                                state: { from: location }
                                 }}
                            />
                         :
                      (children)
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    );
}

export default function App() {
    const { accessToken } = useSelector(state => state.general);
    const { verified, kycRequired, max_rank } = useSelector(state => state.user);
    
    return (
        <NotificationLoadingProvider>
            <Suspense fallback={<Loading />}>
                <Router basename={BASE_URL}>
                <LastLocationProvider>
                    <Fragment>
                        <ScrollToTop />
                        <Header />
                        <div id='chatStyle' style={{backgroundColor:'#f6f7fa', paddingBottom:120, width:420, margin:'0 auto', maxWidth:'100%', position:'relative', boxShadow:'0 0px 6px 8px #00000003'}}>
                            {/* 6 is July. */}
                            {new Date() >= new Date(2020, 6, 8, 0, 0, 0) && new Date() < new Date(2020, 6, 8, 10, 31, 0) && false?
                                <Switch>
                                    <Route path='' component={() => {
                                    window.location.href = 'https://about.bigo.shopping';
                                    return null;
                                }} />
                                </Switch>
                                :
                                <Switch>
                                    <Route path="/search/:keyword" component={Search} />
                                    <Route path="/search" component={Search} />
                                    {
                                        PROJECT_TYPE === "catalog" ? 
                                            <Route path="/mystore" component={MyStore} />
                                            :
                                            <Route path="/cart" component={Cart} />
                                    }
                                    <Route path="/setting" component={Setting} />
                                    {/* <Route path="/event" component={Event} /> */}
                                    <Route path="/policies" component={Policies} />
                                    <Route path="/merchantServiceAgreement" component={MerchantServiceAgreement} />
                                    <Route path="/privacyAgreement" component={PrivacyAgreement} />
                                    <Route path="/refundAndReturnAgreement" component={RefundAndReturnAgreement} />
                                    <Route path="/termOfUse" component={TermOfUse} />
                                    <Route path="/contactUsForm" component={ContactUsForm} />
                                    <Route path="/contactUs" component={ContactUs} />
                                    <Route path="/comingSoon" component={ComingSoon} />
                                    <Route path="/item/:id" component={Item} />
                                    <Route path="/notification_detail/:id" component={NotificationDetails} />
                                    <Route path="/voucher/:id" component={VoucherItem} />
                                    <Route path="/login" component={Login} />
                                    <Route path="/register" component={Register} />
                                    <Route path="/categorylist" component={CategoryList} />
                                    <Route path="/category/:id" component={Categories} />
                                    <Route path="/affiliant-partner" component={AffiliantPartner} />
                                    <Route path="/collection/:id" component={Collections} />
                                    <Route path="/faq" exact component={Faq} />
                                    <Route path="/" exact component={Home}></Route>
                                    <Route path="/callback" component={Callback} />
                                    <Route path="/revenuemonster/:oid/callback" render={(props) => (<Redirect to={{ pathname: `/paymentresult/${props.match.params.oid}`, search: '?source=revenuemonster' }} /> )}></Route>
                                    <Route path="/paymentresult/:oid" component={PaymentCallback} />
                                    <Route path="/paymentresult/" component={PaymentCallback} />
                                    <Route path="/shop/:shopSlug" component={Shop} />
                                    <Route path="/review/:id" component={Review} />
                                    <Route path="/forgot-password" component={PasswordForgot} />
                                    <Route path="/voucher_redemption" component={VoucherRedemption}/>
                                    <Route path="/voucher_cart" component={VoucherCart}/>
                                    <Route path="/reset-password/:token" component={PasswordReset}>{accessToken ? <Redirect to={{ pathname: "/" }} /> : <NotificationLoadingProvider><PasswordReset /><NotificationLoading /></NotificationLoadingProvider> }</Route>
                                    <Route path="/kyc">{accessToken && kycRequired === true ?  <NotificationLoadingProvider><Kyc /><NotificationLoading /></NotificationLoadingProvider> : <Redirect to={{ pathname: "/" }} /> }</Route>
                                    <Route path="/account-link" component={AccountLinkVerify}/>
                                    {
                                        _.map(routes, route => (
                                            // route.path == "/recruit" && _.size(max_rank) == 0 ? 
                                            // <PrivateRoute key={route.path} exact={route.exact} path={route.path} >{route.component}<Redirect to={{ pathname: "/" }} /></PrivateRoute>
                                            // :
                                            <PrivateRoute key={route.path} exact={route.exact} path={route.path} >{route.component}</PrivateRoute>
                                            
                                        ))
                                    }

                                    {/* <Route path="/checkout" component={Checkout} />
                                <Route path="/payment/:oid" component={Payment} />
                                <Route path="/payment" component={Payment} />
                                <Route path="/account/:page/:oid" component={Account} />
                                <Route path="/account/:page" component={Account} />
                                <Route path="/account" component={Account} />
                                <Route component={GenericNotFound} />
                                <Route path="/callback" component={Callback} /> */}
                                </Switch>
                            }
                            
                        </div>
                        {/* <ChatButton /> */}
                        <Footer />
                    </Fragment>
                </LastLocationProvider>
                </Router>
            </Suspense>
            <NotificationLoading />
        </NotificationLoadingProvider>
    );
};
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateCartTotal } from '../actions';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { getUrl, deleteUrl, putUrl } from '../helper/ApiAction';
import _ from 'lodash';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CircularProgress, Stepper, Step, StepLabel, Breadcrumbs, Checkbox, FormControl, Input, InputAdornment, Container, Chip, Button, IconButton, Typography, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Box, Hidden, Divider, List, ListItem, FormControlLabel } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { Circle, CheckCircle } from 'react-feather';

import useNotificationLoading from '../helper/useNotificationLoading';
import WebpImg from './Layouts/WebpImg';

import TitleBar from './Layouts/TitleBar';

export default function Cart() {
    const { accessToken, cartTotal } = useSelector(state => state.general);
    const { rank: user_rank } = useSelector(state => state.user);
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();

    const isMountedRef = useRef(null);
    let history = useHistory();
    const theme = useTheme();

    const [state, setState] = useState({
        checkAll: false,
        dialogShow: false,
        removeCartId: [],
        totalSelected: 0,
        totalPrice: 0,
        totalWalletPrice: {},
        open: false,
        error: false,
        message: '',
    });

    const { currency, currencyRate, currencydisplay } = useSelector(state => state.general);
    let currency_code = [];
    _.map(currencydisplay, (currency_detail, index) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }

    });

    const [cart, setCart] = useState(null);
    const [currencyData, setCurrencyData] = useState(null);
    const [wallets, setWallets] = useState([]);

    const calculateTotal = userCart => {
        let totalPrice = 0;
        let totalSelected = 0;
        let totalWalletPrice = {};

        let checkAll = true;
        _.map(userCart, merchantItem => {
            _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                _.map(cartDetails, (cartItem) => {
                    if (cartItem.selected) {
                        // let starter = (user_rank && cartItem.product.rank) ? ((user_rank === null || cartItem.product.rank.code > user_rank.code) ? true : false) : true;
                        // let itemPrice = cartItem.product.type === 'rank' ? (starter ? cartItem.product.price_starter : cartItem.product.price_repurchase) : cartItem.product.sell_price;
                        totalSelected += parseInt(cartItem.quantity);
                        let itemPrice = cartItem.product.sell_price;
                        if (_.size(cartItem.product.options) > 0) {
                            _.map(cartItem.product.options, (option) => {
                                _.map(option.pricing, (pricingOption) => {
                                    if (pricingOption.id === cartItem.product_warehouse_id) {
                                        // let priceMod = cartItem.product.type === 'rank' ? (starter ? pricingOption.price_mod_starter : pricingOption.price_mod_repurchase) : pricingOption.price_mod;
                                        let priceMod = pricingOption.price_mod;
                                        itemPrice = parseFloat(priceMod) > 0 ? parseFloat(priceMod) : itemPrice;
                                    }
                                })
                            })
                        }
                        if (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) {
                            totalPrice += parseFloat((itemPrice * cartItem.quantity).toFixed(2));
                            // const selectedPricing = _.find(cartItem.product.multi_pricings, { 'id': cartItem.multi_pricing_id });
                            // totalPrice += parseFloat((itemPrice * selectedPricing.cash_scale * cartItem.quantity).toFixed(2));
                            // if(selectedPricing.multi_wallet_type === 'separate'){
                            //     _.map(selectedPricing.multi_wallet_array, (data, key) => {
                            //         _.map(wallets, (w, key) => {
                            //             if(parseInt(data.id) === parseInt(w.id)){
                            //                 let name = _.split(w.name, '|');
                            //                 let display_name = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                            //                 if(display_name){
                            //                     const old_amount = parseFloat(totalWalletPrice[display_name] ? totalWalletPrice[display_name] : 0);
                            //                     totalWalletPrice[display_name] = (old_amount + (parseFloat(data.amount) * cartItem.quantity)).toFixed(selectedPricing.decimal);
                            //                 }
                            //             }
                            //         })
                            //     })
                            // }else{
                            //     if(parseFloat(selectedPricing.wallet_amount) > 0){
                            //         let name = [];
                            //         if(selectedPricing.multi_wallet_type === 'combine'){
                            //             name = _.split(selectedPricing.pricing_name, '|');
                            //         }else if(selectedPricing.multi_wallet_type === 'single'){
                            //             name = _.split(selectedPricing.wallet_name, '|');
                            //         }
                            //         let display_name = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                            //         if(display_name){
                            //             const old_amount = parseFloat(totalWalletPrice[display_name] ? totalWalletPrice[display_name] : 0);
                            //             totalWalletPrice[display_name] = (old_amount + (parseFloat(selectedPricing.wallet_amount) > 0 ? (parseFloat(selectedPricing.wallet_amount) * cartItem.quantity) : 0)).toFixed(selectedPricing.decimal);
                            //         }
                            //     }
                            // }
                        } else {
                            totalPrice += parseFloat((itemPrice * cartItem.quantity).toFixed(2));
                        }
                    } else if (shippingChannel !== 'unlisted') {
                        checkAll = false;
                    }
                })
            });
        })
        setState(state => ({ ...state, totalPrice, totalSelected, checkAll, totalWalletPrice }));
    };

    useEffect(() => {
        getUrl('wallets').then(response => {
            if (isMountedRef.current && response.status === 1) {
                setWallets(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        // eslint-disable-next-line
    }, [addAlert]);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        if (isMountedRef.current) {
            setLoading(false);
            getUrl('carts').then(cartList => {
                setCurrencyData(cartList.currency_data);
                setCart(cartList.data);
                calculateTotal(cartList.data);
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [wallets, addAlert]);

    const checkAll = () => {
        const newCheckAll = state.checkAll ? false : true;
        const newCart = cart;
        _.map(newCart, (merchantItem, merchantIndex) => {
            _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                _.map(cartDetails, (cartItem, cartIndex) => {
                    if (shippingChannel !== 'unlisted') {
                        if (Boolean(cartItem.selected) !== Boolean(newCheckAll)) {
                            putUrl(`carts/${cartItem.id}`, {
                                selected: newCheckAll ? 1 : 0,
                                quantity: cartItem.quantity,
                            }).catch(error => {
                                addAlert(JSON.stringify(error.message));
                            });
                        }

                        newCart[merchantIndex]['items'][shippingChannel][cartIndex] = {
                            ...cartItem, quantity: cartItem.quantity, selected: newCheckAll ? 1 : 0
                        }
                    }
                })
            })
        })
        setCart(newCart);
        setState({ ...state, checkAll: newCheckAll });
        calculateTotal(newCart);
    }

    const removeCart = async () => {
        const { removeCartId } = state;
        if (removeCartId) {
            const cartIdString = _.join(removeCartId, ',');
            deleteUrl(`carts/${cartIdString}`).then(response => {
                if (response.status === 0) {

                } else {
                    const newCart = cart;
                    _.map(newCart, (merchantItem, merchantIndex) => {
                        let merchantProduct = false;
                        _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                            newCart[merchantIndex]['items'][shippingChannel] = _.remove(cartDetails, cartItem => {
                                if (!_.includes(removeCartId, cartItem.id)) {
                                    return cartItem;
                                }
                            })
                            if (_.size(newCart[merchantIndex]['items'][shippingChannel]) !== 0) {
                                merchantProduct = true;
                            }
                        })
                        if (!merchantProduct) {
                            delete newCart[merchantIndex];
                        }
                    })
                    dispatch(updateCartTotal(cartTotal - 1));
                    setCart(newCart);
                    setState({ ...state, dialogShow: false, removeCartId: [] });
                    calculateTotal(newCart);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const updateCart = (cartId, inputQuantity, reverseSelected) => {
        let selectedCartItem = {};
        _.map(cart, merchantItem => {
            _.map(merchantItem.items, cartDetails => {
                const matchItem = _.find(cartDetails, ['id', cartId]);
                if (_.size(matchItem) > 0) {
                    selectedCartItem = matchItem;
                }
            })
        })
        let newInputQuantity = selectedCartItem.quantity;
        let newInputSelected = selectedCartItem.selected;
        if (inputQuantity) {
            newInputQuantity = parseInt(inputQuantity) > 0 ? parseInt(inputQuantity) : 1;
        }
        if (reverseSelected) {
            newInputSelected = newInputSelected ? false : true;
        }

        let newCart = cart;
        putUrl(`carts/${cartId}`, {
            selected: newInputSelected ? 1 : 0,
            quantity: newInputQuantity,
        }).then(response => {
            if (response.status === 0) {
                addAlert(_.map(response.error.cart).join(' '));
            } else {
                _.map(newCart, (merchantItem, merchantIndex) => {
                    _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                        _.map(cartDetails, (cartItem, cartIndex) => {
                            if (cartItem.id === cartId) {
                                newCart[merchantIndex]['items'][shippingChannel][cartIndex] = {
                                    ...cartItem, quantity: newInputQuantity, selected: newInputSelected
                                }
                            }
                        })
                    });
                })
                setCart(newCart);
                calculateTotal(newCart);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    const checkoutCart = () => {
        if (!accessToken) {
            // return setState(state => ({ ...state, open: true, error: true, message: t('cart.loginToProceed') }));
            return addAlert(t('cart.loginToProceed'));
        }
        let proceedCheckout = true;
        if (_.size(cart) > 0) {
            _.map(cart, merchantItem => {
                _.map(merchantItem.items, cartDetails => {
                    _.map(cartDetails, cartItem => {
                        if (cartItem.selected) {
                            let stockAvailable = 0;
                            if (_.size(cartItem.product.options) > 0) {
                                _.map(cartItem.product.options, (option) => {
                                    _.map(option.pricing, (pricingOption) => {
                                        if (pricingOption.id === cartItem.product_warehouse_id) {
                                            stockAvailable = pricingOption.quantity;
                                        }
                                    })
                                });
                            } else {
                                stockAvailable = cartItem.product.quantity;
                            }

                            if (cartItem.quantity > stockAvailable) {
                                proceedCheckout = false;
                                addAlert(t('general.stockExceedError'));
                            }
                        }
                    })
                })
            })
        }

        if (proceedCheckout) {
            return history.push("/checkout");
        }
    }

    const priceDisplay = (cartItem, unlisted, display) => {
        // unlisted: true/false
        // display: web/mobile
        // let starter = (user_rank && cartItem.product.rank) ? ((user_rank === null || cartItem.product.rank.code > user_rank.code) ? true : false) : true;
        // let productPrice = cartItem.product.type === 'rank' ? (starter ? cartItem.product.price_starter : cartItem.product.price_repurchase) : cartItem.product.sell_price;
        let productPrice = cartItem.product.sell_price;
        let priceStyle = {};
        let noMultiPricing = true;
        if (_.size(cartItem.product.options) > 0) {
            _.map(cartItem.product.options, (optionItem) => {
                _.map(optionItem.pricing, (pricingOption) => {
                    if (pricingOption.id === cartItem.product_warehouse_id) {
                        // let priceMod = cartItem.product.type === 'rank' ? (starter ? pricingOption.price_mod_starter : pricingOption.price_mod_repurchase) : pricingOption.price_mod;
                        let priceMod = pricingOption.price_mod;
                        productPrice = parseFloat(priceMod) > 0 ? parseFloat(priceMod) : productPrice;
                    }
                })
            })
        }
        if (display === 'mobile' && unlisted === false) {
            priceStyle = { color: theme.palette.price.main, fontSize: '15px' };
        } else if (display === 'mobile' && unlisted === true) {
            priceStyle = { color: "#808080", fontSize: '15px' };
        } else if (display === 'web' && unlisted === false) {
            priceStyle = { color: theme.palette.price.main };
        } else if (display === 'web' && unlisted === true) {
            priceStyle = { color: "#808080" };
        }
        return (
            <>
                {
                    (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) ?
                        _.map(cartItem.product.multi_pricings, (pricingItem, pricingIndex) => {
                            if (pricingItem.id === parseInt(cartItem.multi_pricing_id)) {
                                noMultiPricing = false;
                                let pricingText = [];
                                _.map(pricingItem.wallet_settings, (setting, key) => {
                                    let minAmount = parseFloat(setting.type === 'percent' ? productPrice * (setting.min_amount / 100) : setting.min_amount);
                                    let maxAmount = parseFloat(setting.type === 'percent' ? productPrice * (setting.max_amount / 100) : setting.max_amount);
                                    pricingText[key] = { 'prefix': `${setting.pricing_name} `, 'min_amount': parseFloat(minAmount * cartItem.quantity).toFixed(pricingItem.decimal), 'max_amount': parseFloat(maxAmount * cartItem.quantity).toFixed(pricingItem.decimal) };
                                })
                                // if ((cartItem.product.type === 'rank' && starter && pricingItem.type !== 'starter') ||
                                //     (cartItem.product.type === 'rank' && !starter && pricingItem.type !== 'repurchase') ||
                                //     (cartItem.product.type === 'normal' && pricingItem.type !== 'normal')) {
                                //     noMultiPricing = true;
                                // }
                                return (
                                    <Box key={pricingIndex} display="flex" flexDirection="column" style={unlisted === true ? { color: "#808080" } : {}}>
                                        <Typography variant="h6" style={priceStyle}>
                                            <NumberFormat value={(cartItem.quantity * productPrice * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                        </Typography>
                                        {
                                            _.map(pricingText, (data, key) => {
                                                return (
                                                    <Typography variant="overline" key={key}>
                                                        <NumberFormat value={data.min_amount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={data.prefix} />
                                                        {
                                                            data.min_amount !== data.max_amount
                                                                ?
                                                                <NumberFormat value={data.max_amount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={' - '} />
                                                                : null
                                                        }
                                                    </Typography>
                                                )
                                            })
                                        }
                                    </Box>
                                )
                            }
                        })
                        :
                        <Typography variant="h6" style={priceStyle}>
                            <NumberFormat value={(cartItem.quantity * productPrice * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                        </Typography>
                }
                {
                    (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) && noMultiPricing ?
                        <Typography variant="h6" style={{ color: "#808080", fontSize: '15px' }}>
                            {t('cart.paymentInvalid')}
                        </Typography>
                        : null
                }
            </>
        )
    }

    return (
        <Box className={styles.root2} >
            <TitleBar height={70} title={t('title.cart')} displayInfo currencyButton displayCart back pagetitle />
            <Box className='fixed-header-padding'>
                {/* <Grid container  justify="center" className={styles.breadcrumbRoot} style={{ margin: '0', maxWidth: '100%' }}>
                    <Grid item xs={10} md={5}>
                        <Stepper activeStep={0} alternativeLabel className={styles.stepperStyle}>
                            <Step>
                                <StepLabel StepIconProps={{ classes: { root: styles.icon } }}>
                                    {t('checkout.myCart')}
                                </StepLabel>
                            </Step>
                            <Step>
                                <StepLabel StepIconProps={{ classes: { text: styles.whiteText } }}>
                                    {t('checkout.fillInfo')}
                                </StepLabel>
                            </Step>
                            <Step>
                                <StepLabel StepIconProps={{ classes: { text: styles.whiteText } }}>
                                    {t('checkout.submitOrder')}
                                </StepLabel>
                            </Step>
                        </Stepper>
                    </Grid>
                </Grid> */}
                <Box className={styles.root}>
                    <Grid container spacing={1} justify="center">
                        {
                            accessToken ? null :
                                <Alert
                                    severity="warning"
                                    action={
                                        <>
                                            <Link underline='none' color="inherit" to="/register" component={RouterLink} style={{ "marginRight": 10 }}>
                                                <Button variant="contained" size="small" color="secondary">
                                                    <Typography variant="overline">{t('cart.registerNow')}</Typography>
                                                </Button>
                                            </Link>
                                            <Link underline='none' color="inherit" to="/login" component={RouterLink}>
                                                <Button variant="contained" size="small" color="secondary">
                                                    <Typography variant="overline">{t('cart.loginNow')}</Typography>
                                                </Button>
                                            </Link>
                                        </>
                                    }
                                >
                                    {t('cart.noLogin')}
                                </Alert>
                        }
                        <Container>
                            <Box item xs={12}>
                                <List>
                                    {
                                        _.size(cart) > 0 && cart !== null ?
                                            _.map(cart, (merchantItem, merchantId) => {
                                                return (
                                                    <Box key={merchantId} style={{ marginBottom: '20px', boxShadow: '0 0 14px 0 #00000026' }}>
                                                        <Box border={1} display="flex" flexDirection="row" alignItems="center" borderColor={theme.palette.gray.border} style={{ padding: 10, backgroundColor: '#0e386b', color: '#fff' }}>
                                                            <StorefrontIcon fontSize="small" style={{ marginLeft: 20 }} />
                                                            <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 5 }}>{merchantItem.name}</Typography>
                                                        </Box>
                                                        {
                                                            _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                                                                return (
                                                                    _.map(cartDetails, (cartItem) => {
                                                                        const title_translate = i18n.language === 'cn' ? cartItem.product.title_cn : cartItem.product.title_en;
                                                                        let variant_translate = [];
                                                                        let stockAvailable = 0;
                                                                        if (_.size(cartItem.product.options) > 0) {
                                                                            _.map(cartItem.product.options, (option) => {
                                                                                _.map(option.pricing, (pricingOption) => {
                                                                                    if (pricingOption.id === cartItem.product_warehouse_id) {
                                                                                        if (option.color) {
                                                                                            variant_translate.push(`${(i18n.language === 'cn' ? (option.color_cn ? option.color_cn : option.color_en) : option.color_en)}`);
                                                                                        }
                                                                                        if (option.size) {
                                                                                            variant_translate.push(`${(i18n.language === 'cn' ? (option.size_cn ? option.size_cn : option.size_en) : option.size_en)}`);
                                                                                        }
                                                                                        if (option.attribute_value) {
                                                                                            variant_translate.push(`${(i18n.language === 'cn' ? (option.attribute_value_cn ? option.attribute_value_cn : option.attribute_value_en) : option.attribute_value_en)}`);
                                                                                        }
                                                                                        stockAvailable = pricingOption.quantity;
                                                                                    }
                                                                                })
                                                                            });
                                                                        } else {
                                                                            stockAvailable = cartItem.product.quantity;
                                                                        }
                                                                        return (
                                                                            <Box key={cartItem.id} style={{ backgroundColor: '#fff' }}>
                                                                                <ListItem style={{ padding: '0', width: '100%' }}>
                                                                                    {
                                                                                        (shippingChannel === 'unlisted')
                                                                                            ?
                                                                                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10, width: '100%' }}>
                                                                                                <Checkbox style={{ width: 35, padding: 0 }}
                                                                                                    checked={Boolean(cartItem.selected)}
                                                                                                    icon={<Circle />}
                                                                                                    disabled
                                                                                                />
                                                                                                <Box className={styles.imageSize} border={1} borderColor={theme.palette.gray.border}>
                                                                                                    <WebpImg className={styles.imgStyle} alt={cartItem.product.id} src={cartItem.product.images_array[0] ? cartItem.product.images_array[0] : '/images/logo.png'} />
                                                                                                </Box>

                                                                                                <Box style={{ paddingLeft: 15, width: '100%' }}>
                                                                                                    <Box style={{ marginTop: 10 }}>
                                                                                                        <Chip size="small" style={{ margin: 5 }} label={<Typography variant="caption" style={{ color: "#808080" }}>{t('product.unlistedProduct')}</Typography>} variant="outlined" color="default" />
                                                                                                    </Box>
                                                                                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'flex-start' }}>
                                                                                                        <Grid>
                                                                                                            <Typography variant="caption" style={{ color: "#808080", }}>{title_translate}</Typography>
                                                                                                        </Grid>
                                                                                                        <Button
                                                                                                            style={{ minWidth: 'unset', width: 'unset', padding: 0 }}
                                                                                                            variant="text"
                                                                                                            onClick={() => setState({ ...state, dialogShow: true, removeCartId: [cartItem.id] })}
                                                                                                            color="secondary"
                                                                                                            fullWidth
                                                                                                        >
                                                                                                            <DeleteIcon />
                                                                                                        </Button>
                                                                                                    </Box>
                                                                                                    <Box>
                                                                                                        {
                                                                                                            _.size(variant_translate) > 0 ?
                                                                                                                <Grid item xs={12}>
                                                                                                                    <Typography variant="caption" style={{ fontSize: 10, color: "#808080" }}>{t('cart.variations')}: </Typography>
                                                                                                                    {
                                                                                                                        _.map(variant_translate, variantText => {
                                                                                                                            return (
                                                                                                                                <Chip key={variantText} size="small" style={{ marginLeft: 5 }} label={<Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}</Typography>} variant="outlined" color="default" />
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </Grid>
                                                                                                                : null
                                                                                                        }
                                                                                                    </Box>
                                                                                                    <Box>
                                                                                                        {priceDisplay(cartItem, true, 'mobile')}
                                                                                                    </Box>
                                                                                                    <FormControl style={{ margin: '5px 0' }}>
                                                                                                        <Input
                                                                                                            type="text"
                                                                                                            value={cartItem.quantity}
                                                                                                            disableUnderline={true}
                                                                                                            fullWidth={true}
                                                                                                            classes={{ input: styles.inputStyle }}
                                                                                                            disabled
                                                                                                            startAdornment={
                                                                                                                <InputAdornment position="start">
                                                                                                                    <IconButton
                                                                                                                        style={{ padding: '0' }}
                                                                                                                        disabled
                                                                                                                    >
                                                                                                                        <RemoveIcon />
                                                                                                                    </IconButton>
                                                                                                                </InputAdornment>
                                                                                                            }
                                                                                                            endAdornment={
                                                                                                                <InputAdornment position="end">
                                                                                                                    <IconButton
                                                                                                                        style={{ padding: '0' }}
                                                                                                                        disabled
                                                                                                                    >
                                                                                                                        <AddIcon />
                                                                                                                    </IconButton>
                                                                                                                </InputAdornment>
                                                                                                            }
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                    <Box>
                                                                                                        {
                                                                                                            cartItem.quantity > stockAvailable ?
                                                                                                                <Grid item xs={12} style={{ padding: 10, alignSelf: 'center', textAlign: 'right' }}>
                                                                                                                    <Typography variant="caption" color="error">{t('cart.stockAvailable')}: {stockAvailable}</Typography>
                                                                                                                </Grid>
                                                                                                                : null
                                                                                                        }
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </Box>
                                                                                            :
                                                                                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 10, width: '100%' }}>
                                                                                                <Checkbox style={{ width: 35, padding: 0 }}
                                                                                                    checked={Boolean(cartItem.selected)}
                                                                                                    icon={<Circle />}
                                                                                                    checkedIcon={<CheckCircle />}
                                                                                                    onChange={() => updateCart(cartItem.id, null, true)}
                                                                                                />
                                                                                                <Box className={styles.imageSize} border={1} borderColor={theme.palette.gray.border}>
                                                                                                    <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} component={RouterLink}>
                                                                                                        <WebpImg className={styles.imgStyle} alt={cartItem.product.id} src={cartItem.product.images_array[0] ? cartItem.product.images_array[0] : '/images/logo.png'} />
                                                                                                    </Link>
                                                                                                </Box>

                                                                                                <Box style={{ paddingLeft: 15, width: '100%' }}>
                                                                                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'flex-start' }}>
                                                                                                        <Grid style={{ paddingRight: 10 }}>
                                                                                                            <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} component={RouterLink}><Typography style={{fontSize:16, fontWeight:'bold'}} className={styles.textline2}>{title_translate}</Typography></Link>
                                                                                                        </Grid>

                                                                                                        <Button
                                                                                                            style={{ minWidth: 'unset', width: 'unset', padding: 0 }}
                                                                                                            variant="text"
                                                                                                            onClick={() => setState({ ...state, dialogShow: true, removeCartId: [cartItem.id] })}
                                                                                                            color="secondary"
                                                                                                            fullWidth
                                                                                                        >
                                                                                                            <DeleteIcon />
                                                                                                        </Button>
                                                                                                    </Box>

                                                                                                    <Box>
                                                                                                        {
                                                                                                            _.size(variant_translate) > 0 ?
                                                                                                                <Grid item xs={12}>
                                                                                                                    <Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{t('cart.variations')}: </Typography>
                                                                                                                    {
                                                                                                                        _.map(variant_translate, variantText => {
                                                                                                                            return (
                                                                                                                                <Chip key={variantText} size="small" style={{ marginLeft: 5 }} label={<Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}</Typography>} variant="outlined" color="default" />
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </Grid>
                                                                                                                : null
                                                                                                        }
                                                                                                    </Box>
                                                                                                    <Box>
                                                                                                        {priceDisplay(cartItem, false, 'mobile')}
                                                                                                    </Box>

                                                                                                    <FormControl style={{ margin: '5px 0' }}>
                                                                                                        <Input
                                                                                                            type="text"
                                                                                                            value={cartItem.quantity}
                                                                                                            disableUnderline={true}
                                                                                                            fullWidth={true}
                                                                                                            classes={{ input: styles.inputStyle }}
                                                                                                            onChange={({ target }) => updateCart(cartItem.id, target.value)}
                                                                                                            startAdornment={
                                                                                                                <InputAdornment position="start">
                                                                                                                    <IconButton
                                                                                                                        style={{ padding: '0' }}
                                                                                                                        color="secondary"
                                                                                                                        onClick={() => updateCart(cartItem.id, parseInt(cartItem.quantity) - 1)}
                                                                                                                    >
                                                                                                                        <RemoveIcon />
                                                                                                                    </IconButton>
                                                                                                                </InputAdornment>
                                                                                                            }
                                                                                                            endAdornment={
                                                                                                                <InputAdornment position="end">
                                                                                                                    <IconButton
                                                                                                                        style={{ padding: '0' }}
                                                                                                                        color="secondary"
                                                                                                                        onClick={() => updateCart(cartItem.id, parseInt(cartItem.quantity) + 1)}
                                                                                                                    >
                                                                                                                        <AddIcon />
                                                                                                                    </IconButton>
                                                                                                                </InputAdornment>
                                                                                                            }
                                                                                                        />
                                                                                                    </FormControl>
                                                                                                    <Box>
                                                                                                        {
                                                                                                            cartItem.quantity > stockAvailable ?
                                                                                                                <Grid item xs={12} style={{ padding: 10, alignSelf: 'center', textAlign: 'right' }}>
                                                                                                                    <Typography variant="caption" color="error">{t('cart.stockAvailable')}: {stockAvailable}</Typography>
                                                                                                                </Grid>
                                                                                                                : null
                                                                                                        }
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </Box>
                                                                                    }
                                                                                </ListItem>
                                                                                <Divider />
                                                                            </Box>
                                                                        )
                                                                    })
                                                                )
                                                            })
                                                        }
                                                    </Box>
                                                )
                                            })
                                            :
                                            <Box minHeight={100} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                                                {
                                                    cart === null ?
                                                        <>
                                                            <CircularProgress disableShrink />
                                                            <Typography variant="caption">{t('general.fetchingData')}</Typography>
                                                        </>
                                                        :
                                                        <Box>
                                                            <WebpImg className={styles.imgStyle} alt={'emptycart'} src={'/images/general/empty-cart2.png'} style={{ width: '150px', maxWidth: '50%' }} />
                                                            <Typography style={{ textAlign: 'center', paddingLeft: '15px' }}>{t('general.noProduct')}</Typography>
                                                        </Box>
                                                }
                                            </Box>
                                    }
                                </List>
                            </Box>
                        </Container>
                    </Grid>
                </Box>
                {/* delete dialog */}
                <Dialog
                    open={state.dialogShow}
                    onClose={() => setState({ ...state, dialogShow: false })}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {t('cart.removeTitle')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('cart.removeContent')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={() => setState({
                                ...state,
                                dialogShow: false,
                                removeCartId: []
                            })}
                            color="default"
                        >
                            <Typography variant="overline">{t('button.cancel')}</Typography>
                        </Button>
                        <Button onClick={removeCart} color="secondary">
                            <Typography variant="overline">{t('button.confirm')}</Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* checkout part */}
                {
                    _.size(cart) > 0 && cart !== null ?
                        <>
                            <Box style={{ width: '100%', backgroundColor: '#f2f2f2', padding: '10px 15px 18px', position: 'fixed', bottom: -7, width: 420, boxShadow: '0 -5px 11px 0 #0000001a', zIndex: 10, maxWidth: '100%', minHeight: 80, display: 'flex', alignItems: 'center' }}>
                                <Grid item xs={12} sm={10} style={{ margin: '0 auto' }}>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box style={{ textAlign: 'start' }} >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={state.checkAll}
                                                        icon={<Circle />}
                                                        checkedIcon={<CheckCircle />}
                                                        onChange={checkAll}
                                                        size='small'
                                                    />
                                                }
                                                label={
                                                    <Box>
                                                        <Typography variant="subtitle2" style={{ color: '#383838' }}>{t('cart.all')}</Typography>
                                                    </Box>
                                                }
                                            />
                                        </Box>
                                        <Box classes={{ root: styles.tableCellStyle }} >
                                            <Box>
                                                <Typography variant="overline" style={{ fontWeight: 'bold', lineHeight: 'unset', whiteSpace: 'nowrap', fontSize: '11px' }}>
                                                    {t('cart.subTotal')}: <br />
                                                    {
                                                        state.totalPrice > 0
                                                            ?
                                                            <NumberFormat style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#0f386b' }} className={styles.priceStyle} value={state.totalPrice * currencyRate[`${currency}`]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                            :
                                                            null
                                                    }
                                                </Typography>
                                            </Box>
                                            {
                                                _.size(state.totalWalletPrice) > 0 ?
                                                    _.map(state.totalWalletPrice, (walletTotal, walletType) => {
                                                        return (
                                                            <Box key={walletType}>
                                                                <Typography variant="overline" style={{ color: 'white', fontWeight: 'bold', lineHeight: 'unset', whiteSpace: 'nowrap', fontSize: '11px' }} className={styles.priceStyle}>
                                                                    <NumberFormat className={styles.priceStyle} style={{ whiteSpace: 'nowrap', fontSize: '14px', color: '#0f386b' }} value={walletTotal} displayType={'text'} thousandSeparator={true} prefix={`${walletType} `} />
                                                                </Typography>
                                                            </Box>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </Box>
                                        <Box classes={{ root: styles.tableCellStyle }} style={{ marginLeft: '5px' }} >
                                            <Button variant="contained" size="large" style={{ backgroundColor: (state.totalSelected > 0 ? '#0e386b' : '#e0e2e2'), color: (state.totalSelected > 0 ? '#fff' : '#8e8d8d'), padding: '5px 12px' }} disabled={state.totalSelected > 0 ? false : true} onClick={checkoutCart}>
                                                <Typography variant="overline">{t('cart.checkout')}</Typography>
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Box>
                        </>
                        :
                        null
                }
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0 10px 0',
    },
    inputStyle: {
        textAlign: 'center',
        width: 60,
        fontSize: 16,
        padding: 5,
    },
    priceStyle: {
        fontSize: 14,
        // color: theme.palette.secondary.main,
        color: 'white',
    },
    icon: {
        color: `${theme.palette.button.step} !important `,
    },
    whiteText: {
        fill: '#fff'
    },
    stepperStyle: {
        backgroundColor: 'transparent',
        padding: 0,
    },
    containerStyle: {
        borderWidth: 20
    },
    tableCellStyle: {
        borderBottom: 0,
        textAlign: 'end'
    },
    imgStyle: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    imageSize: {
        [theme.breakpoints.down('sm')]: {
            width: 80,
            minWidth: 80,
            maxWidth: 80,
            height: 80,
        },
        [theme.breakpoints.up('sm')]: {
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            height: 100,
        },
    },
    root2: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg,
        // padding:'0 0 80px',
        minHeight: '100%'
    },
    textline2: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
    }
}));
import React, { useState, useEffect, useCallback, useRef } from 'react'; //,{ useState, useEffect, useRef, createRef } 
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import WebpImg from '../Layouts/WebpImg';
import QRCode from 'qrcode.react';

import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, makeStyles, Typography, useTheme, Link, Avatar, Dialog, Hidden, Button, Grid, Badge, Menu, MenuItem, Select, IconButton, Tooltip } from '@material-ui/core';
import { changeCurrency as reduxChangeCurrency } from '../../actions';
import { FiChevronLeft } from 'react-icons/fi';
import { AiOutlineQrcode } from 'react-icons/ai'
import { LOCAL_URL } from '../../configs/Config';
import useNotificationLoading from '../../helper/useNotificationLoading';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { getUrl, logoutUser } from '../../helper/ApiAction';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { LocalMall } from '@material-ui/icons';

export default function TitleBar(props) {
    const { title, height = 150, back = false, backUrl = null, children, displayQr = false, displayCart = false, linkMall = false, displayCartTop = false, displayInfo = false, logoIcon = false, pagetitle = false, backtext = false, displayUsername = false, currencyButton = false } = props;

    const [showQr, setShowQr] = useState(false);
    const [qrUrl, setQrUrl] = useState("");

    const theme = useTheme();
    const classes = useStyles();
    const { id, username, landing_icon, max_rank: rank, max_mrank, contract, expiry_date, account_link } = useSelector(state => state.user);
    const { cartTotal, accessToken, currency, currencyRate, currencydisplay } = useSelector(state => state.general);
    const history = useHistory();
    const [contractNameDisplay, setContractNameDisplay] = useState('');
    const shareLink = useRef(null);
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const dispatch = useDispatch();
    const [showIshoppingLink, setShowIshoppingLink] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        let name = '-';
        if (contract) {
            _.map(contract, (corder) => {
                if (corder.status === 20) {
                    name = corder.contract_name_display;
                }
            })
        }
        setContractNameDisplay(name);
    }, [contract]);

    const showQR = useCallback(() => {
        setQrUrl(`${LOCAL_URL}/register?r=${btoa(id)}`);
        setShowQr(true);
    }, [id]);

    const copyShareLink = useCallback((e) => {
        shareLink.current.select();
        document.execCommand('copy');
        e.target.focus();

        addAlert(t('profile.copySuccess'), "success");
    }, [id]);

    const daysLeft = expiry_date => {
        var today = new Date();

        expiry_date = expiry_date.substring(0, 10);
        var expiry = new Date(expiry_date);

        if (expiry <= today) return 0;

        var diffInTime = expiry.getTime() - today.getTime();
        return Math.ceil((diffInTime / (1000 * 3600 * 24)));
    }

    const formatDate = date => {
        date = date.substring(0, 10);
        let theDate = new Date(date);
        let offset = theDate.getTimezoneOffset();
        theDate = new Date(theDate.getTime() - (offset * 60 * 1000));
        return theDate.toISOString().substring(0, 10);
    }


    useEffect(() => {
        _.map(account_link, linking => {
            if (linking.source === 'ishopping') {
                setShowIshoppingLink(true);
            }
        })
    }, [account_link]);

    const goToIshopping = () => {
        if (accessToken) {
            getUrl(`/login-to/ishopping`).then(response => {
                setLoading(false);
                if (response.status === 1 && response.url) {
                    window.open(response.url, '_blank').focus();
                } else {
                    addAlert(t('general.loginRedirectError'));
                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        }
    }
    const changeCurrency = (event) => {
        dispatch(reduxChangeCurrency(event.target.value));
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <>

            {
                displayInfo &&

                <Box height={height}  className='transformHeader' paddingX={2} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" style={{ background: theme.palette.primary.main, position:'fixed', width:420, maxWidth:'100%', zIndex:99 }}>
                    {/* background={background} */}
                    {
                        linkMall && showIshoppingLink &&
                        <Box onClick={() => goToIshopping()} style={{ marginRight: 30, cursor: 'pointer', display: 'flex', alignItems: 'center', backgroundColor: "#fff", borderTopRightRadius: 24, borderBottomRightRadius: 24, padding: '5px 15px' }} >
                            <Typography style={{ color: theme.palette.primary.main, fontSize: 14 }}>{t('general.ishoppingMall')}</Typography>
                            <LocalMall fontSize="default" style={{ color: theme.palette.primary.main, marginLeft: 8, fontSize: 17 }} />
                        </Box>
                    }
                    <div style={{display:'flex', alignItems:'center'}}>
                        {/* //logo and back */}
                        <div>
                            {back &&
                                <div style={{ zIndex: 3 }}>
                                    {backUrl ?
                                        <Link underline='none' to={backUrl} className={classes.backIconStyle} component={RouterLink} style={{ zIndex: 3 }} >
                                            <FiChevronLeft style={{ color: theme.palette.primary.main, fontSize: 28, background: '#fff', borderRadius: 48, padding: 2,}} />
                                        </Link>
                                        : <div onClick={() => history.goBack()} className={classes.backIconStyle}>
                                            <FiChevronLeft style={{ color: theme.palette.primary.main, fontSize: 28, background: '#fff', borderRadius: 48, padding: 2,}} />
                                        </div>
                                    }

                                </div>
                            }
                            {logoIcon &&
                                <Box className={classes.rankWrapperStyle}>
                                    <Box style={{ borderRadius: '50%' }}>
                                        {
                                            _.size(landing_icon) > 0 && landing_icon ?
                                                <Avatar className={classes.large} style={{ color: theme.palette.primary.main }} alt="icon" src={landing_icon.file_name} />
                                                :
                                                // <Typography className={classes.rankTitleStyle}>{_.size(username) > 0 ? username[0] : '-'}</Typography>
                                                <Box className={classes.rankTitleStyle}>
                                                    <Link underline='none' to="/home" component={RouterLink}>
                                                    <WebpImg src="/images/logo.png" alt="logo" style={{height:40}} containerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
                                                    </Link>
                                                </Box>
                                        }
                                    </Box>
                                </Box>
                            }
                        </div>
                        {/* //pagetitle and username */}
                        <div style={{paddingLeft:0}}>
                                {/* {pagetitle &&
                                    <div onClick={() => history.goBack()}>
                                        <div className={classes.titleWrapperStyle} >
                                            <Typography classes={{ root: classes.titleStyle }}>{title}</Typography>
                                        </div>
                                    </div>
                                }
                            {backtext &&
                                    <div onClick={() => history.goBack()}>
                                        <div className={classes.titleWrapperStyle} >
                                            <Typography classes={{ root: classes.titleStyle }} >{t('button.back')}</Typography>
                                        </div>
                                    </div>
                            } */}
                            {displayUsername &&
                                <Box flex={1}>
                                    {children ? children :
                                        <>
                                            {accessToken ?
                                                <Box display="flex" flexDirection="column" >
                                                    <Link underline='none' to="/profile" component={RouterLink}>
                                                        <Typography className={classes.usernameStyle}>{username}</Typography>
                                                    </Link>
                                                </Box>
                                                : 
                                                <div className={classes.titleWrapperStyle} >
                                                    <Typography classes={{ root: classes.titleStyle }}>{title}</Typography>
                                                </div>
                                            }
                                        </>
                                    }
                                </Box>
                            }
                        </div>
                    </div>
                    {/* {displayCartTop &&
                        <Link underline='none' to="/cart" component={RouterLink} style={{ marginRight: 30 }}>
                            <Badge badgeContent={cartTotal} classes={{ badge: classes.badgeStyle }}>
                                <ShoppingCartIcon style={{ color: "white" }} />
                            </Badge>
                        </Link>
                    } */}
                    
                    <Box display="flex" justifyContent="center" alignItems="center">
                            {accessToken ?
                                <>
                                    {displayCart &&
                                        <Link underline='none' to="/cart" component={RouterLink} style={{ marginRight: 15 }}>
                                            <Badge badgeContent={cartTotal} classes={{ badge: classes.badgeStyle }}>
                                                <ShoppingCartIcon style={{ color: "white" }} />
                                            </Badge>
                                        </Link>
                                    }
                                </>
                                :
                                <Link underline='none' color="inherit" to="/login" component={RouterLink}>
                                    <div style={{ backgroundColor: theme.palette.white.main, color: theme.palette.primary.main, borderRadius: 5, padding: '5px 15px' }}>
                                        {t('button.login')}
                                    </div>
                                </Link>
                            }
                        
                        {currencyButton &&
                        <Tooltip title={t('home.currency')}>
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open}
                                aria-haspopup="true"
                                aria-expanded={open}
                            >
                                <Avatar sx={{ width: 32, height: 32 }} style={{ backgroundColor: 'transparent' }}><MonetizationOnIcon style={{ width: 28, height: 28 }} /></Avatar>
                            </IconButton>
                        </Tooltip>
                        }
                        {false && displayQr && <Box display="flex" style={{ cursor: 'pointer' }} onClick={showQR}>
                            {/* <Grid style={{ color:theme.palette.secondary.main }}/> */}
                            {/* <FontAwesomeIcon icon={faQrcode} color={theme.palette.secondary.main} style={{ width: 25, height: 25 }} /> */}
                            <AiOutlineQrcode color={theme.palette.white.main} style={{ width: 30, height: 30 }} />
                        </Box>}
                    </Box>
                </Box>
            }
            <Dialog
                open={showQr}
                onClose={() => setShowQr(false)}
                maxWidth="md"
            >
                <Box style={{ padding: '20px 0 ' }}>
                    <Box display="flex" flexDirection="center" width="100%" alignItems="center" justifyContent="center" padding={3}>
                        <QRCode value={qrUrl} renderAs="svg" fgColor={theme.palette.primary.main} />
                    </Box>
                    <Box display="flex" flexDirection="center" width="100%" alignItems="center" justifyContent="center" padding={3} >
                        <Grid container spacing={2}>
                            <Grid item xs style={{ textAlign: "center" }}>
                                <form>
                                    <textarea ref={shareLink} value={qrUrl} readOnly style={{ border: 'none', resize: 'none', outline: 'none' }} />
                                </form>
                            </Grid>
                            {document.queryCommandSupported('copy') &&
                                <Grid item xs style={{ textAlign: "center" }}>
                                    <Button variant="contained" color="secondary" onClick={copyShareLink}>{t('profile.copyLink')}</Button>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Box>
            </Dialog>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                {_.map(currencydisplay, (currencydisplay, key) => {
                    return (<MenuItem onClick={(event, value) => changeCurrency({ target: { value: currencydisplay.code } })}>{currencydisplay.code}</MenuItem>)
                })}
            </Menu>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    titleContainerWrapperStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        position: 'relative',
    },
    backIconStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        cursor: 'pointer',
    },
    titleWrapperStyle: {
        flexGrow: 1
    },
    titleStyle: {
        textAlign: 'center',
        color: theme.palette.white.main
    },
    rankWrapperStyle: {
        // border: '3px solid',
        // borderColor: theme.palette.secondary.main,
        padding: 3,
        height: 60,
        width: 60,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // background: 'rgb(168,120,33)',
        // background: 'linear-gradient(90deg, rgba(168,120,33,1) 0%, rgba(252,242,169,1) 19.59%, rgba(255,196,79,1) 50%, rgba(252,237,166,1) 80.42%, rgba(184,140,54,1) 100%)'
    },

    rankTitleStyle: {
        // color: theme.palette.secondary.main,
        fontSize: 36,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        backgroundColor: '#152a43',
        height: 52,
        width: 52,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '-webkit-linear-gradient(135deg, rgba(168,120,33,1) 0%, rgba(252,242,169,1) 19.59%, rgba(255,196,79,1) 50%, rgba(252,237,166,1) 80.42%, rgba(184,140,54,1) 100%)',
        WebkitBackgroundClip: ' text',
        WebkitTextFillColor: 'transparent',
    },
    dividerStyle: {
        padding: '0 10px'
    },
    usernameStyle: {
        fontSize: 15,
        color: theme.palette.white.main,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    infoStyle: {
        color: theme.palette.white.main,
        fontSize: 11,
        letterSpacing: 1
    },
    large: {
        width: 56,
        height: 55,
    },
    badgeStyle: {
        backgroundColor: '#ffb92d',
        color: "#fff"
    }
}));
import { red, orange, lightBlue, deepOrange, teal, deepPurple, indigo, green,cyan, brown, blueGrey, amber } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// A custom theme for this app
let theme = createMuiTheme({
    palette: {
        primary: {
            main: '#e53935',
        },
        secondary: {
            main: '#e53935',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#f7f7f7',
            review: '#efefef',
        },
        white: {
            main: '#FFFFFF',
        },
        green: {
            main: '#4caf50',
        },
        background: {
            body: '#f6f7fa'
        },
        text:{
            primary:'#696d84',
            primaryLight:'#9ea1b7',
            lightBlue:'#fff',
        },
        gray: {
            main: '#b5b5b5',
            border: '#dee2e6',
            chip: '#b8b9b9',
            text:'#5b5b5b',
            dtext:'#696969',
            textTitle:'#979797',
            ltext: '#d1d1d1',
            lightTitle: '#adacac',
        },
        silver:{
            bg:'#bebfc4',
            bgDarker:'#9d9ea2',
            text:'#939393',
            textDarker:'#6b6b6b',
        },
        gold:{
            bg:'#d8b57c',
            bgDarker:'#aa8743',
            text:'#dcb574',
            textLight:'#efcd97'
        },
        price: {
            main: '#e53935',
        },
        profile: {
            user: orange.A400,
            order: lightBlue.A400,
            notification: deepOrange.A400,
            wallets: deepPurple.A400,
            integration: teal.A400,
            likes: red.A400,
            chat:indigo.A400,
            voucher: red.A700,
            sponsor: green.A400,
            placement:cyan.A400,
            recruit:brown.A400,
            bindSubAccount:blueGrey.A400,
            accountLink: amber.A400,
        },
        button: {
            main: '#e53935',
            step: '#fdb93f',
        },
    },
    breakpoints: {
        values: {
          ss:300,
          s:400,
          xs: 500,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
});

theme = responsiveFontSizes(theme);

export default theme;
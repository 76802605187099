import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { logoutUser, getUrl, removeLoginAccess, postUrl } from '../../helper/ApiAction';
import _ from 'lodash';

import { fade, makeStyles } from '@material-ui/core/styles';
import { Hidden, Box, AppBar, Toolbar, IconButton, TextField, Badge, Menu, MenuItem, Container, Button, Link, InputAdornment, useTheme, useScrollTrigger, Slide } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import StorefrontIcon from '@material-ui/icons/Storefront';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from 'react-i18next';
import { changeLanguage as reduxChangeLanguage, storeUuid, removeUuid, storeProfile, updateCartTotal, availableEvent2, storeWallets,changeCurrencyRate } from '../../actions';
import { API } from '../../configs/AxiosConfig';
import { PROJECT_TYPE, DEFAULT_LANGUAGE } from '../../configs/Config';

import { v4 as uuidv4 } from 'uuid';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { Globe, ShoppingCart, User, Search } from 'react-feather';
import useNotificationLoading from '../../helper/useNotificationLoading';

import PropTypes from 'prop-types';

import SearchIcon from '@material-ui/icons/Search';
import WebpImg from './WebpImg';

const HIDE_COMPONENT_PAGE = ["login", "register", "checkout", "payment"];

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default function Header(props) {
    const { uuid, cartTotal, accessToken } = useSelector(state => state.general);
    const styles = useStyles();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    let history = useHistory();
    const location = useLocation();
    const theme = useTheme();
    const { setLoading, addAlert } = useNotificationLoading();

    const [state, setState] = useState({
        languageAnchorEl: null,
        profileAnchorEl: null,
        mobileAnchorEl: null,
    })

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;
    const [keyword, setKeyword] = useState('');
    const [currentPath, setCurrentPath] = useState();
    
    useEffect(() => {
        let active = true;

        if (active) {
            const path = _.split(location.pathname, '/');
            if (path) {
                setCurrentPath(path[1].toLowerCase());
            }
        }
        return () => { active = false };
    }, [location.pathname])

    useEffect(() => {
        let active = true;

        if (keyword) {
            getUrl(`keyword/${encodeURIComponent(keyword)}`)
                .then(response => {
                    if (active) {
                        let returnData = [];
                        _.map(response.data, (value, index) => {
                            returnData[index] = { name: value, id: index };
                        })
                        setOptions(returnData);
                    }
                    // setOptions(response.data);
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        }

        return () => { active = false };
    }, [keyword, addAlert, t])

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        if (_.includes(['en', 'cn'], i18n.language) === false) {
            changeLanguage(DEFAULT_LANGUAGE || 'en');
        }
        API.defaults.headers.common['Language'] = i18n.language === 'cn' ? 'zh-CN' : 'en';
        // eslint-disable-next-line
    }, [i18n.language, t]);

    // useEffect(() => {
    //     let ipNotIncludesPath = '/comingSoon';
    //     let ipAllowArray = ['175.143.34.14'];

    //     axios.get('https://api64.ipify.org/').then((response) => {
    //         if(!ipAllowArray.includes(response.data)){
    //             if(location.pathname != ipNotIncludesPath){
    //                 setLoading(false);
    //                 history.push(ipNotIncludesPath);
    //             }
    //         }
    //     }).catch((error) => {
    //         console.log("ip address error", error);
    //     });

    //     // eslint-disable-next-line
    // }, [location.pathname]);

    useEffect(() => {
        if (accessToken) {
            API.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            if (API.defaults.headers.common['Guest-Token']) {
                delete API.defaults.headers.common['Guest-Token'];
                postUrl('carts/migrate', {
                    guest_token: uuid
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
            getUrl('user')
                .then(userData => {
                    if (uuid) {
                        dispatch(removeUuid());
                    }
                    if (userData.status === 1) {
                        dispatch(storeProfile(userData.data));
                    }
                }).catch(error => {
                    removeLoginAccess();
                });
        } else {
            delete API.defaults.headers.common['Authorization'];
            API.defaults.headers.common['Guest-Token'] = uuid;
        }

        if(PROJECT_TYPE === "catalog"){
            getUrl('mystore/products').then(cartList => {                
                dispatch(updateCartTotal(cartList.data.total));
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }else{
            getUrl('carts')
                .then(cartList => {
                    let cartTotal = 0;
                    _.map(cartList.data, cartItem => {
                        _.map(cartItem.items, cartDetails => {
                            cartTotal += parseInt(_.size(cartDetails));
                        })
                    })
                    dispatch(updateCartTotal(cartTotal));
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        }

        // getUrl('luckydraw/available_event2').then(response => {
        //     if(response.status === 1){
        //         dispatch(availableEvent2(response.available));
        //     }
        // }).catch(error => {
        //     addAlert(JSON.stringify(error.message));
        // });

        if (!uuid && !accessToken) {
            const createdUuid = uuidv4();
            dispatch(storeUuid(createdUuid));
        }

    }, [uuid, accessToken, dispatch, addAlert]);

    useEffect(() => {
        getUrl('wallets').then(response => {
            if(response.status) {
                dispatch(storeWallets(response.data));
            }
        }).catch(error => {
            console.log("wallets error", error);
        })
        getUrl(`get_currentcy_rate`).then(result => {
            result['data']=JSON.parse(result['data']);
            dispatch(changeCurrencyRate(result));
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }, [accessToken])

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
        handleMenuClose();
    };

    const handleMenuClose = () => {
        setState({ ...state, languageAnchorEl: null, mobileAnchorEl: null, profileAnchorEl: null });
    };

    const languageId = 'primary-search-language-menu';
    const profileId = 'primary-search-account-menu';
    const mobileMenuId = 'primary-search-menu-mobile';

    const renderMenu = (
        <Menu
            anchorEl={state.languageAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={languageId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(state.languageAnchorEl)}
            onClose={handleMenuClose}

        >
            <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
            <MenuItem onClick={() => changeLanguage('cn')}>中文</MenuItem>
        </Menu>
    );

    const renderProfile = (
        <Menu
            anchorEl={state.profileAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={profileId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(state.profileAnchorEl)}
            onClose={handleMenuClose}
        >
            {
                accessToken ?
                    <Box>
                        <Link underline='none' color="inherit" to="/account/profile" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('title.account')}</MenuItem>
                        </Link>
                        <Link underline='none' color="inherit" to="/account/order" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('title.order')}</MenuItem>
                        </Link>
                        <Link underline='none' color="inherit" to="/account/vouchers" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('title.voucher')}</MenuItem>
                        </Link>
                        <Link underline='none' color="inherit" to="/voucher_cart" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('title.voucherCart')}</MenuItem>
                        </Link>
                        <Link underline='none' color="inherit" to="/contactus" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('footer.contactUs')}</MenuItem>
                        </Link>
                        <MenuItem
                            onClick={() => {
                                logoutUser();
                                handleMenuClose();
                                history.replace('/');
                            }}
                        >
                            {t('button.logout')}
                        </MenuItem>
                    </Box>
                    :
                    <Box>
                        <Link underline='none' color="inherit" to="/login" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('button.login')}</MenuItem>
                        </Link>
                        <Link underline='none' color="inherit" to="/voucher_cart" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('title.voucherCart')}</MenuItem>
                        </Link>
                        <Link underline='none' color="inherit" to="/contactUs" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('footer.contactUs')}</MenuItem>
                        </Link>
                    </Box>
            }
        </Menu>
    );

    const renderMobileMenu = (
        <Menu
            anchorEl={state.mobileAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(state.mobileAnchorEl)}
            onClose={handleMenuClose}
        >
            <Box >
                <Link underline='none' color="inherit" to={PROJECT_TYPE === "catalog" ? "/mystore" : "/cart"} component={RouterLink} onClick={() => handleMenuClose()}>
                    <MenuItem>
                        <IconButton color="inherit">
                            <Badge badgeContent={cartTotal} classes={{ badge: styles.badgeStyle }}>
                                {
                                    PROJECT_TYPE === "catalog" ?
                                        <StorefrontIcon style={{ color: '#000000' }} size={20} />
                                        :
                                        <ShoppingCart color="#000000" size={20} />
                                }
                            </Badge>
                        </IconButton>
                        <p>{PROJECT_TYPE === "catalog" ? t('title.myStore') : t('title.cart')}</p>
                    </MenuItem>
                </Link>
                <Link underline='none' color="inherit" to="/category/all" component={RouterLink} onClick={() => handleMenuClose()}>
                    <MenuItem>
                        <IconButton color="inherit">
                            <CategoryOutlinedIcon style={{ color: '#000000' }} size={20} />
                        </IconButton>
                        <p>{t('title.categories')}</p>
                    </MenuItem>
                </Link>
                <MenuItem onClick={(event) => setState({ ...state, languageAnchorEl: event.currentTarget })} >
                    <IconButton
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <Globe color="#000000" size={20} />
                    </IconButton>
                    <p>{t('home.translate')}</p>
                </MenuItem>
                {
                    accessToken ?
                        <>
                            <Link underline='none' color="inherit" to="/account/profile" onClick={() => handleMenuClose()} component={RouterLink}>
                                <MenuItem>
                                    <IconButton
                                        aria-controls="primary-search-account-menu"
                                        aria-haspopup="true"
                                        color="inherit"
                                    >
                                        <User color="#000000" size={20} />
                                    </IconButton>
                                    <p>{t('title.account')}</p>
                                </MenuItem>
                            </Link>
                            <MenuItem onClick={() => { logoutUser(); handleMenuClose(); history.replace('/'); }} >
                                <IconButton
                                    aria-controls="primary-search-account-menu"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <ExitToAppIcon />
                                </IconButton>
                                <p>{t('button.logout')}</p>
                            </MenuItem>
                        </>
                        :
                        <Link underline='none' color="inherit" to="/login" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>
                                <IconButton
                                    aria-controls="primary-search-account-menu"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <User color="#000000" size={20} />
                                </IconButton>
                                <p>{t('button.login')}</p>
                            </MenuItem>
                        </Link>
                    // <Link underline='none' color="inherit" to="/login" onClick={() => handleMenuClose()}>
                    //     <MenuItem>
                    //         { t('button.login') }
                    //     </MenuItem>
                    // </Link>
                }
            </Box>

        </Menu>
    );

    return (
        // _.includes(['comingsoon'], currentPath) ?
        true?
        null : 
        <div className={styles.grow} style={{display:'none'}}>
            <HideOnScroll {...props}>
                <AppBar color="primary" style={{ backgroundColor: theme.palette.primary.main, width:420, maxWidth:'100%', margin:'0 auto', right:'unset' }}>
                    <Container>
                        <Toolbar className={styles.toolbarTest} style={{ padding: '0' }}>
                            <Link underline='none' to="/" component={RouterLink} onClick={() => handleMenuClose()} className={styles.iconStyle}>
                                <WebpImg src="/images/logo.png" style={{ height: 38, paddingLeft: 10, paddingRight: 10 }} alt="" />
                                {/* <Typography className={styles.title} variant="h6">
                                DEMO
                            </Typography> */}
                            </Link>
                            {
                                _.includes(HIDE_COMPONENT_PAGE, currentPath) === false ?
                                    <>
                                        <Hidden xsDown>
                                            <Autocomplete
                                                style={{ width: '700px', maxWidth: '50%' }}
                                                open={open}
                                                onOpen={() => setOpen(true)}
                                                onClose={() => setOpen(false)}
                                                getOptionSelected={(option, value) => option.name === value.name}
                                                getOptionLabel={(option) => option.name}
                                                options={options}
                                                loading={loading}
                                                loadingText={`${t('general.loading')}...`}
                                                onInputChange={(event, newInputValue) => {
                                                    setKeyword(newInputValue)
                                                }}
                                                onChange={(event, value) => {
                                                    if (value && value.id) {
                                                        setOpen(false);
                                                        history.push(`/item/${value.id}`);
                                                    }
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder={t('general.search')}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                startAdornment: (
                                                                    <InputAdornment position="start"><Search style={{ color: theme.palette.gray.main }} /></InputAdornment>
                                                                ),
                                                                endAdornment: (
                                                                    <Fragment>
                                                                        {params.InputProps.endAdornment}
                                                                    </Fragment>
                                                                ),
                                                                style: {
                                                                    height: 40,
                                                                    padding: '0 14px',
                                                                    backgroundColor: '#FFFFFF'
                                                                }
                                                            }}
                                                            onKeyPress={(ev) => {
                                                                if (ev.key === 'Enter') {
                                                                    ev.preventDefault();
                                                                    setOpen(false);
                                                                    history.push(`/search/${keyword}`);
                                                                }
                                                            }}
                                                        />
                                                    )
                                                }}
                                                renderOption={(option, state) => (
                                                    // <Box display="flex" style={{ flex: 1 }}>
                                                    <Link underline='none' to={`/item/${option.id}`} onClick={() => handleMenuClose()} component={RouterLink} style={{ flex: 1 }}>
                                                        {option.name}
                                                    </Link>
                                                    // </Box>
                                                )}
                                            />
                                        </Hidden>
                                        <div className={styles.sectionDesktop}>
                                            <Button onClick={() => { handleMenuClose(); history.push(PROJECT_TYPE === "catalog" ? "/mystore" : "/cart") }}>
                                                {/* <Link underline='none' to="/cart" color="inherit" onClick={() => handleMenuClose()} component={RouterLink}> */}
                                                <Badge badgeContent={cartTotal} classes={{ badge: styles.badgeStyle }}>
                                                    {
                                                        PROJECT_TYPE === "catalog" ?
                                                            <StorefrontIcon style={{ color: '#FFFFFF' }} size={20} />
                                                            :
                                                            <ShoppingCart color="#FFFFFF" size={20} />
                                                    }
                                                </Badge>
                                                {/* </Link> */}
                                            </Button>
                                            <IconButton
                                                aria-label="language"
                                                aria-controls={languageId}
                                                aria-haspopup="true"
                                                onClick={(event) => setState({ ...state, languageAnchorEl: event.currentTarget })}
                                                color="inherit"
                                            >
                                                <Globe color="#FFFFFF" size={20} />
                                            </IconButton>
                                            <IconButton
                                                edge="end"
                                                aria-label="account of current user"
                                                aria-controls={profileId}
                                                aria-haspopup="true"
                                                onClick={(event) => setState({ ...state, profileAnchorEl: event.currentTarget })}
                                                color="inherit"
                                            >
                                                <User color="#FFFFFF" size={20} />
                                            </IconButton>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', }}>
                                            <div className={styles.sectionMobile}>
                                                <Link underline='none' style={{ lineHeight: '1.0' }} color="inherit" to="/search" component={RouterLink}>
                                                    <SearchIcon
                                                        style={{ color: '#fff' }} />
                                                </Link>
                                            </div>

                                            <div className={styles.sectionMobile}>
                                                <IconButton
                                                    aria-label="show more"
                                                    aria-controls={mobileMenuId}
                                                    aria-haspopup="true"
                                                    onClick={(event) => setState({ ...state, mobileAnchorEl: event.currentTarget })}
                                                    style={{ color: '#fff' }}
                                                >
                                                    <MoreIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                        {/* <div className={styles.sectionMobile}>
                                    <IconButton
                                        aria-label="show more"
                                        aria-controls={mobileMenuId}
                                        aria-haspopup="true"
                                        onClick={ (event) => setState({ ...state, mobileAnchorEl: event.currentTarget }) }
                                        color="inherit"
                                    >
                                        <MoreIcon />
                                    </IconButton>
                                </div> */}
                                    </>
                                        : null
                                    
                            }
                        </Toolbar>
                    </Container>
                </AppBar>
            </HideOnScroll>
            <Box style={{ paddingBottom: 60, backgroundColor: 'rgba(208, 208, 208, 0.23)', height:'100%' }}>

            </Box>
            {renderMobileMenu}
            {renderMenu}
            {renderProfile}

        </div>
    );
}

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        color: '#FFFFFF'
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.black, 0.08),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.15),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 400,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbarTest: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    iconStyle: {
        display: 'flex',
        alignItems: 'center',
        color: 'black'
    },
    badgeStyle: {
        backgroundColor: theme.palette.background.default,
        color: "#000000"
    }
}));
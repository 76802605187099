import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { postUrl, getUrl, logoutUser } from '../helper/ApiAction';
import { CardHeader, CardContent, TextField, InputAdornment, IconButton, Button, Typography, Container, Grid, Link, Box, Divider, Card } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { BACKEND_URL, CLIENT_SECRET, EXTERNAL_LOGIN, FACEBOOK_APPID, LOGIN_SECRET_KEY, ISHOPPING_LOGIN } from '../configs/Config';
import { authSuccess, authFail, storeCodeState ,changeLanguage as reduxChangeLanguage} from '../actions';
import { generateRandomString, useQuery } from '../helper/Tools';
import useNotificationLoading from '../helper/useNotificationLoading';

import { useLastLocation } from 'react-router-last-location';
import { Base64 } from 'js-base64';
import sha256 from 'crypto-js/sha256';

import FacebookIcon from '@material-ui/icons/Facebook';
import jwt_decode from "jwt-decode";
import _ from 'lodash';
import WebpImg from './Layouts/WebpImg';

export default function Login() {
    
    const dispatch = useDispatch();
    const { t , i18n} = useTranslation();

    const { accessToken, shareLinkProductId } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    const { name } = useSelector(state => state.user);

    let history = useHistory();

    const lastLocation = useLastLocation();

    const styles = useStyles();
    const [state, setState] = useState({
        showPassword: false,
        username: '',
        password: ''
    });
    const [userLogin, setUserLogin] = useState(false);
    const [continueDialogOpen, setContinueDialogOpen] = useState(false);

    const query = useQuery();
    const source = query.get('src');

    useEffect(() => {
        if(accessToken) {
            if(source){
                if(userLogin){
                    // user login, redirect to third party page
                    setLoading(true);
                    getUrl(`/login-to/${source}`).then(response => {
                        setLoading(false);
                        if(response.status === 1 && response.url){
                            window.location.href = response.url; 
                        }else{
                            addAlert(t('general.loginRedirectError'));
                        }
                    }).catch(error => {
                        setLoading(false);
                        addAlert(JSON.stringify(error.message));
                    });
                }else{
                    // user already login, ask continue as XXX
                    setContinueDialogOpen(true);
                }
            }else{
                let previousUrl = "";
                if(document.referrer) previousUrl = new URL(document.referrer);
                if(shareLinkProductId){
                    history.replace({ pathname: `item/${shareLinkProductId}` });
                }else if(!previousUrl || (previousUrl && window.location.host != previousUrl['host'])){
                    history.replace({ pathname: "/" });
                }else{
                    history.goBack();
                }
            }
        }
    }, [accessToken, history, shareLinkProductId, source]);

    const loginUser = () => {
        setLoading(true);
        setUserLogin(true);

        const loginState = {
            grant_type: 'password',
            client_id: '4',
            client_secret: CLIENT_SECRET,
            username: state.username,
            password: state.password,
            scope: '*',
        }
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.post(`${BACKEND_URL}/oauth/token`, loginState)
        .then((response) => {
            // setLoading(false);
            setLoading(false);
            if(response.status === 200) {
                // dispatch(authSuccess(response.data));
                // history.goBack();
                const decoded_jwt = jwt_decode(response.data.access_token);
                const userRole = decoded_jwt ? decoded_jwt.role : [];
                if(_.size(_.intersection(userRole, ['member']))){
                    dispatch(authSuccess(response.data));
                }else{
                    addAlert(t('general.incorrectUsernamePassword'));
                }
            }
        }).catch((error) => {
            console.log("error", error);
            setLoading(false);
            if (error.response && error.response.status === 400) {
                addAlert(t('general.incorrectUsernamePassword'));
                // console.log('The username or password is incorrect');
            }else if (error.response && error.response.status === 401) {
                let errorResponse = JSON.parse(error.request.response);
                addAlert(errorResponse.message);
            }else{
                addAlert(JSON.stringify(error.message));
                // console.log('Please try again later or contact Technical Support if problem persist.');
            }            
            dispatch(authFail());
            // setLoading(false);            
        }); 
    }

    const loginUserThirdParty = platform => {
        const generatedLoginState = generateRandomString(40);
        const generatedCodeVerifier = generateRandomString(128);
        dispatch(storeCodeState(generatedCodeVerifier, generatedLoginState));

        // let path64 = lastLocation?Base64.encode(lastLocation.pathname):'';
        // if(platform === 'bigo') {
        //     window.location.href = EXTERNAL_LOGIN+'&p='+path64; 
        // }

        if(platform === 'ishopping') {
            window.location.href = ISHOPPING_LOGIN; 
        }
    }

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://connect.facebook.net/en_US/sdk.js";
        script.async = true;
        document.body.appendChild(script);
        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : FACEBOOK_APPID,
                cookie     : true,  // enable cookies to allow the server to access the session
                xfbml      : true,  // parse social plugins on this page
                version    : 'v2.1' // use version 2.1
            });
            window.FB.getLoginStatus(function(response) {
                if(response.status === 'connected'){
                    let updateData = {
                        name: '',
                        email: '',
                        user_id: '',
                    }
                    updateData.user_id = response.authResponse.userID;
                    window.FB.api('/me?fields=id,name,email', function(responseMe) {
                        if(responseMe.id === updateData.user_id){
                            updateData.name = responseMe.name;
                            updateData.email = responseMe.email;
                        }
                        postUrl('facebook/login', updateData).then(response => {
                            if(response.status === 1){
                                const loginState = {
                                    grant_type: 'password',
                                    client_id: '4',
                                    client_secret: CLIENT_SECRET,
                                    username: response.data.data.username+'|facebook',
                                    password: sha256('facebook_'+LOGIN_SECRET_KEY+'_'+response.data.data.user_id).toString(),
                                    scope: '*',
                                }
                                axios.defaults.headers.common['Accept'] = 'application/json';
                                axios.post(`${BACKEND_URL}/oauth/token`, loginState)
                                .then((response2) => {
                                    setLoading(false);
                                    if(response2.status === 200) {
                                        const decoded_jwt = jwt_decode(response2.data.access_token);
                                        const userRole = decoded_jwt ? decoded_jwt.role : [];
                                        if(_.size(_.intersection(userRole, ['member']))){
                                            dispatch(authSuccess(response2.data));
                                            history.goBack();
                                        }else{
                                            addAlert(t('general.incorrectUsernamePassword'));
                                        }
                                    }
                                }).catch((error) => {
                                    setLoading(false);
                                    if (error.response && error.response.status === 400) {
                                        addAlert(t('general.incorrectUsernamePassword'));
                                    }else if (error.response && error.response.status === 401) {
                                        let errorResponse = JSON.parse(error.request.response);
                                        addAlert(errorResponse.message);
                                    }else{
                                        addAlert(JSON.stringify(error.message));
                                    }            
                                    dispatch(authFail());
                                });
                            }else{
                                addAlert(response.data);
                                dispatch(authFail());
                            }
                        }).catch(error => {
                            if (error.response && error.response.status === 401) {
                                let errorResponse = JSON.parse(error.request.response);
                                addAlert(errorResponse.message);
                            }else{
                                addAlert(JSON.stringify(error.message));
                            }            
                            dispatch(authFail());
                        });
                    });
                }
            }.bind(this));
        }.bind(this);
    }, []);

    const loginUserFb = () => {
        setLoading(true);
        let updateData = {
            name: '',
            email: '',
            user_id: '',
            // access_token: ''
        }
        window.FB.login(function(response){
            if(response.status === 'connected'){
                updateData.user_id = response.authResponse.userID;
                // updateData.access_token = response.authResponse.accessToken;
                window.FB.api('/me?fields=id,name,email', function(responseMe) {
                    if(responseMe.id === updateData.user_id){
                        updateData.name = responseMe.name;
                        updateData.email = responseMe.email;
                    }
                    postUrl('facebook/login', updateData).then(response => {
                        if(response.status === 1){
                            const loginState = {
                                grant_type: 'password',
                                client_id: '4',
                                client_secret: CLIENT_SECRET,
                                username: response.data.data.username+'|facebook',
                                password: sha256('facebook_'+LOGIN_SECRET_KEY+'_'+response.data.data.user_id).toString(),
                                scope: '*',
                            }
                            axios.defaults.headers.common['Accept'] = 'application/json';
                            axios.post(`${BACKEND_URL}/oauth/token`, loginState)
                            .then((response2) => {
                                setLoading(false);
                                if(response2.status === 200) {
                                    const decoded_jwt = jwt_decode(response2.data.access_token);
                                    const userRole = decoded_jwt ? decoded_jwt.role : [];
                                    if(_.size(_.intersection(userRole, ['member']))){
                                        dispatch(authSuccess(response2.data));
                                        history.goBack();
                                    }else{
                                        addAlert(t('general.incorrectUsernamePassword'));
                                    }
                                }
                            }).catch((error) => {
                                setLoading(false);
                                if (error.response && error.response.status === 400) {
                                    addAlert(t('general.incorrectUsernamePassword'));
                                }else if (error.response && error.response.status === 401) {
                                    let errorResponse = JSON.parse(error.request.response);
                                    addAlert(errorResponse.message);
                                }else{
                                    addAlert(JSON.stringify(error.message));
                                }            
                                dispatch(authFail());
                            });
                        }else{
                            addAlert(response.data);
                            dispatch(authFail());
                        }
                    }).catch(error => {
                        setLoading(false); 
                        if (error.response && error.response.status === 401) {
                            let errorResponse = JSON.parse(error.request.response);
                            addAlert(errorResponse.message);
                        }else{
                            addAlert(JSON.stringify(error.message));
                        }            
                        dispatch(authFail());
                    });
                });
            }else{
                setLoading(false); 
            }
        }, {scope: 'email'});
    }

    const dontContinueAs = () => {
        setContinueDialogOpen(false);
        logoutUser();
    }
    const continueAs = () => {
        setContinueDialogOpen(false);
        setLoading(true);
        getUrl(`/login-to/${source}`).then(response => {
            setLoading(false);
            if(response.status === 1 && response.url){
                window.location.href = response.url; 
            }else{
                addAlert(t('general.loginRedirectError'));
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }
    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };

    return (
        <Box style={{paddingTop:25}}>
            <Card style={{boxShadow:'none', border:'none', background:'none'}}>
                <center><WebpImg src="/images/logo-mistore.png" style={{ width:'20%', paddingLeft: 10, paddingRight: 10 }} alt="logo" /></center>
                <Typography style={{fontSize:21, textAlign:'center', paddingTop:10, fontWeight:'bold', textTransform:'uppercase', color:'#9d9d9d'}}>
                    {t('title.login')}
                </Typography>
                {/* <Divider /> */}
                <CardContent style={{ padding: 30 }}>
                    <TextField
                        label={t('register.username') + '/' + t('register.email')}
                        value={state.username}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><PersonIcon /></InputAdornment>,
                        }}
                        variant="outlined"
                        autoComplete="email"
                        color='primary'
                        fullWidth={true}
                        onChange={({ target }) => setState({ ...state, username: target.value })}
                    />
                    <TextField
                    style={{marginTop:20}}
                        label={t('register.password')}
                        type={state.showPassword ? 'text' : 'password'}
                        value={state.password}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <LockIcon />
                            </InputAdornment>,
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                    {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }}
                        variant="outlined"
                        color='primary'
                        fullWidth={true}
                        onChange={({ target }) => setState({ ...state, password: target.value })}
                    />
                    <Box className={styles.paddingVertical10} display="flex" justifyContent="space-between">
                        {/* <Link underline='none' to={`/register`} component={RouterLink}>
                            <Typography variant="subtitle2" color="primary" style={{ textAlign: 'center', fontSize:14 }}>
                                {t('button.register')}
                            </Typography>
                        </Link> */}
                        <Typography></Typography>
                        <Link underline='none' to={`/forgot-password`} component={RouterLink}>
                            <Typography variant="caption" style={{color:'#838383', fontSize:14}}>
                                {t('title.forgotPassword')}
                            </Typography>
                        </Link>
                    </Box>
                    <Box className={styles.paddingVertical10} display="flex" justifyContent="center">
                        <Button variant="contained" color="secondary" size="large" style={{width:210, borderRadius:48}}  fullWidth={true} onClick={loginUser}>
                            {t('button.login')}
                        </Button>
                    </Box>
                    <Box className={styles.paddingVertical10} display="flex" justifyContent="center">
                        <Link underline='none' onClick={() => changeLanguage('en')} component={RouterLink} style={{marginRight: 5 }}>
                            <Typography>{t('Eng')}</Typography> 
                        </Link>
                        <Typography>{t('|')}</Typography> 
                        <Link underline='none' onClick={() => changeLanguage('cn')} component={RouterLink} style={{marginLeft: 5 }}>
                            <Typography>{t('中文')}</Typography> 
                        </Link>
                    </Box>
                    {/* <Box style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'8px'}}>
                        <div style={{height:'2px', width:'50px', backgroundColor:'#d4d4d4'}}></div>
                        <Typography style={{padding:'0 15px', fontSize:'13px', color:'#969292'}}>{ t('button.loginWith') }</Typography>
                        <div style={{height:'2px', width:'50px', backgroundColor:'#d4d4d4'}}></div>
                    </Box>
                    <Box className={styles.paddingVertical10} display="flex" justifyContent="center">
                        <Button variant="contained" color="secondary" size="large" style={{width:210, borderRadius:48}}  fullWidth={true} onClick={() => loginUserThirdParty('ishopping')}>
                            {t('button.loginWithIshopping')}
                        </Button>
                    </Box> */}
                    {/* <Box className={styles.paddingVertical10} display="flex">
                        <Button variant="outlined" size="large" style={{ backgroundColor: '#4267B2' }} fullWidth={true} onClick={loginUserFb}>
                            <FacebookIcon style={{ color: 'white' }} /> <Typography style={{ color: 'white' }}>{t('button.loginWithFb')}</Typography>
                        </Button>
                    </Box> */}
                    
                </CardContent>
            </Card>
            <Dialog open={continueDialogOpen} >
                <DialogTitle id="scroll-dialog-title">{t('login.continueAsTitle', {'name': name})}</DialogTitle>
                <DialogContent dividers={false}>{t('login.continueAsContent', {'source': source})}</DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => continueAs()} color="primary">{t("login.continueYes")}</Button>
                    <Button variant="contained" onClick={() => dontContinueAs()} color="primary">{t("login.continueNo")}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0 10px 0',
        backgroundColor: 'white',
    },
    paddingVertical10: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    containerStyle: {
        padding: 20,
    }
}));
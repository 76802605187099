import React, { useState, useEffect, useRef , createRef} from 'react';
import { useTranslation } from 'react-i18next';

import { Link as RouterLink } from 'react-router-dom';
import { TextField, Button, Typography, Grid, Box, Divider, List, Hidden, ListItem, Chip, Dialog, DialogActions, DialogContentText, Snackbar, Card, CardContent, DialogContent, DialogTitle, FormControl, Select, FormHelperText,Avatar } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import _ from 'lodash';
import { getUrl, postUrl, putUrl, deleteUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

import { Plus } from 'react-feather';
import { ArtTrackOutlined,AspectRatioOutlined } from '@material-ui/icons';

import { createMuiTheme, ThemeProvider, MuiThemeProvider } from '@material-ui/core/styles';

import TitleBar from './Layouts/TitleBar';
import './css/styles.css';

import { CgTrash } from "react-icons/cg";


const INITIAL_ADDRESS_STATE = { name: '', mobile: '', email: '', identity_no: '', address: '', address2: '', city: '', state: '', zip: '', country: '', icFront: "", icBack: "" };

export default function ProfileAddress() {
    const styles = useStyles();
    const { t ,i18n } = useTranslation();
    const theme = useTheme();

    const [state, setState] = useState({
        modalShow: false,
        mode: null,
        deleteAddressId: null,
        editAddressId: null,
        snackbarShow: false,
        deleteAddressShow: false,
        error: false,
        message: '',
    });

    const [addressFormState, setAddressFormState] = useState(INITIAL_ADDRESS_STATE);
    const [errorAddressState, setErrorAddressState] = useState({
        name: "",
        mobile: "",
        email: "",
        identity_no: "",
        country: "",
        zip: "",
        state: "",
        city: "",
        address2: "",
        address: "",
        icFront: "",
        icBack: "",
    })
    const [shouldUploadIC, setShouldUploadIC] = useState(false);
    const [addresses, setAddress] = useState([]);
    const [countries, setCountry] = useState([]);
    const [addressState, setAddressState] = useState([]);
    const [cities, setCity] = useState([]);
    const [district, setDistrict] = useState([]);
    const [addressGroup, setAddressGroup] = useState([]);
    const { addAlert, setLoading } = useNotificationLoading();

    const isMountedRef = useRef(null);
    const icFrontRef = useRef();
    const icBackRef = useRef();

    useEffect(() => {
        isMountedRef.current = true;
            getUrl('address_group_list').then(addressList => {
                if(isMountedRef.current && addressList.status === 1) {
                    setAddressGroup({
                        stateGroup: addressList.state_group,
                        cityGroup: addressList.city_group,
                        districtGroup: addressList.district_group,
                    });
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [addAlert, t]);

    const getUserAddress = () => {
        getUrl('user/address').then(addressList => {
            if(isMountedRef.current) {
                setAddress(addressList.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    useEffect(() => {
        isMountedRef.current = true;

        getUserAddress();

        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [addAlert, t]);

    useEffect(() => {
        setCity([]);
        setDistrict([]);
        isMountedRef.current = true;
        getUrl('countries').then(countryList => {
            // console.log(countryList)
            if(isMountedRef.current) {
                setCountry(countryList.data);
                // const filteredCountryList = _.filter(countryList.data, countryItem => countryItem.code === 'CN' || countryItem.code === 'MY');
                // setCountry(filteredCountryList);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addAlert, t, addressFormState.country]);

    useEffect(() => {
        if(addressFormState.country === 'CN') {
            setShouldUploadIC(true);
        } else {
            setShouldUploadIC(false);
        }
    }, [addressFormState.country])

    useEffect(() => {
        isMountedRef.current = true;
        if(addressFormState.country) {
            if(_.includes(addressGroup.stateGroup, addressFormState.country)) {
                getUrl(`states/${addressFormState.country}`).then(stateList => {
                    if(isMountedRef.current) {
                        setAddressState(stateList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
    
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if(addressFormState.state) {
            if(_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state)) {
                getUrl(`cities/${addressFormState.state}`).then(cityList => {
                    if(isMountedRef.current) {
                        setCity(cityList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addressFormState.state, addAlert, t]);

    useEffect(() => {
        isMountedRef.current = true;
        if(addressFormState.city) {
            if(_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state) && _.includes(addressGroup.districtGroup, addressFormState.city)) {
                getUrl(`districts/${addressFormState.city}`).then(districtList => {
                    if(isMountedRef.current) {
                        setDistrict(districtList.data);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [i18n.language, addressFormState.country, addressFormState.city, addAlert, t]);

    const handleAddressChange = async (event) => {
        const name = event.target.name;
        let value = event.target.value;
        if(name === 'icFront') {
            if(icFrontRef.current.files[0]) {
                const a = await toBase64(icFrontRef.current.files[0]);
                value = a;
            } else {
                value = '';
            }
        } else if(name === 'icBack') {
            if(icBackRef.current.files[0]) {
                const a = await toBase64(icBackRef.current.files[0]);
                value = a;
            } else {
                value = '';
            }
        }
        setAddressFormState({ ...addressFormState, [name]: value });
        if(name === 'country') {
            setAddressFormState(addressFormState => ({ ...addressFormState, state: '', city: '', address2: '' }));
        }
    };


    const deleteUserAddress = () => {
        if(state.deleteAddressId) {
            deleteUrl(`user/address/${state.deleteAddressId}`).then(result => {
                getUserAddress();
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
            setState({ ...state, deleteAddressId: null, deleteAddressShow: false });
        }
    }

    const openAddAddress = () => {
        setErrorAddressState({});
        setState({ ...state, mode: 'add', modalShow: true });
    };

    const closeModal = () => {
        setState({ ...state, modalShow: false, mode: null, editAddressId: null, });
        setAddressFormState(INITIAL_ADDRESS_STATE);
    };

    const openEditAddress = addressId => {
        setErrorAddressState({});
        const editedAddress = _.find(addresses, { 'id': addressId });
        if(editedAddress) {
            const { name, mobile, email, identity_no, address, address2, city, state, zip, country, icFront, icBack } = editedAddress;
            let newAddressFormState = { 
                name: name || "", 
                mobile: mobile || "", 
                email: email || "", 
                identity_no: identity_no || "", 
                address, 
                address2, 
                city, 
                state, 
                zip, 
                country, 
                icFront, 
                icBack 
            };
            if(_.size(icFront) > 0) {
                newAddressFormState['icFront'] = icFront.file_name;
            }
            if(_.size(icBack) > 0) {
                newAddressFormState['icBack'] = icBack.file_name;
            }

            setAddressFormState(newAddressFormState);
            setState(state => ({ ...state, mode: 'edit', modalShow: true, editAddressId: addressId }));
        }
    }
    
    const setDefaultAddress = addressId => {
        const editedAddress = _.find(addresses, { 'id': addressId });

        const { name, mobile, address, address2, city, state, zip, country, email,identity_no } = editedAddress;
        const defaultAddress = {
            name, mobile, address, address2, city, state, zip, country, default: 1, email,identity_no
        }
        putUrl(`user/address/${addressId}`, defaultAddress).then(result => {
            if(result.error) {
                let errMsg = "";
                _.map(result.error, (errorItem) => {
                    errMsg += errorItem + " ";
                })
                addAlert(errMsg);
            }else{
                addAlert(result.message, 'success');
                getUserAddress();
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);

    });

    const submitAddress = () => {
        // const { country, city, address2, zip, address } = addressFormState;
        // if(!country || !addressFormState.state || !city) {
        //     if(cities) {
        //         if(!address2 || !zip || !address) {
        //             setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') })
        //         }
        //     }
        //     setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') })
        // } else {
            if(state.mode === 'add') {
                postUrl('user/address', addressFormState).then(result => {
                    if(result.error) {
                        let errorFieldState = {};
                        _.map(result.error, (errorItem, errorIndex) => {
                            errorFieldState[errorIndex] = errorItem;
                        })
                        setErrorAddressState(errorAddressState => (errorFieldState));
                        setState( state => ({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') }) );
                    } else {
                        setAddressFormState(INITIAL_ADDRESS_STATE);
                        setState( state => ({ ...state, modalShow: false, snackbarShow: true, error: false, message: result.message }) );
                         getUserAddress();
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            } else {
                putUrl(`user/address/${state.editAddressId}`, addressFormState).then(result => {
                    if(result.error) {
                        let errorFieldState = {};
                        _.map(result.error, (errorItem, errorIndex) => {
                            errorFieldState[errorIndex] = errorItem;
                        })
                        setErrorAddressState(errorAddressState => (errorFieldState));
                        setState( state => ({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequiredAddress') }) );
                    } else {
                        setAddressFormState(INITIAL_ADDRESS_STATE);
                        setState( state => ({ ...state, modalShow: false, mode: null, editAddressId: null, snackbarShow: true, error: false, message: result.message }) );
                        getUserAddress();
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        // }
    }


    return (
        <Box className={styles.root} style={{ padding: '0 0 80px', }}>

            <TitleBar height={70} title={t('profile.address')} displayInfo displayQr currencyButton displayCart linkMall back pagetitle />
            <div className='fixed-header-padding'>
                <Grid container spacing={1} justify="center" style={{ padding: '20px 15px 0' }}>
                    <Grid item xs={12}>
                        <List className={styles.listStyle} style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
                            <ListItem style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center' }}>
                                {/* <Box style={{ color: theme.palette.primary.main }}> */}
                                <div>
                                    <Typography variant="h6" style={{ color: theme.palette.primary.main }}>
                                        {t('profile.addresses')}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        {t('profile.addressSubtitle')}
                                    </Typography>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button variant="contained" onClick={openAddAddress} style={{ display: 'flex', alignItems: 'center', width: '32px', height: '32px', minWidth: '32px' }} color="secondary">
                                        <Box style={{ lineHeight: '0' }}>
                                            <Plus style={{ color: '#fff', lineHeight: '0' }} size={27} />
                                        </Box>
                                    </Button>
                                    {/* <Hidden only={['xs']}>
                                        <Typography style={{ whiteSpace: 'nowrap', fontSize: '14px', marginLeft: '8px', color: '#f72b60' }}>{t('address.addNew')}</Typography>
                                    </Hidden> */}
                                </div>
                                {/* </Box> */}
                            </ListItem>
                            {addresses && _.map(addresses, addressItem => (
                                <div style={{ boxShadow: '0 0 6px 0 #0002', padding: 20, borderRadius: 15, marginBottom: 15 }} key={addressItem.id}>
                                    <div>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                                            <Box display="flex" alignItems="center" style={{ width: '85%' }}>
                                                <Typography color="textPrimary" style={{ fontSize: 19, whiteSpace: 'nowrap', maxWidth: '62%', textOverflow: 'ellipsis', overflow: 'hidden', }}><b>{addressItem.name || '-'}</b></Typography>
                                                <Typography style={{ paddingLeft: 15 }}>{addressItem.mobile || '-'}</Typography>
                                            </Box>
                                            <Button size="small" style={{ color: theme.palette.primary.main }} onClick={() => openEditAddress(addressItem.id)} >{t('button.edit')}</Button>
                                        </Box>
                                        <Typography style={{ color: theme.palette.gray.text }}>{addressItem.email || '-'}</Typography>
                                        <Typography style={{ color: theme.palette.gray.text }}>{`${addressItem.address},`} {`${addressItem.address2_display ? addressItem.address2_display + ', ' : ''} ${addressItem.city_display}, ${addressItem.state_display},`} {`${addressItem.zip}, ${addressItem.country_display}`}</Typography>
                                    </div>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" style={{ paddingTop: 15 }}>
                                        
                                        {
                                            addressItem.default ?
                                                <Chip
                                                    label={t('button.default')}
                                                    color="secondary"
                                                    style={{ borderRadius:48, padding: '4px 9px', height: 'unset', width: 108, boxShadow: 'inset 3px 4px 3px 0 #00000029', height:'unset', fontSize:18, color: '#686868',backgroundColor: '#dcdcdc' }}
                                                    classes={{ label: styles.chipLabel }}
                                                /> :
                                                <Button
                                                    disabled={addressItem.default}
                                                    variant="outlined"
                                                    size="small"
                                                    color='primary'
                                                    className='red-button-style'
                                                    onClick={() => { setDefaultAddress(addressItem.id) }}
                                                >{t('button.setDefault')}</Button>
                                        }
                                        <Button onClick={() => setState({ ...state, deleteAddressId: addressItem.id, deleteAddressShow: true })} ><CgTrash style={{ fontSize: 24, color: theme.palette.gray.dtext }} /></Button>

                                    </Box>

                                    {/* <Grid container spacing={2} justify="center">
                                <Grid item xs={12} sm={8}>
                                    <Grid item xs={12} className={styles.paddingVerticalZero}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={3} sm={3}>
                                                <Typography variant="caption" color="textSecondary">{t('profile.name')}</Typography>
                                            </Grid>
                                            <Grid item xs={9} sm={9}>
                                                <Typography variant="overline" color="textPrimary">{addressItem.name || '-'}</Typography>
                                                {
                                                    addressItem.default ?
                                                        <Chip
                                                            label="Default"
                                                            color="secondary"
                                                            size="small"
                                                            style={{ marginLeft: 10 }}
                                                            classes={{ label: styles.chipLabel }}
                                                        /> : null
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} classes={{ root: styles.paddingVerticalZero }}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={3} sm={3}>
                                                <Typography variant="caption" color="textSecondary">{t('profile.mobile')}</Typography>
                                            </Grid>
                                            <Grid item xs={9} sm={9}>
                                                <Typography variant="overline" color="textPrimary">{addressItem.mobile || '-'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} classes={{ root: styles.paddingVerticalZero }}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={3} sm={3}>
                                                <Typography variant="caption" color="textSecondary">{t('profile.email')}</Typography>
                                            </Grid>
                                            <Grid item xs={9} sm={9}>
                                                <Typography variant="overline" color="textPrimary">{addressItem.email || '-'}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={styles.paddingVerticalZero}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={3} sm={3}>
                                                <Typography variant="caption" color="textSecondary">{t('profile.addresses')}</Typography>
                                            </Grid>
                                            <Grid item xs={9} sm={9}>
                                                <Box display="flex" flexDirection="column">
                                                    <Typography variant="caption" color="textPrimary">{`${addressItem.address},`}</Typography>
                                                    <Typography variant="caption" color="textPrimary">{`${addressItem.address2_display ? addressItem.address2_display + ', ' : ''} ${addressItem.city_display}, ${addressItem.state_display},`}</Typography>
                                                    <Typography variant="caption" color="textPrimary">{`${addressItem.zip}, ${addressItem.country_display}`}</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box display="flex" justifyContent="space-between" paddingY={2}>
                                        <Button size="small" variant="outlined" onClick={() => openEditAddress(addressItem.id)} >{t('button.edit')}</Button>
                                        <Button size="small" variant="outlined" color="secondary" onClick={() => setState({ ...state, deleteAddressId: addressItem.id, deleteAddressShow: true })} >{t('button.delete')}</Button>
                                        <Hidden smUp>
                                            <Button
                                                disabled={addressItem.default}
                                                variant="outlined"
                                                size="small"
                                                onClick={() => { setDefaultAddress(addressItem.id) }}
                                            >{t('button.setDefault')}</Button>
                                        </Hidden>

                                    </Box>
                                    <Hidden only={'xs'}>
                                        <Box display="flex" justifyContent="flex-end" paddingY={2}>
                                            <Button
                                                disabled={addressItem.default}
                                                variant="outlined"
                                                size="small"
                                                onClick={() => { setDefaultAddress(addressItem.id) }}
                                            >{t('button.setDefault')}</Button>
                                        </Box>
                                    </Hidden>
                                </Grid>
                            </Grid> */}
                                </div>
                            ))}
                        </List>
                        <Snackbar
                            open={state.snackbarShow}
                            autoHideDuration={2000}
                            onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        >
                            <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                                <CardContent style={{ padding: 10 }}>
                                    <Typography style={{ color: 'white' }}>{state.message}</Typography>
                                </CardContent>
                            </Card>
                        </Snackbar>
                        <MuiThemeProvider theme={dialogstyle}>
                            <Dialog
                                open={state.deleteAddressShow}
                                fullWidth
                                onClose={() => setState({ ...state, deleteAddressShow: false })}
                            >
                                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                                    {t('address.removeTitle')}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        {t('address.removeContent')}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus variant="outlined" onClick={() => setState({ ...state, deleteAddressShow: false, deleteAddressId: null })} color="default">
                                        <Typography variant="overline">{t('button.cancel')}</Typography>
                                    </Button>
                                    <Button onClick={deleteUserAddress} variant="contained" color="secondary">
                                        <Typography variant="overline">{t('button.confirm')}</Typography>
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={state.modalShow} maxWidth='xs' onClose={closeModal}>
                                <DialogTitle id="form-dialog-title">
                                    {
                                        state.mode === 'add' ?
                                            t('address.addNewAddress')
                                            : t('address.editAddress')
                                    }
                                </DialogTitle>
                                <DialogContent dividers>
                                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                        <Typography>{t('address.recipient')}</Typography>
                                        <TextField
                                            onChange={handleAddressChange}
                                            variant="outlined"
                                            type="text"
                                            inputProps={{ name: 'name' }}
                                            value={addressFormState.name}
                                            error={errorAddressState.name ? true : false}
                                            helperText={errorAddressState.name}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                        <Typography>{t('address.mobile')}</Typography>
                                        <TextField
                                            onChange={handleAddressChange}
                                            variant="outlined"
                                            type="text"
                                            inputProps={{ name: 'mobile' }}
                                            value={addressFormState.mobile}
                                            error={errorAddressState.mobile ? true : false}
                                            helperText={errorAddressState.mobile}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                        <Typography>{t('address.email')}</Typography>
                                        <TextField
                                            onChange={handleAddressChange}
                                            variant="outlined"
                                            type="text"
                                            inputProps={{ name: 'email' }}
                                            value={addressFormState.email}
                                            error={errorAddressState.email ? true : false}
                                            helperText={errorAddressState.email}
                                        />
                                    </FormControl>
                                    {shouldUploadIC &&
                                        <>
                                            <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                                <Typography>{t('address.identity_no')}</Typography>
                                                <TextField
                                                    onChange={handleAddressChange}
                                                    variant="outlined"
                                                    type="text"
                                                    inputProps={{ name: 'identity_no' }}
                                                    value={addressFormState.identity_no}
                                                    error={errorAddressState.identity_no ? true : false}
                                                    helperText={errorAddressState.identity_no}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                                <Typography variant="body2">{t('checkout.chinaRecipientNeedIC')}</Typography>
                                                <Box display="flex" flexDirection="row">
                                                    <input hidden accept="image/*" type="file" ref={icFrontRef} name="icFront" onChange={handleAddressChange} />
                                                    <input hidden accept="image/*" type="file" ref={icBackRef} name="icBack" onChange={handleAddressChange} />
                                                    <Button
                                                        className={styles.uploadButtonContainer}
                                                        onClick={() => icFrontRef.current.click()}
                                                        variant="outlined"
                                                    // startIcon={ addressFormState.icFront ? <Avatar src={ addressFormState.icFront } /> : <ArtTrackOutlined /> }
                                                    >
                                                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                                            {addressFormState.icFront ?
                                                                <img src={addressFormState.icFront} alt="ic front" />
                                                                : <ArtTrackOutlined fontSize="large" />}
                                                            <Typography variant="body2">{t('checkout.icFront')}</Typography>
                                                        </Box>
                                                    </Button>
                                                    <Button
                                                        className={styles.uploadButtonContainer}
                                                        onClick={() => icBackRef.current.click()}
                                                        variant="outlined"
                                                    // startIcon={ addressFormState.icBack ? <Avatar src={ addressFormState.icBack } /> : <AspectRatioOutlined /> }
                                                    >
                                                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                                            {addressFormState.icBack ?
                                                                <img src={addressFormState.icBack} alt="ic back" />
                                                                : <AspectRatioOutlined fontSize="large" />}
                                                            <Typography variant="body2">{t('checkout.icBack')}</Typography>
                                                        </Box>
                                                    </Button>
                                                </Box>
                                                <Typography variant="caption" color="error">{errorAddressState.icFront}</Typography>
                                                <Typography variant="caption" color="error">{errorAddressState.icBack}</Typography>
                                            </FormControl>
                                        </>
                                    }
                                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                        <Typography>{t('address.country')}</Typography>
                                        <Select
                                            native
                                            value={addressFormState.country}
                                            onChange={handleAddressChange}
                                            inputProps={{ name: 'country' }}
                                            error={errorAddressState.country ? true : false}
                                        >
                                            <option value="">{t('address.pleaseSelect')}{t('address.country')}</option>
                                            {
                                                _.map(countries, countryItem => {
                                                    return (
                                                        <option key={countryItem.code} value={countryItem.code}>{countryItem.name_display}</option>
                                                    )
                                                })
                                            }
                                        </Select>
                                        <FormHelperText>{errorAddressState.country}</FormHelperText>
                                    </FormControl>
                                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                        <Typography>{t('address.zip')}</Typography>
                                        <TextField
                                            inputProps={{ name: 'zip' }}
                                            onChange={handleAddressChange}
                                            variant="outlined"
                                            type="text"
                                            value={addressFormState.zip}
                                            error={errorAddressState.zip ? true : false}
                                            helperText={errorAddressState.zip}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                        <Typography>{t('address.state')}</Typography>
                                        {
                                            _.includes(addressGroup.stateGroup, addressFormState.country) ?
                                                <>
                                                    <Select
                                                        native
                                                        value={addressFormState.state}
                                                        onChange={handleAddressChange}
                                                        inputProps={{ name: 'state' }}
                                                        error={errorAddressState.state ? true : false}
                                                    >
                                                        <option value="">{t('address.pleaseSelect')}{t('address.state')}</option>
                                                        {
                                                            _.map(addressState, stateItem => {
                                                                return (
                                                                    <option key={stateItem.id} value={stateItem.id}>{stateItem.name_display}</option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                    <FormHelperText>{errorAddressState.state}</FormHelperText>
                                                </>
                                                :
                                                <TextField
                                                    onChange={handleAddressChange}
                                                    variant="outlined"
                                                    type="text"
                                                    inputProps={{ name: 'state' }}
                                                    value={addressFormState.state}
                                                    error={errorAddressState.state ? true : false}
                                                    helperText={errorAddressState.state}
                                                />
                                        }
                                    </FormControl>
                                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                        <Typography>{t('address.city')}</Typography>
                                        {
                                            _.size(cities) > 0 ?
                                                <>
                                                    <Select
                                                        native
                                                        value={addressFormState.city}
                                                        onChange={handleAddressChange}
                                                        inputProps={{ name: 'city' }}
                                                        error={errorAddressState.city ? true : false}
                                                    >
                                                        <option value="">{t('address.pleaseSelect')}{t('address.city')}</option>
                                                        {
                                                            _.map(cities, cityItem => {
                                                                return (
                                                                    <option key={cityItem.id} value={cityItem.id}>{cityItem.name_display}</option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                    <FormHelperText>{errorAddressState.city}</FormHelperText>
                                                </>
                                                :
                                                <TextField
                                                    onChange={handleAddressChange}
                                                    variant="outlined"
                                                    type="text"
                                                    inputProps={{ name: 'city' }}
                                                    value={addressFormState.city}
                                                    error={errorAddressState.city ? true : false}
                                                    helperText={errorAddressState.city}
                                                />
                                        }
                                    </FormControl>
                                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                        <Typography>{t('address.address2')}</Typography>
                                        {
                                            _.size(district) > 0 ?
                                                <>
                                                    <Select
                                                        native
                                                        value={addressFormState.address2}
                                                        onChange={handleAddressChange}
                                                        inputProps={{ name: 'address2' }}
                                                        error={errorAddressState.address2 ? true : false}
                                                    >
                                                        <option value="">{t('address.pleaseSelect')}{t('address.address2')}</option>
                                                        {
                                                            _.map(district, districtItem => {
                                                                return (
                                                                    <option key={districtItem.id} value={districtItem.id}>{districtItem.name_display}</option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                    <FormHelperText>{errorAddressState.address2}</FormHelperText>
                                                </>
                                                :
                                                <TextField
                                                    onChange={handleAddressChange}
                                                    variant="outlined"
                                                    type="text"
                                                    inputProps={{ name: 'address2' }}
                                                    value={addressFormState.address2}
                                                    error={errorAddressState.address2 ? true : false}
                                                    helperText={errorAddressState.address2}
                                                />
                                        }
                                    </FormControl>

                                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                        <Typography>{t('address.address')}</Typography>
                                        <TextField
                                            onChange={handleAddressChange}
                                            variant="outlined"
                                            type="text"
                                            inputProps={{ name: 'address' }}
                                            value={addressFormState.address}
                                            error={errorAddressState.address ? true : false}
                                            helperText={errorAddressState.address}
                                            multiline
                                            rows={3}
                                        />
                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                    <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button variant="contained" onClick={submitAddress} color="secondary">
                                                {state.mode === 'add' ? t('button.add') : t('button.update')}
                                            </Button>
                                        </Box>
                                    </FormControl>
                                </DialogActions>
                            </Dialog>
                        </MuiThemeProvider>
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15, 
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    paddingVerticalZero: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    chipLabel: {
        fontSize: 15
    },
    formControl: {
        padding: 5
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    inputFile: {
        display: 'none',
    },
    uploadButtonContainer: {
        flex: 1,
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    }
}));

const dialogstyle = createMuiTheme({
    overrides: {
        MuiDialog: {
            paperWidthXs: {
                maxWidth:400
                
            }
        }
    }
});
import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

import { makeStyles, withStyles, Radio, RadioGroup, FormControlLabel, CircularProgress, DialogTitle, DialogContent, DialogActions, TextField, Dialog, FormControl, Select, Card, CardContent, IconButton, Hidden, CardActionArea, Stepper, Step, StepLabel, Chip, Button, Typography, Grid, Breadcrumbs, Link, Box, List, ListItem, useTheme, CardHeader, FormHelperText,MenuItem, MuiDialogTitle, MuiDialogContent, MuiDialogActions, } from '@material-ui/core';
import { Edit, Plus, Edit3 } from 'react-feather';
import { CloseOutlined, StorefrontOutlined, ArtTrackOutlined, AspectRatioOutlined,CheckCircle } from '@material-ui/icons';
import { FiChevronLeft } from 'react-icons/fi';
import WebpImg from './Layouts/WebpImg';

import { updateCartTotal } from '../actions';
import { getUrl, postUrl, putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

import TitleBar from './Layouts/TitleBar';
import './css/styles.css';

import CloseIcon from '@material-ui/icons/Close';

const INITIAL_ADDRESS_STATE = { name: '', mobile: '', email: '', identity_no: '', address: '', address2: '', city: '', state: '', zip: '', country: '', icFront: "", icBack: "" };


////// dialog - change address /////////////


const styles = (theme) => ({
root: {
margin: 0,
padding: theme.spacing(2),
},
closeButton: {
position: 'absolute',
right: theme.spacing(1),
top: theme.spacing(1),
color: theme.palette.grey[500],
},
});


///////////// dialog end - change address ///////////

export default function Checkout() {
const { name, email, mobile, username } = useSelector(state => state.user);
const [buyerInfo, setBuyerInfo] = useState({
    name: name,
    email: email?email:'',
    mobile: mobile?mobile:'',
});
const [recipientInfo, setRecipientInfo] = useState({
    name: name,
    email: email?email:'',
    mobile: mobile?mobile:'',
});
const [userDialog, setUserDialog] = useState({
    type: '',
    open: false,
    name: '',
    email: '',
    mobile: '',
});
const [state, setState] = useState({
    open: false,
    error: false,
    message: '',
    modalShow: false,
    paymentId: null,
    addressId: null,
    totalPrice: 0,
    totalSelected: null,
    totalWalletPrice: {},
    totalShippingPrice: 0,
    walletBalance: {},
    selectAddressShow: false,
    orderSuccess: false,
    mode: null,
    editAddressId: null,
});
const [addressFormState, setAddressFormState] = useState(INITIAL_ADDRESS_STATE);
const [errorAddressState, setErrorAddressState] = useState({
    name: "",
    mobile: "",
    email: "",
    identity_no: "",
    country: "",
    zip: "",
    state: "",
    city: "",
    address2: "",
    address: "",
    icFront: "",
    icBack: "",
})
const [errorField, setErrorField] = useState({
    cart: []
});
const [cartErrors, setCartError] = useState([]);
const [cart, setCart] = useState([]);
const [updateCart, setUpdateCart] = useState(false);
const [currencyData, setCurrencyData] = useState(null);
const [addresses, setAddress] = useState(null);
const [countries, setCountry] = useState([]);
const [addressState, setAddressState] = useState([]);
const [cities, setCity] = useState([]);
const [district, setDistrict] = useState([]);
const [remark, setRemark] = useState({});
const [mallShippingRefresh, setMallShippingRefresh] = useState(false);
const [mallShipping, setMallShipping] = useState([]);
const [mallTotalShipping, setMallTotalShipping] = useState(0);
const [disabledSubmit, setDisabledSubmit] = useState(false);
const [fetchingMallShipping, setFetchingMallShipping] = useState(false);
const [shippingFeeOpen, setShippingFeeOpen] = useState([]);
const [addressGroup, setAddressGroup] = useState([]);
const [wallets, setWallets] = useState([]);
const [multiWalletBalance, setMultiWalletBalance] = useState([]);
const [downlineInfo, setDownlineInfo] = useState({
    username: username?username:'',
    source: 'local',
});
const [shippingWallet, setShippingWallet] = useState({
    id: 0,
    prefix: '',
    decimal: 2,
});
const [totalPayment, setTotalPayment] = useState({
    cash: 0,
    wallet_list: {}
});
const [shouldUploadIC, setShouldUploadIC] = useState(false);
const [redeemWallet, setRedeemWallet] = useState([]);
const [redeemCash, setRedeemCash] = useState(0);
const [isMalaysiaShipping, setIsMalaysiaShipping] = useState(false);
const [malaysiaShippingArea, setMalaysiaShippingArea] = useState("");
const [selfPickUpInfo, setSelfPickupInfo]=useState({});
const [mallFee, setMallFee]=useState({});
const [pickup, SetPickUp]=useState(0);
const [walletPoint, setWalletPoint] = useState([]);

const { t, i18n } = useTranslation();
const styles = useStyles();
const theme = useTheme();
let history = useHistory();
const dispatch = useDispatch();
const { addAlert, setLoading } = useNotificationLoading();

const isMountedRef = useRef(null);
const icFrontRef = useRef();
const icBackRef = useRef();

const {currency,currencyRate,currencydisplay } = useSelector(state => state.general);
let currency_code = [];
_.map(currencydisplay,(currency_detail,index)=>{
    if(currency_detail.code == currency){
        currency_code = currency_detail.desc.split('|');
    }
    
});

useEffect(() => {
    if(_.size(addresses) > 0 && state.addressId > 0) {
        const selectedAddress = _.find(addresses, { id: state.addressId });
        if(_.size(selectedAddress) && selectedAddress.country === 'MY') {
            setIsMalaysiaShipping(true);
            getUrl(`countries/${selectedAddress.country}`).then(response => {
                // console.log('countries', response);
                if(response.status) {
                    _.map(response.data, (regionState, region) => {
                        const existState = _.find(regionState, { id: parseInt(selectedAddress.state) });
                        if(_.size(existState)) {
                            setMalaysiaShippingArea(region);
                        }
                    })
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        } else {
            setIsMalaysiaShipping(false);
        }
    }
}, [state.addressId, addresses, cart]);

useEffect(() => {
    calculateTotal(cart);
}, [malaysiaShippingArea, cart, selfPickUpInfo]);

useEffect(() => {
    isMountedRef.current = true;
    if(isMountedRef.current) {
        getUrl('carts')
        .then(cartList => {
            if(isMountedRef.current) {
                // console.log("cartList", cartList);
                setCurrencyData(cartList.currency_data);
                setCart(cartList.data);
                calculateTotal(cartList.data);
                setupWalletPoint(cartList.data);
                // setIsSelfPickUp(cartList.data.selfPickUp ==1? true:false);
                _.map(cartList.data,(merchantItem, merchantIndex)=>{
                        if(_.size(selfPickUpInfo)<=0){
                            setSelfPickupInfo(prevState=>({
                                ...prevState,
                                [merchantIndex]:{
                                    id:merchantIndex,
                                    channel:'delivery',
                                    self_pickup:false,
                                    outlet_id:0,
                                }
                            }))
                        }
                    // }
                
                })
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }
    return () => { isMountedRef.current = false };
    // eslint-disable-next-line
}, [addAlert, updateCart, wallets,mallFee]);

useEffect(() => {
    isMountedRef.current = true;
    if(isMountedRef.current) {
        getUrl('wallets/balance').then(response => {
            if(isMountedRef.current && response.status === 1) {
                setWallets(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }
    return () => { isMountedRef.current = false };
    // eslint-disable-next-line
}, [addAlert]);

useEffect(() => {
    isMountedRef.current = true;
    if(isMountedRef.current) {
        getUrl('user/address')
        .then(addressList => {
            if(isMountedRef.current) {
                setAddress(addressList.data);
                // console.log("addressList.data", addressList.data);
                let defaultAddress = _.find(addressList.data, { 'default': true });
                if(_.size(defaultAddress) > 0) {
                    // setState(state => ({ ...state, addressId: defaultAddress.id, changedAddressId: defaultAddress.id }))
                    setState(state => ({ ...state, addressId: defaultAddress.id }))
                } else {
                    if(_.size(addressList.data)) {
                        const firstAddress = addressList.data[0];
                        // setState(state => ({ ...state, addressId: firstAddress.id, changedAddressId: firstAddress.id }))
                        setState(state => ({ ...state, addressId: firstAddress.id }))
                    }
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }
    return () => { isMountedRef.current = false };
    // eslint-disable-next-line
}, [addAlert]);

useEffect(() => {
    isMountedRef.current = true;
        getUrl('address_group_list').then(addressList => {
            if(isMountedRef.current && addressList.status === 1) {
                setAddressGroup({
                    stateGroup: addressList.state_group,
                    cityGroup: addressList.city_group,
                    districtGroup: addressList.district_group,
                });
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    return () => isMountedRef.current = false;
}, [addAlert, t]);

useEffect(() => {
    if(addressFormState.country === 'CN') {
        setShouldUploadIC(true);
    } else {
        setShouldUploadIC(false);
    }
}, [addressFormState.country])

useEffect(() => {
    setCity([]);
    setDistrict([]);
    isMountedRef.current = true;
    getUrl('countries').then(countryList => {
        // console.log(countryList)
        if(isMountedRef.current) {
            setCountry(countryList.data);
            // const filteredCountryList = _.filter(countryList.data, countryItem => countryItem.code === 'CN' || countryItem.code === 'MY');
            // setCountry(filteredCountryList);
        }
    }).catch(error => {
        addAlert(JSON.stringify(error.message));
    });
    return () => isMountedRef.current = false;
}, [i18n.language, addAlert, t, addressFormState.country]);

useEffect(() => {
    isMountedRef.current = true;
    if(addressFormState.country) {
        if(_.includes(addressGroup.stateGroup, addressFormState.country)) {
            getUrl(`states/${addressFormState.country}`).then(stateList => {
                if(isMountedRef.current) {
                    setAddressState(stateList.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }
    return () => isMountedRef.current = false;
    // eslint-disable-next-line
}, [i18n.language, addressFormState.country, addAlert, t]);

useEffect(() => {
    isMountedRef.current = true;
    if(addressFormState.state) {
        if(_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state)) {
            getUrl(`cities/${addressFormState.state}`).then(cityList => {
                if(isMountedRef.current) {
                    setCity(cityList.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }
    return () => isMountedRef.current = false;
    // eslint-disable-next-line
}, [i18n.language, addressFormState.country, addressFormState.state, addAlert, t]);

useEffect(() => {
    isMountedRef.current = true;
    if(addressFormState.city) {
        if(_.includes(addressGroup.stateGroup, addressFormState.country) && _.includes(addressGroup.cityGroup, addressFormState.state) && _.includes(addressGroup.districtGroup, addressFormState.city)) {
            getUrl(`districts/${addressFormState.city}`).then(districtList => {
                if(isMountedRef.current) {
                    setDistrict(districtList.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }
    return () => isMountedRef.current = false;
    // eslint-disable-next-line
}, [i18n.language, addressFormState.country, addressFormState.city, addAlert, t]);

useEffect(() => {
    isMountedRef.current = true;
    if(state.addressId !== null){
        setDisabledSubmit(true);
        setFetchingMallShipping(true);
        const selectedAddress = _.find(addresses, { 'id': state.addressId });
        const updateData = {
            user_zip: selectedAddress?selectedAddress.zip:'',
            user_state: selectedAddress?selectedAddress.state:'',
            user_country: selectedAddress?selectedAddress.country:'',
        }
        putUrl('mall/shipping_fee', updateData).then(response => {
            setFetchingMallShipping(false);
            if(isMountedRef.current) {
                if(response.status){
                    setMallShipping(response.data);
                    // setDisabledSubmit(response.error);
                    let checkDisableSubmit=response.error;
                    _.map(response.data,(merchantItem, merchantIndex)=>{
                        let merchant_channel=_.find(selfPickUpInfo,{'id':merchantIndex});
                        if(_.size(merchant_channel)>0){
                            if(merchant_channel.self_pickup){
                                checkDisableSubmit=false;
                            }
                        }
                    })
                    setMallFee(0);
                    setDisabledSubmit(checkDisableSubmit);
                    setMallTotalShipping(response.total_shipping?response.total_shipping:0);
                }else{
                    addAlert(JSON.stringify(response.data));
                    setDisabledSubmit(true);
                }
            }
        }).catch(error => {
            setFetchingMallShipping(false);
            addAlert(JSON.stringify(error.message));
        });
    }
    return () => isMountedRef.current = false;
}, [addAlert, addresses, state.addressId, mallShippingRefresh]);

useEffect(() => {
    getUrl('shipping_fee/wallet').then(response => {
        if(response.status){
            let shippingFeePrefix = '';
            if(currencyData){
                shippingFeePrefix = i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en;
            }
            if(_.size(response.data) > 0){
                let name = _.split(response.data.name, '|');
                shippingFeePrefix = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                setShippingWallet({id: response.data.id, prefix: shippingFeePrefix, decimal: response.data.decimal});
            }else{
                setShippingWallet({id: 0, prefix: shippingFeePrefix, decimal: 2});
            }
        }else{
            addAlert(JSON.stringify(response.data));
            setDisabledSubmit(true);
        }
    }).catch(error => {
        addAlert(JSON.stringify(error.message));
    });
    return ;
}, [i18n.language, addAlert, currencyData]);

useEffect(() => {
    let tempWallet = {};
    let cash = parseFloat(state.totalPrice);
    let walletCash = 0;

    _.map(walletPoint, (cartWallets, cartId) => {
        _.map(cartWallets, (amount, wid) => {
            if (wid !== 'cash' && amount > 0) {
                let w = _.find(wallets, { 'id': parseInt(wid) })
                let display_name = w.wallet_name ? w.wallet_name : w.code;
                tempWallet[display_name] = tempWallet[display_name] ? (parseFloat(tempWallet[display_name]) + parseFloat(amount)).toFixed(2) : parseFloat(amount).toFixed(2);
                walletCash += parseFloat(amount);
            }
        })
    });
        
    if (shippingWallet.id > 0) {
        if (parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) > 0) {
            const old_amount = parseFloat(tempWallet[shippingWallet.prefix] ? tempWallet[shippingWallet.prefix] : 0);
            tempWallet[shippingWallet.prefix] = (old_amount + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping)-parseFloat(pickup)).toFixed(shippingWallet.decimal);
        }
    } else {
        cash = parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup);
    }
    
    setTotalPayment({
        // cash: cash - parseFloat(redeemCash) ,
        cash: cash - parseFloat(walletCash) ,
        wallet_list: tempWallet
    });
}, [state.totalPrice, state.totalShippingPrice, state.totalWalletPrice, mallTotalShipping, shippingWallet, redeemWallet, redeemCash, pickup, walletPoint]);

useEffect(() => {
    let totalCashAmount = 0;
    let totalCashLeft = 0;
    let tempRedeemWallet = {};

    if (shippingWallet.id > 0) {
        totalCashAmount = totalCashLeft = parseFloat(state.totalPrice).toFixed(2);
    } else {
        totalCashAmount = totalCashLeft = parseFloat(parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping)-parseFloat(pickup)).toFixed(2);
    }
    totalCashAmount = totalCashLeft =0; //dont auto fill
    _.map(wallets, wallet => {
        if(wallet.is_cash === 1){
            let name = _.split(wallet.name, '|');
            let display_name = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
            // default redeem full amount
            let rate = 1;
            if(wallet.cash_rate > 0){
                rate = wallet.cash_rate;
            }
            let cashBalance = wallet.balance*rate;
            if(totalCashLeft > cashBalance){
                tempRedeemWallet[display_name] = parseFloat(wallet.balance).toFixed(2); //wallet.decimal
                totalCashLeft -= cashBalance;
            }else{
                tempRedeemWallet[display_name] = parseFloat(totalCashLeft/rate).toFixed(2); //wallet.decimal
                totalCashLeft = 0;
            }
        }
    })
    setRedeemWallet(tempRedeemWallet);
    setRedeemCash(parseFloat(parseFloat(totalCashAmount) - parseFloat(totalCashLeft)).toFixed(2));
}, [wallets, state.totalPrice, state.totalShippingPrice, shippingWallet, mallTotalShipping,pickup]);

const handleRedeemWalletChange = (wType, wAmount) => {
    let totalRedeemCash = 0;
    _.map(redeemWallet, (walletAmount, walletType) => {
        let rate = 1;
        _.map(wallets, wallet => {
            let name = _.split(wallet.name, '|');
            let display_name = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
            if(display_name === walletType){
                if(wallet.cash_rate > 0){
                    rate = wallet.cash_rate;
                }
            }
        })
        if(wType === walletType){
            totalRedeemCash += wAmount>0?(parseFloat(wAmount)*rate):0;
        }else{
            totalRedeemCash += walletAmount>0?parseFloat(walletAmount)*rate:0;
        }
    })
    setRedeemWallet({ ...redeemWallet, [wType]: wAmount});
    setRedeemCash(totalRedeemCash);
};

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);

});

const handleAddressChange = async (event) => {
    const name = event.target.name;
    let value = event.target.value;
    if(name === 'icFront') {
        if(icFrontRef.current.files[0]) {
            const a = await toBase64(icFrontRef.current.files[0]);
            value = a;
        } else {
            value = '';
        }
    } else if(name === 'icBack') {
        if(icBackRef.current.files[0]) {
            const a = await toBase64(icBackRef.current.files[0]);
            value = a;
        } else {
            value = '';
        }
    }
    setAddressFormState({ ...addressFormState, [name]: value });
    if(name === 'country') {
        setAddressFormState(addressFormState => ({ ...addressFormState, state: '', city: '', address2: '' }));
    }
};
const openAddAddress = () => {
    setErrorAddressState({});
    setState(state => ({ ...state, mode: 'add', modalShow: true }));
};
const openEditAddress = selectedAddressId => {
    setErrorAddressState({});
    const editedAddress = _.find(addresses, { 'id': selectedAddressId });
    if(editedAddress) {
        const { name, mobile, email, identity_no, address, address2, city, state, zip, country, icFront, icBack } = editedAddress;
        let newAddressFormState = { 
            name: name || "", 
            mobile: mobile || "", 
            email: email || "", 
            identity_no: identity_no || "", 
            address, 
            address2, 
            city, 
            state, 
            zip, 
            country, 
            icFront, 
            icBack 
        };
        if(_.size(icFront) > 0) {
            newAddressFormState['icFront'] = icFront.file_name;
        }
        if(_.size(icBack) > 0) {
            newAddressFormState['icBack'] = icBack.file_name;
        }

        setAddressFormState(newAddressFormState);
        setState(state => ({ ...state, mode: 'edit', modalShow: true, editAddressId: selectedAddressId }));
    }
}
const closeModal = () => {
    // setState({ ...state, modalShow: false, mode: null, 'editAddressId': null });
    setState(state => ({ ...state, modalShow: false, mode: null, 'editAddressId': null }));
    setAddressFormState(INITIAL_ADDRESS_STATE);
};

const handleRemarkChange = (event, merchantId) => {
    setRemark({ ...remark, [merchantId]: event.target.value });
    // console.log('remark', remark);
}

const handleShippingFeeClick = (merchantId) => {
    setShippingFeeOpen({ ...shippingFeeOpen, [merchantId]: !shippingFeeOpen[merchantId]});
}
const handleMallShippingFee = (event, merchantId) => {
    let newValue = parseInt(event.target.value);
    setMallShipping({...mallShipping, [merchantId]: {...mallShipping[merchantId], selected_rate: {
        key: newValue,
        shipping_fee: mallShipping[merchantId].rate_list[newValue].price,
        courier_id: mallShipping[merchantId].rate_list[newValue].courier_id,
        service_id: mallShipping[merchantId].rate_list[newValue].service_id,
        courier_name: mallShipping[merchantId].rate_list[newValue].courier_name,
    }}});
    let tempMall = mallShipping;
    tempMall[merchantId].selected_rate = {
        key: newValue,
        shipping_fee: mallShipping[merchantId].rate_list[newValue].price,
        courier_id: mallShipping[merchantId].rate_list[newValue].courier_id,
        service_id: mallShipping[merchantId].rate_list[newValue].service_id,
        courier_name: mallShipping[merchantId].rate_list[newValue].courier_name,
    };
    let totalShippingFee = 0;
    _.map(tempMall, (value) => {
        if(!value.error && value.selected_rate){
            totalShippingFee += parseFloat(value.selected_rate.shipping_fee);
            setMallFee(prevState=>({
                ...prevState,
                [merchantId]:{
                    fee:value.selected_rate.shipping_fee,   
                }
            }))
        }
    })
    setMallTotalShipping(totalShippingFee);
}

const openUserDialog = (type, info) => {
    setUserDialog({
        type: type,
        open: true,
        name: info.name,
        email: info.email,
        mobile: info.mobile,
    });
}
const closeUserModal = () => {
    setUserDialog({ ...userDialog, open: false });
}
const saveUserModal = () => {
    if(userDialog.type === 'buyer'){
        setBuyerInfo({
            name: userDialog.name,
            email: userDialog.email,
            mobile: userDialog.mobile,
        })
    }
    else if(userDialog.type === 'recipients'){
        setRecipientInfo({
            name: userDialog.name,
            email: userDialog.email,
            mobile: userDialog.mobile,
        })
    }
    setUserDialog({ ...userDialog, open: false });
}

const calculateTotal = userCart => {
    // console.log("calculate total", userCart);
    // console.log("isMalaysiaShipping", isMalaysiaShipping);
    // console.log("malaysiaShippingArea", malaysiaShippingArea);
    
    let totalPrice = 0;
    let totalSelected = 0;
    let totalWalletPrice = {};
    let forMultiWalletBalance = {};
    let totalShippingPrice = 0;

    _.map(userCart, (merchantItem,merchantId) => {
        let merchant_channel=_.find(selfPickUpInfo,{'id':merchantId});
        if(merchant_channel &&merchant_channel.channel =='pickup'){
            totalShippingPrice += 0;
        }else{
            if(isMalaysiaShipping && malaysiaShippingArea === 'east') {
                totalShippingPrice += merchantItem.shipping_fee2 > 0 ? parseFloat((merchantItem.shipping_fee2).toFixed(2)) : 0;
            } else {
                totalShippingPrice += merchantItem.shipping_fee > 0 ? parseFloat((merchantItem.shipping_fee).toFixed(2)) : 0;
            }
        }
        _.map(merchantItem.items, (cartDetails) => {
            _.map(cartDetails, (cartItem) => {
                if(cartItem.selected) {
                    totalSelected += parseInt(cartItem.quantity);
                    let itemPrice = parseFloat(cartItem.product.sell_price);
                    if(_.size(cartItem.product.options) > 0){
                        _.map(cartItem.product.options, (option) => {
                            _.map(option.pricing, (pricingOption) => {
                                if(pricingOption.id === cartItem.product_warehouse_id) {
                                    itemPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : cartItem.product.sell_price
                                }
                            })
                        })
                    }
                    if(_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id){
                        totalPrice += parseFloat((itemPrice * cartItem.quantity).toFixed(2));                            
                    }else{
                        totalPrice += parseFloat((itemPrice * cartItem.quantity).toFixed(2));
                    }
                }
            })
        })
    });
    setMultiWalletBalance(forMultiWalletBalance);
    setState(state => ({ ...state, totalPrice, totalSelected, totalWalletPrice, totalShippingPrice }));
};

const setupWalletPoint = userCart => {
    let tempWalletPoint = {};
    _.map(userCart, (merchantItem, merchantId) => {
        _.map(merchantItem.items, (cartDetails) => {
            _.map(cartDetails, (cartItem) => {
                if(cartItem.selected) {
                    if(_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id){
                        // find total price
                        let productPrice = cartItem.product.sell_price;
                        if (_.size(cartItem.product.options) > 0) {
                            _.map(cartItem.product.options, (optionItem) => {
                                _.map(optionItem.pricing, (pricingOption) => {
                                    if (pricingOption.id === cartItem.product_warehouse_id) {
                                        productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
                                    }
                                })
                            })
                        }
                        let totalPrice = cartItem.quantity * productPrice;
                        let sumTotal = 0;
                        let isSetCash = false;
                        // set cash wallet to productPrice
                        let productWallet = {};
                        const selectedPricing = _.find(cartItem.product.multi_pricings, { 'id': cartItem.multi_pricing_id });
                        _.map(selectedPricing.wallet_settings, setting => {
                            _.map(setting.wallet_ids, wid => {
                                productWallet[wid] = (walletPoint[cartItem.id] && walletPoint[cartItem.id][wid]) ? walletPoint[cartItem.id][wid] : 0;
                                if(wid != 'cash'){
                                    sumTotal += parseFloat(productWallet[wid]);
                                }else{
                                    isSetCash = true;
                                }
                            })
                        })
                        if(isSetCash){
                            let cash = totalPrice - sumTotal;
                            productWallet['cash'] = cash > 0 ? cash : 0;
                        }
                        // set wallet point
                        tempWalletPoint[cartItem.id] = productWallet;
                    }
                }
            })
        })
    });
    setWalletPoint(tempWalletPoint);
}

const submitOrder = () => {
    setCartError([]);
    let onlySelfPickUp = true;
    _.map(selfPickUpInfo, (merchant, merchantIndex) => {
        if(merchant.self_pickup===false){
            onlySelfPickUp = false;
        }
    });
    if (!onlySelfPickUp && !state.addressId){
        // show error to select address
        addAlert(t('checkout.noAddressSelected'));
    }
    // if(state.addressId) {
    else{
        setLoading(true);
        const selectedAddress = _.find(addresses, { 'id': state.addressId });
        // let redeemPoint = [];
        // _.map(redeemWallet, (wAmount, wType) => {
        //     if(wAmount > 0){
        //         _.map(wallets, wallet => {
        //             let name = _.split(wallet.name, '|');
        //             let display_name = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
        //             if(display_name === wType){
        //                 redeemPoint = redeemPoint.concat({'wallet_id': wallet.id, 'wallet_amount': wAmount});
        //             }
        //         })
        //     }
        // })
        let orderField = {
            buyer_name: buyerInfo.name,
            buyer_email: buyerInfo.email,
            buyer_mobile: buyerInfo.mobile,
            //recipient: selectedAddress.name ,
            //recipient_email: buyerInfo.email,
            //mobile: selectedAddress.mobile ,
            //country: selectedAddress.country ,
            //zip: selectedAddress.zip ,
            //state: selectedAddress.state ,
            //city: selectedAddress.city ,
            //address: selectedAddress.address,
            //address2: selectedAddress.address2 ,
            remark,
            mall_shipping: mallShipping,
            recipient_source: downlineInfo.source,
            recipient_username: downlineInfo.username,
            address_id: state.addressId?state.addressId:0,
            // redeem_point: redeemPoint,
            self_pickup:selfPickUpInfo,
            wallet_point: walletPoint
        }
        if(selectedAddress){
            let orderField2 = {
                recipient: selectedAddress.name,
                recipient_email: buyerInfo.email,
                mobile: selectedAddress.mobile,
                country: selectedAddress.country,
                zip: selectedAddress.zip,
                state: selectedAddress.state,
                city: selectedAddress.city,
                address: selectedAddress.address,
                address2: selectedAddress.address2,
            }
            orderField ={...orderField,...orderField2}
        }
        postUrl('orders', orderField)
        .then(response => {
            setLoading(false);
            // console.log("response", response);
            if(response.status === 1) {
                // redirect to payment page
                dispatch(updateCartTotal(0));
                if(response.order_cancelled){
                    history.push({pathname: `account/order`, state: {error_msg: t('checkout.orderCancelled')}});
                }else{
                    history.push(`/payment/${encodeURIComponent(response.data[0]['purchase_no'])}`);
                }
            } else {
                // setState({ ...state, open: true, error: true, message: response.message });
                setUpdateCart(!updateCart);
                if(response.error) {
                    let newErrorField = {};
                    let displayErrorMsg = [];
                    _.map(response.error, (errorItem, errorIndex) => {
                        setCartError(response.error);
                        newErrorField[errorIndex] = errorItem[0]
                        displayErrorMsg.push(errorItem[0]);
                    })
                    console.log("displayErrorMsg", displayErrorMsg);
                    setErrorField(newErrorField);
                    addAlert(_.map(displayErrorMsg).join(' '));
                } else if(response.message) {
                    addAlert(JSON.stringify(response.message));
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    } 
}

const submitAddress = () => {
    if(state.mode === 'add') {
        postUrl('user/address', addressFormState)
        .then(result => {
            if(result.error) {
                let errorFieldState = {};
                _.map(result.error, (errorItem, errorIndex) => {
                    errorFieldState[errorIndex] = errorItem;
                })
                setErrorAddressState(errorAddressState => (errorFieldState));
                addAlert(t('profile.fillUpRequiredAddress'));
            } else {
                setAddressFormState(INITIAL_ADDRESS_STATE);
                addAlert(result.message, 'success');
                setState( state => ({ ...state, modalShow: false }) ); // , snackbarShow: true, error: false, message: result.message
                getUrl('user/address').then(addressList => {
                    setAddress(addressList.data);

                    let defaultAddress = _.find(addressList.data, { 'default': true });
                    if(_.size(defaultAddress) > 0) {
                        setState(state => ({ ...state, addressId: defaultAddress.id }))
                    } else {
                        if(_.size(addressList.data)) {
                            const firstAddress = addressList.data[0];
                            setState(state => ({ ...state, addressId: firstAddress.id }))
                        }
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    } else {
        putUrl(`user/address/${state.editAddressId}`, addressFormState)
        .then(result => {
            if(result.error) {
                let errorFieldState = {};
                _.map(result.error, (errorItem, errorIndex) => {
                    errorFieldState[errorIndex] = errorItem;
                })
                setErrorAddressState(errorAddressState => (errorFieldState));
                addAlert(t('profile.fillUpRequiredAddress'));
            } else {
                setAddressFormState(INITIAL_ADDRESS_STATE);
                const refreshMall = (state.editAddressId === state.addressId);
                setState( state => ({ ...state, modalShow: false, mode: null, editAddressId: null }) ); // , snackbarShow: true, error: false, message: result.message
                addAlert(result.message, 'success');
                getUrl('user/address').then(addressList => {
                    setAddress(addressList.data);
                    if(refreshMall){
                        setMallShippingRefresh(!mallShippingRefresh);
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }
}

const priceDisplay = (cartItem) => {
    let productPrice = cartItem.product.sell_price;
    if(_.size(cartItem.product.options) > 0){
        _.map(cartItem.product.options, (optionItem) => {
            _.map(optionItem.pricing, (pricingOption) => {
                if (pricingOption.id === cartItem.product_warehouse_id) {
                    productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
                }
            })
        })
    }
    return (
        <>
        {
            (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) ?
            _.map(cartItem.product.multi_pricings, (pricingItem, pricingIndex) => {
                if (pricingItem.id === parseInt(cartItem.multi_pricing_id)) {
                    let pricingText = [];
                    _.map(pricingItem.wallet_settings, (setting, key) => {
                        let minAmount = parseFloat(setting.type === 'percent' ? productPrice*(setting.min_amount/100) : setting.min_amount);
                        let maxAmount = parseFloat(setting.type === 'percent' ? productPrice*(setting.max_amount/100) : setting.max_amount);
                        pricingText[key] = {'prefix': `${setting.pricing_name} `, 'min_amount': parseFloat(minAmount * cartItem.quantity).toFixed(2), 'max_amount': parseFloat(maxAmount * cartItem.quantity).toFixed(2)};
                    })
                    return (
                        <Box key={pricingIndex} display="flex" flexDirection="column">
                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                <Typography style={{ color: theme.palette.gray.text, fontSize: 16 }}>
                                    <NumberFormat value={(productPrice * currencyRate[`${currency}`])} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                </Typography>
                                <Typography style={{ color: theme.palette.gray.text, fontSize:16  }}>x {cartItem.quantity}</Typography>
                            </div>
                            <Typography variant="h6" style={{ color: theme.palette.price.main }}>
                                <NumberFormat value={(cartItem.quantity * productPrice * currencyRate[`${currency}`])} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                            </Typography>
                            {
                                _.map(pricingText, (data, key) => {
                                    return (
                                        <Typography variant="overline" key={key} style={{lineHeight:1.3}}>
                                            <NumberFormat value={data.min_amount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={data.prefix} />
                                            {
                                                data.min_amount !== data.max_amount
                                                ?
                                                <NumberFormat value={data.max_amount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={' - '} />
                                                : null
                                            }
                                        </Typography>
                                    )
                                })
                            }
                        </Box>
                    )
                } else {
                    return null;
                }
            })
            :
            <Typography variant="h6" style={{ color: theme.palette.price.main }}>
                <NumberFormat value={(cartItem.quantity * productPrice*currencyRate[`${currency}`])} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
            </Typography>
        }
        </>
    )
}

const handleChannelChange=(channel, merchantId,mallShippingFee)=>{ 
    let merchant=_.find(selfPickUpInfo,{'id':merchantId});
    let shipping_fee=_.find(mallFee,{'id':merchantId});
    let total_deduct=pickup;
    // console.log("merchant",merchant);
    
    if(_.size(merchant)>0 ){
        setSelfPickupInfo(prevState=>({
            ...prevState,
            [merchant.id]:{
                // id:merchant.id,
                ...prevState[merchant.id],
                channel:channel,
                self_pickup:channel =='pickup'? true:false,
                outlet_id:0,
            }
        }))

        //for mall shipping
        if(channel =='pickup'){
            if(shipping_fee && _.size(shipping_fee)>0){
                total_deduct +=shipping_fee.fee;
            }else if(mallShippingFee && !mallShipping[merchantId].error && mallShipping[merchantId].selected_rate.shipping_fee){
                total_deduct +=mallShipping[merchantId].selected_rate.shipping_fee;
            }
            SetPickUp(total_deduct);

            if(mallShipping[merchantId] && mallShipping[merchantId].error ==true){
                setDisabledSubmit(false);
            }

        }else{
            if(shipping_fee && _.size(shipping_fee)>0){
                total_deduct -=shipping_fee.fee;
            }else if(mallShippingFee && !mallShipping[merchantId].error && mallShipping[merchantId].selected_rate.shipping_fee){
                total_deduct -=mallShipping[merchantId].selected_rate.shipping_fee;
            }
            SetPickUp(total_deduct);

            if(mallShipping[merchantId] && mallShipping[merchantId].error ==true){
                setDisabledSubmit(true);
            }
        }

        // console.log("deduct", total_deduct);
        
    }
    // console.log("selfpickupinfo",selfPickUpInfo)
    
}

const handleOutletChange=(outlet_id, merchantId)=>{
    let merchant=_.find(selfPickUpInfo,{'id':merchantId});
    if(_.size(merchant)>0 && merchant.channel =='pickup' ){
        setSelfPickupInfo(prevState=>({
            ...prevState,
            [merchant.id]:{
                // id:merchant.id,
                // channel:'pickup',
                // self_pickup:true,
                ...prevState[merchant.id],
                outlet_id:outlet_id,
            }
        }))
    }
    // console.log("selfpickupinfo",selfPickUpInfo)
}

const handleWalletPointChange = (cartItem, wallet_id, value) => {
    let productPrice = cartItem.product.sell_price;
    if(_.size(cartItem.product.options) > 0){
        _.map(cartItem.product.options, (optionItem) => {
            _.map(optionItem.pricing, (pricingOption) => {
                if (pricingOption.id === cartItem.product_warehouse_id) {
                    productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
                }
            })
        })
    }
    let totalPrice = cartItem.quantity * productPrice;
    let sumTotal = 0;
    let isSetCash = false;
    _.map(walletPoint[cartItem.id], (amount, wid) => {
        if(wid != 'cash'){
            if(wid == wallet_id){
                sumTotal += parseFloat(value)?parseFloat(value):0;
            }else{
                sumTotal += parseFloat(amount);
            }
        }else{
            isSetCash = true;
        }
    })
    console.log('sumTotal', sumTotal);
    if(isSetCash && wallet_id != 'cash'){
        let cash = totalPrice - sumTotal;
        if(cash > 0){
            setWalletPoint({ ...walletPoint, [cartItem.id]: { ...walletPoint[cartItem.id], [wallet_id]: value, ['cash']: cash } });
        }else{
            setWalletPoint({ ...walletPoint, [cartItem.id]: { ...walletPoint[cartItem.id], [wallet_id]: value, ['cash']: 0 } });
        }
    }else{
        setWalletPoint({ ...walletPoint, [cartItem.id]: { ...walletPoint[cartItem.id], [wallet_id]: value } });
    }
}


////////dialog - change address////////
const [open, setOpen] = React.useState(false);

const handleClickOpen = () => {
    setOpen(true);
};
const handleClose = () => {
    setOpen(false);
};

return(
    <Box className={styles.root2} >
        <TitleBar height={70} title={t('title.checkout')} displayInfo currencyButton displayCart back pagetitle />
        <Box className='fixed-header-padding'>
            {/* ////// buyer info ////// */}
            <div style={{padding:'15px 25px 30px', boxShadow:'0 7px 6px 0 #00000017'}}>
                <div style={{ color: 'black', backgroundColor: 'transparent', height: '100%' }}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  }}>
                        <Typography variant="h6" style={{ color: '#adacac', fontSize: 17 }}>{t('checkout.buyer')}</Typography>
                        <IconButton aria-label="settings" style={{ zIndex: 1, padding: 0 }} onClick={() => openUserDialog('buyer', buyerInfo)}>
                            <Edit size={18} style={{ color: theme.palette.primary.main }} />
                        </IconButton>
                    </Box>
                    <div style={{ paddingTop: 0 }}>
                        <Box display="flex" flexDirection="column">
                            <Typography variant="subtitle2">{t('profile.name')}: {buyerInfo.name}</Typography>
                            <Typography variant="subtitle2">{t('profile.mobile')}: {buyerInfo.mobile}</Typography>
                            <Typography variant="subtitle2">{t('profile.email')}: {buyerInfo.email}</Typography>
                        </Box>
                    </div>
                </div>
                <div style={{ paddingTop:25 }}>
                    <Typography variant="h6" style={{fontSize: 16, color:theme.palette.gray.text }}>{t('checkout.recipientUsername')}</Typography>
                    <TextField
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={downlineInfo.username}
                        margin="dense"
                        onChange={(event) => setDownlineInfo({ ...downlineInfo, username: event.target.value })}
                    />
                </div>
            </div>
            <Box style={{ padding: '0 15px' }}>
                <Grid container spacing={1} justify="center">
                    <Grid item xs={12}>
                        {errorField && _.map(errorField, (errorMsg, key) => (
                            <Grid key={errorMsg + key} item xs={12} sm={12}>
                                <Typography variant="body1" color="error">{errorMsg}</Typography>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 30 }}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Typography variant="h6">{t('checkout.shippingAddress')}</Typography>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography onClick={handleClickOpen} style={{ whiteSpace: 'nowrap', fontSize: '14px', marginLeft: '8px', color: '#f72b60', cursor: 'pointer' }}>{t('button.changeAddress')}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    
                    <div>
                        {
                            _.size(addresses) > 0 ?
                                _.map(addresses, (addressItem) => {
                                    if (state.addressId == addressItem.id) {
                                        return (
                                            <Card  key={addressItem.id} style={{ color: addressItem.id === state.addressId ? 'white' : 'black', backgroundColor: addressItem.id === state.addressId ? theme.palette.button.main : 'white', height: '100%' }}>
                                                    <CardHeader
                                                        action={
                                                            <IconButton aria-label="settings" style={{ zIndex: 1 }} onClick={() => openEditAddress(addressItem.id)} disabled={fetchingMallShipping} >
                                                                <Edit size={14} color={addressItem.id === state.addressId ? 'white' : 'black'} />
                                                            </IconButton>
                                                        }
                                                        style={{ paddingBottom: 0 }}
                                                    />
                                                    <CardActionArea onClick={() => setState({ ...state, addressId: addressItem.id })} style={{ height: '100%', marginTop: -46, paddingTop: 20, paddingBottom: 20 }} disabled={fetchingMallShipping} >
                                                        <CardContent style={{ paddingTop: 0 }}>
                                                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                                                <Box display="flex" flexDirection="row" alignItems="center" style={{ width: 'calc(100% - 34px)' }}>
                                                                    <Typography variant="subtitle2" style={{ whiteSpace: 'nowrap', fontSize: 19, maxWidth: 150, textOverflow: 'ellipsis', overflow: 'hidden', }}>{`${addressItem.name || '-'}`}</Typography>
                                                                    <Typography variant="subtitle2" style={{ paddingLeft: 10, whiteSpace: 'nowrap', fontSize: 16 }}>( {`${addressItem.mobile || '-'}`} )</Typography>
                                                                </Box>
                                                            </Box>
                                                            <Typography variant="body2" style={{ textAlign: 'justify', paddingTop: 10 }}>
                                                                {` ${addressItem.address}, ${addressItem.address2_display}, ${addressItem.city_display}, ${addressItem.state_display}, ${addressItem.zip}, ${addressItem.country_display}`}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                        )
                                    }

                                })
                                :
                                <Box minHeight={100} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                                    {
                                        addresses === null ?
                                            <>
                                                <CircularProgress disableShrink />
                                                <Typography variant="caption">{t('general.fetchingData')}</Typography>
                                            </>
                                            :
                                            <Typography variant="caption">{t('checkout.noAddress')}</Typography>
                                    }
                                </Box>
                        }
                    </div>

                    {/* // checkout listing */}
                    <Grid item xs={12} style={{ marginTop: 30 }}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Typography variant="h6">{t('checkout.checkoutListing')}</Typography>
                            <Link underline='none' color="inherit" to="/cart" component={RouterLink} style={{ display: 'flex', alignItems: 'center' }}>
                                <Button variant="contained" style={{ display: 'flex', alignItems: 'center', width: '32px', height: '32px', minWidth: '32px' }} color="secondary" onClick={openAddAddress}>
                                    <Box style={{ lineHeight: '0' }}>
                                        <Edit3 style={{ color: '#fff', lineHeight: '0' }} size={27} />
                                    </Box>
                                </Button>
                                <Hidden only={['xs']}>
                                    <Typography style={{ whiteSpace: 'nowrap', fontSize: '14px', marginLeft: '8px', color: '#f72b60' }}>{t('checkout.backToCart')}</Typography>
                                </Hidden>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            {
                                state.totalSelected && state.totalSelected != null ?
                                    _.map(cart, (merchantItem, merchantId) => {
                                        // console.log("cart", cart);
                                        let showMerchant = false;
                                        let selfShippingFee = false;
                                        let mallShippingFee = false;
                                        let shippingFeePrefix = shippingWallet.prefix;
                                        let outlet_list = [];
                                        let merchant_channel_list = _.find(selfPickUpInfo, { 'id': merchantId });

                                        if (_.size(merchantItem.outlets) > 0) {
                                            outlet_list = merchantItem.outlets;
                                        }

                                        _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                                            _.map(cartDetails, cartItem => {
                                                // console.log("cartItem", cartItem)
                                                if (cartItem.selected) {
                                                    showMerchant = true;
                                                    if (shippingChannel === 'self') {
                                                        selfShippingFee = true;
                                                    }
                                                }
                                            })
                                        })
                                        _.map(mallShipping, (value, merId) => {
                                            if (merchantId === merId) {
                                                mallShippingFee = true;
                                            }
                                        })

                                        if (showMerchant) {
                                            return (
                                                <Box key={merchantId} classes={{ root: styles.firstItemBoxStyle }} style={{ boxShadow: 'rgba(0, 0, 0, 0.067) 5px 5px 6px 0px, -4px -3px 6px 0 #fff', backgroundColor: '##ffffff66', borderRadius: 15, borderLeft: '3px solid #fff', borderTop: '3px solid #fff', }}>
                                                    <Box style={{ padding: '15px 20px' }}>
                                                        <Box display="flex" flexDirection="row" alignItems="center">
                                                            <Box>
                                                                <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
                                                                    <StorefrontOutlined fontSize="small" />
                                                                    <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 5 }}>{merchantItem.name}</Typography>

                                                                </Box>
                                                                <Typography variant="caption">{t('checkout.prepareTime', { 'day': merchantItem.prepare_time })}</Typography>
                                                            </Box>
                                                        </Box>
                                                        {
                                                            !fetchingMallShipping && merchant_channel_list ?
                                                                <Box style={{ paddingLeft: 10 }}>
                                                                    <FormControl component="fieldset">
                                                                        <RadioGroup row value={merchant_channel_list.channel}>
                                                                            <FormControlLabel
                                                                                onChange={(event) => handleChannelChange(event.target.value, merchantId, mallShippingFee)}
                                                                                value="delivery"
                                                                                control={<Radio color="primary" checkedIcon={<CheckCircle color="secondary" />} />}
                                                                                label={t('checkout.self-mallShipping')}
                                                                                size="small"
                                                                            />
                                                                            {
                                                                                merchantItem.self_pickup == 1 ?
                                                                                    <FormControlLabel
                                                                                        onChange={(event) => handleChannelChange(event.target.value, merchantId, mallShippingFee)}
                                                                                        value="pickup"
                                                                                        control={<Radio color="primary" checkedIcon={<CheckCircle color="secondary" />} />}
                                                                                        label={t('checkout.selfPickUp')}
                                                                                        size="small"
                                                                                    />
                                                                                    : null
                                                                            }
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                    {
                                                                        merchant_channel_list && merchant_channel_list.channel == 'pickup' ?
                                                                            <div style={{ padding: 10 }}>
                                                                                {/* <Typography variant="caption">{t('checkout.outletSelect')}</Typography> */}
                                                                                <Grid container spacing={3} justify="center" style={{ paddingTop: 5 }}>
                                                                                    <Grid item xs={12}>
                                                                                        <TextField
                                                                                            select
                                                                                            label={t('checkout.outletSelect')}
                                                                                            value={merchant_channel_list ? merchant_channel_list.outlet_id : ''}
                                                                                            size="small"
                                                                                            onChange={(event) => handleOutletChange(event.target.value, merchantId)}
                                                                                            variant="outlined"
                                                                                            fullWidth
                                                                                            // error={error ? true : false}
                                                                                            // helperText={error}
                                                                                            SelectProps={{
                                                                                                autoWidth: true,
                                                                                                MenuProps: { MenuListProps: { style: { width: '70vh' } } }
                                                                                            }}
                                                                                        >
                                                                                            {outlet_list.map((option) => (
                                                                                                <MenuItem key={option.id} value={option.id} divider autoFocus style={{ display: 'flex' }}>
                                                                                                    <Typography variant="body2" key={option.id} value={option.id} noWrap> {`${option.outlet_name} -[${option.address}]`}</Typography>
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </TextField>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </Box>
                                                                : null
                                                        }
                                                    </Box>

                                                    {
                                                        _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                                                            return (
                                                                <Box key={shippingChannel}>
                                                                    {
                                                                        _.map(cartDetails, (cartItem, cartIndex) => {
                                                                            if (cartItem.selected) {
                                                                                const title_translate = i18n.language === 'cn' ? cartItem.product.title_cn : cartItem.product.title_en;
                                                                                let variant_translate = [];
                                                                                _.map(cartItem.product.options, (option) => {
                                                                                    _.map(option.pricing, (pricingOption) => {
                                                                                        if (pricingOption.id === cartItem.product_warehouse_id) {
                                                                                            if (option.color) {
                                                                                                variant_translate.push(`${(i18n.language === 'cn' ? (option.color_cn ? option.color_cn : option.color_en) : option.color_en)}`);
                                                                                            }
                                                                                            if (option.size) {
                                                                                                variant_translate.push(`${(i18n.language === 'cn' ? (option.size_cn ? option.size_cn : option.size_en) : option.size_en)}`);
                                                                                            }
                                                                                            if (option.attribute_value) {
                                                                                                variant_translate.push(`${(i18n.language === 'cn' ? (option.attribute_value_cn ? option.attribute_value_cn : option.attribute_value_en) : option.attribute_value_en)}`);
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                });
                                                                                return (
                                                                                    <Box key={cartItem.id} classes={{ root: styles.itemBoxStyle }}>
                                                                                        <ListItem style={{ backgroundColor: 'transparent' }}>
                                                                                            <Grid container>
                                                                                                <Grid item xs={12} sm={12} md={12} >
                                                                                                    <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                                                                        <Box border={1} borderColor="#dee2e6" style={{ width: 100, minWidth: 100, borderRadius: 15 }}>
                                                                                                            <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} style={{ display: 'flex', alignItems: 'center', }} component={RouterLink}>
                                                                                                                <WebpImg style={{ height: 100, width: 100, minWidth: 100, borderRadius: 15, objectFit: 'cover' }} alt={cartItem.product.id} src={cartItem.product.images_array[0] ? cartItem.product.images_array[0] : '/images/logo.png'} />
                                                                                                            </Link>
                                                                                                        </Box>
                                                                                                        <Box style={{ paddingLeft: 15, paddingRight: 15, width: '100%' }}>
                                                                                                            <Grid item xs={12}>
                                                                                                                <Link underline='none' color="inherit" to={`/item/${cartItem.product.id}`} component={RouterLink}><Typography style={{ fontSize: 16, lineHeight: 1.2, height:'unset' }} >{title_translate}</Typography></Link>
                                                                                                            </Grid>
                                                                                                            {
                                                                                                                _.size(variant_translate) > 0 ?
                                                                                                                    <Grid item xs={12}>
                                                                                                                        {/* <Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{t('cart.variations')}: </Typography> */}
                                                                                                                        {
                                                                                                                            _.map(variant_translate, variantText => {
                                                                                                                                return (
                                                                                                                                    <Chip key={variantText} size="small" style={{ marginLeft: 5 }} label={<Typography variant="caption" style={{ fontSize: 10, color: theme.palette.gray.chip }}>{variantText}</Typography>} variant="outlined" color="default" />
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </Grid>
                                                                                                                    : null
                                                                                                            }
                                                                                                            {
                                                                                                                merchant_channel_list && merchant_channel_list.channel == 'pickup' ?
                                                                                                                    null :
                                                                                                                    cartItem.product.shipping_channel === 'mall' && mallShippingFee && mallShipping[merchantId].error && mallShipping[merchantId].error_msg
                                                                                                                        ?
                                                                                                                        <Grid item xs={12}>
                                                                                                                            <Typography variant="caption" style={{ color: 'red' }}>{mallShipping[merchantId].error_msg}</Typography>
                                                                                                                        </Grid>
                                                                                                                        :
                                                                                                                        null
                                                                                                            }
                                                                                                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                                                                                                {/* <Typography style={{ color: theme.palette.gray.text }}>x{cartItem.quantity}</Typography> */}
                                                                                                                <Typography style={{fontSize:16}}>{priceDisplay(cartItem)}</Typography>
                                                                                                            </Box>
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                </Grid>
                                                                                                <Grid item xs={6} sm={6} md={3} style={{ padding: 10, textAlign: 'right', alignSelf: 'center' }}>
                                                                                                </Grid>
                                                                                                {_.size(cartErrors[`cart.${cartItem.id}`]) ?
                                                                                                    <Grid item xs={12} style={{ padding: 10, alignSelf: 'center' }}>
                                                                                                        <Typography variant="caption" style={{ color: 'red' }}>{cartErrors[`cart.${cartItem.id}`][0]}</Typography>
                                                                                                    </Grid>
                                                                                                    : null
                                                                                                }
                                                                                                {
                                                                                                    _.size(walletPoint[cartItem.id]) > 0
                                                                                                        ?
                                                                                                        <Grid item xs={12} sm={12} md={12}>
                                                                                                            <Typography variant="body2" style={{ color: theme.palette.gray.text, paddingBottom: "5px" }}>{t('checkout.walletPayment')}</Typography>
                                                                                                            <Grid container>
                                                                                                                {
                                                                                                                    _.map(walletPoint[cartItem.id], (amount, wid) => {
                                                                                                                        let prefix = ` ${i18n.language === 'cn' ? (currencyData.currency_display_cn ? currencyData.currency_display_cn : currencyData.currency_display_en) : currencyData.currency_display_en} `;
                                                                                                                        if (wid !== 'cash') {
                                                                                                                            let wallet = _.find(wallets, { 'id': parseInt(wid) });
                                                                                                                            if (wallet) {
                                                                                                                                prefix = wallet.wallet_name ? wallet.wallet_name : wallet.code;
                                                                                                                            }
                                                                                                                        }
                                                                                                                        return (
                                                                                                                            <Grid item xs={6} style={{ maxWidth: '48%', margin: "3px" }} key={wid}>
                                                                                                                                <TextField
                                                                                                                                    fullWidth={true}
                                                                                                                                    variant="outlined"
                                                                                                                                    label={prefix}
                                                                                                                                    // onChange={(event) => setWalletPoint({ ...walletPoint, [cartItem.id]: { ...walletPoint[cartItem.id], [wid]: event.target.value } })}
                                                                                                                                    onChange={(event) => handleWalletPointChange(cartItem, wid, event.target.value)}
                                                                                                                                    size="small"
                                                                                                                                    type="number"
                                                                                                                                    value={amount}
                                                                                                                                    style={{ marginBottom: 5 }}
                                                                                                                                />
                                                                                                                            </Grid>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                        : null
                                                                                                }
                                                                                            </Grid>
                                                                                        </ListItem>
                                                                                    </Box>
                                                                                )
                                                                            } else {
                                                                                return null;
                                                                            }
                                                                        })
                                                                    }


                                                                    {
                                                                        merchant_channel_list && merchant_channel_list.self_pickup == 1 ?
                                                                            null :
                                                                            shippingChannel === 'self' && selfShippingFee
                                                                                ?
                                                                                <Box alignItems="center" style={{ padding: '0 18px', backgroundColor: 'transparent' }}>
                                                                                    <Grid container spacing={1} justify="center" >
                                                                                        <Grid item xs={6} style={{ textAlign: 'left', color:theme.palette.gray.text, }}>
                                                                                            <Typography variant="body2" >{t('checkout.shippingFee')}: </Typography>
                                                                                        </Grid>
                                                                                        <Grid item xs={6} style={{ paddingRight: 10, textAlign: 'right', alignSelf: 'center' }}>
                                                                                            <Typography variant="body2">
                                                                                                {/* {calculate_total_shippingfess(merchantItem,shippingFeePrefix)}  */}
                                                                                                <NumberFormat value={isMalaysiaShipping && malaysiaShippingArea === 'east' ? parseFloat(merchantItem.shipping_fee2).toFixed(2) : parseFloat(merchantItem.shipping_fee).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={` ${shippingFeePrefix} `} />
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                                :
                                                                                null
                                                                    }
                                                                    {
                                                                        merchant_channel_list && merchant_channel_list.self_pickup == 1 ?
                                                                            null :
                                                                            shippingChannel === 'mall' && mallShippingFee &&
                                                                            <>
                                                                                {
                                                                                    fetchingMallShipping
                                                                                        ?
                                                                                        <Box alignItems="center" style={{ padding: '0 18px', backgroundColor: 'transparent' }}>
                                                                                            <Grid container spacing={1} justify="center" >
                                                                                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                                                                    <CircularProgress disableShrink size="1.5rem" />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Box>
                                                                                        :
                                                                                        (!mallShipping[merchantId].error && mallShipping[merchantId].selected_rate.shipping_fee)
                                                                                            ?
                                                                                            <Box alignItems="center" style={{ padding: '0 18px', backgroundColor: 'transparent' }}>
                                                                                                <Grid container spacing={1} justify="center" >
                                                                                                    {/* <Button onClick={() => handleShippingFeeClick([merchantId])} fullWidth style={{ textTransform: 'none'}} > */}
                                                                                                    <Grid item xs={6} style={{ paddingLeft: '5%', textAlign: 'left' }}>
                                                                                                        <Typography variant="body2" >{mallShipping[merchantId].selected_rate.courier_name} : </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={6} style={{ paddingRight: 10, textAlign: 'right', alignSelf: 'center' }}>
                                                                                                        <Typography variant="body2">
                                                                                                            <NumberFormat value={parseFloat(mallShipping[merchantId].selected_rate.shipping_fee).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={` ${shippingFeePrefix} `} />
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    {_.size(mallShipping[merchantId].rate_list) ?
                                                                                                        <FormControl style={{ width: '100%' }}>
                                                                                                            <RadioGroup value={mallShipping[merchantId].selected_rate.key} onChange={(event) => handleMallShippingFee(event, merchantId)} >
                                                                                                                {Object.values(mallShipping[merchantId].rate_list).map((row, key) => {
                                                                                                                    return (
                                                                                                                        <Box key={row.key} style={{ paddingLeft: '10%' }} >
                                                                                                                            <FormControlLabel
                                                                                                                                value={row.key}
                                                                                                                                control={<Radio size='small' />}
                                                                                                                                label={
                                                                                                                                    <Typography variant="body2">
                                                                                                                                        <Grid container spacing={1} justify="center" alignItems="center">
                                                                                                                                            <Grid item xs={6}>
                                                                                                                                                <WebpImg className={styles.imgStyle} alt={row.courier_name} src={row.courier_logo} />
                                                                                                                                            </Grid>
                                                                                                                                            <Grid item xs={6}>
                                                                                                                                                {`${(shippingWallet.prefix) + ' ' + row.price}`}
                                                                                                                                            </Grid>
                                                                                                                                        </Grid>
                                                                                                                                    </Typography>
                                                                                                                                }
                                                                                                                            />
                                                                                                                        </Box>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </RadioGroup>
                                                                                                        </FormControl>
                                                                                                        : null
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Box>
                                                                                            :
                                                                                            // shipping
                                                                                            <Box alignItems="center"  style={{ padding: '0 18px', backgroundColor: 'transparent' }}>
                                                                                                <Grid container spacing={1} justify="center" >
                                                                                                    <Grid item xs={6} style={{ paddingLeft: 0, textAlign: 'left', color: theme.palette.gray.text }}>
                                                                                                        <Typography variant="body2" >{t('checkout.shippingFee')} : </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={6} style={{ color: "red", textAlign: 'right' }}>
                                                                                                        <Typography variant="body2">
                                                                                                            <NumberFormat value='0.00' decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={` ${shippingFeePrefix} `} />
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Box>
                                                                                }
                                                                            </>}
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                    <Box display="flex" flexDirection="row" alignItems="center" style={{ padding: '10px 15px 15px', backgroundColor: 'transparent', borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}>
                                                        <TextField
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            inputProps={{ name: 'name' }}
                                                            placeholder={t('checkout.remarkMsg')}
                                                            label={t('checkout.remark')}
                                                            multiline
                                                            onChange={(event) => { handleRemarkChange(event, merchantId) }}
                                                            // value={ profileFormState.name }
                                                            size="small"
                                                        />
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                        return null;
                                    })
                                    :
                                    <ListItem>
                                        <Box minHeight={100} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                                            {
                                                state.totalSelected === null ?
                                                    <>
                                                        <CircularProgress disableShrink />
                                                        <Typography variant="caption">{t('general.fetchingData')}</Typography>
                                                    </>
                                                    :
                                                    <>
                                                        <Typography variant="caption">{t('checkout.noItem')}</Typography>
                                                        <Link underline='none' to={`/cart`} component={RouterLink}>
                                                            <Button variant="contained" color="secondary">
                                                                {t('button.goCart')}
                                                            </Button>
                                                        </Link>
                                                    </>
                                            }
                                        </Box>
                                    </ListItem>
                            }
                        </List>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={styles.paymentContainer}>
                            <Grid item xs={6}>
                                <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 14 }}>{state.totalSelected}{t('checkout.productPayment')}：</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                {
                                    parseFloat(state.totalPrice).toFixed(2) > 0
                                        ?
                                        <Typography variant="caption"><NumberFormat className={styles.priceStyle} value={(parseFloat(state.totalPrice) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} /></Typography>
                                        : null
                                }
                                {
                                    _.size(state.totalWalletPrice) > 0 ?
                                        _.map(state.totalWalletPrice, (walletTotal, walletType) => {
                                            return (
                                                <Box key={walletType}>
                                                    <Typography variant="caption" className={styles.priceStyle}>
                                                        <NumberFormat className={styles.priceStyle} value={walletTotal} displayType={'text'} thousandSeparator={true} prefix={` ${walletType} `} />
                                                    </Typography>
                                                </Box>
                                            )
                                        })
                                        : null
                                }
                            </Grid>
                        </Grid>
                        <Grid container className={styles.paymentContainer}>
                            <Grid item xs={6}>
                                <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 14, color: theme.palette.gray.text }}>{t('checkout.shippingFee')}：</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                {
                                    fetchingMallShipping
                                        ?
                                        <CircularProgress disableShrink size="1.5rem" />
                                        :
                                        <Typography variant="caption"><NumberFormat className={styles.priceStyle} value={((parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup)) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} /></Typography>
                                }
                            </Grid>
                        </Grid>
                        <Grid container className={styles.paymentContainer}>
                            <Grid item xs={6}>
                                <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 14 }}>{t('checkout.totalPayment')}：</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                {
                                    fetchingMallShipping
                                        ?
                                        <CircularProgress disableShrink size="1.5rem" />
                                        :
                                        parseFloat(state.totalPrice).toFixed(2) > 0 ?
                                            <Typography variant="caption"><NumberFormat className={styles.priceStyle} value={((parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup)) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} /></Typography>
                                            : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            {/* ///// footer ///// */}
            <Box className={styles.stickyFooter} style={{ textAlign: 'right', zIndex: 1299, minHeight: 0, height: 68 }}>
                <Box style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: '0 20px', height: '100%' }}>
                    <Box style={{ paddingRight: '15px', textAlign: 'right' }}>
                        <Typography variant="overline" style={{ lineHeight: '1.0' }}>
                            {t('checkout.total')}
                        </Typography>
                        <Typography style={{ fontWeight: 'bold', lineHeight: '1.0' }}>
                            {
                                fetchingMallShipping
                                    ?
                                    <CircularProgress disableShrink size="1.5rem" />
                                    :
                                    (parseFloat(totalPayment.cash).toFixed(2)) > 0
                                        ?
                                        <NumberFormat className={styles.priceStyle} value={parseFloat(totalPayment.cash * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                        : null
                            }
                        </Typography>
                        {_.size(totalPayment.wallet_list) > 0 &&
                            _.map(totalPayment.wallet_list, (walletTotal, walletType) => {

                                let walletBalance = 0;
                                let decimal = 2;

                                if (multiWalletBalance[walletType]) {
                                    _.map(multiWalletBalance[walletType], (walletId) => {
                                        let _wallet = _.find(wallets, { 'id': walletId });
                                        if (_wallet) {
                                            walletBalance = (parseFloat(walletBalance) + parseFloat(_wallet.balance));
                                            // decimal = _wallet.decimal;
                                        }
                                    })
                                    walletBalance = parseFloat(walletBalance).toFixed(decimal);
                                } else {
                                    _.map(wallets, (details) => {
                                        let name = _.split(details.name, "|");
                                        let type = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                                        if (walletType === type) {
                                            walletBalance = details.balance;
                                            // decimal = details.decimal;
                                        }
                                    })
                                }

                                return (
                                    <Box key={walletType}>
                                        <Typography style={{ lineHeight: '1.0' }}>
                                            {/* <Box>
                                                <NumberFormat value={walletBalance} decimalScale={decimal} displayType={'text'} thousandSeparator={true} prefix={t('wallet.balance') + ' '} style={{ padding: "3px", margin: "5px", fontSize:12 }} />
                                                </Box>  */}
                                            <NumberFormat className={styles.priceStyle} value={walletTotal} displayType={'text'} thousandSeparator={true} prefix={` ${walletType} `} />
                                        </Typography>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                    <Button variant="contained" size="large" style={{ backgroundColor: state.totalSelected > 0 ? (disabledSubmit ? '#e0e2e2' : '#e53935') : '#e53935', color: state.totalSelected > 0 ? (disabledSubmit ? '#8e8d8d' : '#fff') : '#fff' }} onClick={submitOrder} disabled={state.totalSelected > 0 ? (disabledSubmit ? true : false) : true}>
                        {t('button.placeOrder')}
                    </Button>
                </Box>
            </Box>
            {/* ////// dialog- change address ///////// */}
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <p style={{margin:0}}>{t('button.changeAddress')}</p>
                    <Button variant="contained" onClick={openAddAddress} disabled={fetchingMallShipping} style={{ display: 'flex', alignItems: 'center', width: '32px', height: '32px', minWidth: '32px' }} color="secondary">
                        <Box style={{ lineHeight: '0' }}>
                            <Plus style={{ color: '#fff', lineHeight: '0' }} size={27} />
                        </Box>
                    </Button>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    
                    <Grid container spacing={1}>
                        {
                            _.size(addresses) > 0 ?
                                _.map(addresses, (addressItem) => {
                                    return (
                                        <Grid item xs={12} key={addressItem.id}>
                                            <Card style={{ color: addressItem.id === state.addressId ? 'white' : 'black', backgroundColor: addressItem.id === state.addressId ? theme.palette.button.main : 'white', height: '100%' }}>
                                                <CardHeader
                                                    action={
                                                        <IconButton aria-label="settings" style={{ zIndex: 1 }} onClick={() => openEditAddress(addressItem.id)} disabled={fetchingMallShipping} >
                                                            <Edit size={14} color={addressItem.id === state.addressId ? 'white' : 'black'} />
                                                        </IconButton>
                                                    }
                                                    style={{ paddingBottom: 0 }}
                                                />
                                                <CardActionArea onClick={() => setState({ ...state, addressId: addressItem.id })} style={{ height: '100%', marginTop: -46, paddingTop: 20, paddingBottom: 20 }} disabled={fetchingMallShipping} >
                                                    <CardContent style={{ paddingTop: 0 }}>
                                                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                                                            <Box display="flex" flexDirection="row" alignItems="center" style={{ width: 'calc(100% - 34px)' }}>
                                                                {/* <Typography variant="subtitle2">{ `${t('address.recipient')}: ${addressItem.name || '-'}` }</Typography>
                                                                        <Typography variant="subtitle2">{ `${t('address.mobile')}: ${addressItem.mobile || '-'}` }</Typography> */}
                                                                <Typography variant="subtitle2" style={{ whiteSpace: 'nowrap', fontSize: 19, maxWidth: 150, textOverflow: 'ellipsis', overflow: 'hidden', }}>{`${addressItem.name || '-'}`}</Typography>
                                                                <Typography variant="subtitle2" style={{ paddingLeft: 10, whiteSpace: 'nowrap', fontSize: 16 }}>( {`${addressItem.mobile || '-'}`} )</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Typography variant="body2" style={{ textAlign: 'justify', paddingTop: 10 }}>
                                                            {` ${addressItem.address}, ${addressItem.address2_display}, ${addressItem.city_display}, ${addressItem.state_display}, ${addressItem.zip}, ${addressItem.country_display}`}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    )
                                })
                                :
                                <Box minHeight={100} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                                    {
                                        addresses === null ?
                                            <>
                                                <CircularProgress disableShrink />
                                                <Typography variant="caption">{t('general.fetchingData')}</Typography>
                                            </>
                                            :
                                            <Typography variant="caption">{t('checkout.noAddress')}</Typography>
                                    }
                                </Box>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>
            {/* /////////////// */}
            <Dialog open={userDialog.open} fullWidth onClose={closeUserModal}>
                <DialogTitleWrapper style={{background:theme.palette.primary.main, color:'#fff'}} onClose={closeUserModal}>
                    { userDialog.type === 'buyer' ? t('checkout.editBuyerInfo') : t('checkout.editRecipientInfo') }
                </DialogTitleWrapper>
                <DialogContent dividers className={styles.dialogContentRoot}>
                <Grid container spacing={3} direction="column">
                    <Grid item>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>
                                {t(`checkout.${userDialog.type}`)} {t('profile.name')}
                            </Typography>
                            <TextField 
                                onChange={({ target }) => setUserDialog({ ...userDialog, name: target.value }) }
                                type="text" 
                                value={userDialog.name} 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>
                                {t(`checkout.${userDialog.type}`)} {t('profile.mobile')}
                            </Typography>
                            <TextField 
                                onChange={({ target }) => setUserDialog({ ...userDialog, mobile: target.value }) }
                                type="text" 
                                value={userDialog.mobile} 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>
                                {t(`checkout.${userDialog.type}`)} {t('profile.email')}
                            </Typography>
                            <TextField 
                                onChange={({ target }) => setUserDialog({ ...userDialog, email: target.value }) }
                                type="text" 
                                value={userDialog.email} 
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                </DialogContent>
                <DialogActions className={styles.dialogActionRoot}>
                    <Button autoFocus onClick={saveUserModal} color="primary">
                        {t('button.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={state.modalShow} fullWidth onClose={closeModal}>
                <DialogTitleWrapper id="form-dialog-title" onClose={closeModal}>
                    { state.mode === 'add' ? t('address.addNewAddress') : t('address.editAddress') }
                </DialogTitleWrapper>
                <DialogContent dividers style={{ padding: 30 }} className={styles.dialogContentRoot}>
                    <Grid container spacing={3} direction="column">
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{ t('address.recipient') }</Typography>
                            <TextField 
                                onChange={handleAddressChange} 
                                variant="outlined"
                                type="text" 
                                inputProps={{ name: 'name' }} 
                                value={addressFormState.name}
                                error={errorAddressState.name ? true : false}
                                helperText={errorAddressState.name}
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{ t('address.mobile') }</Typography>
                            <TextField 
                                onChange={handleAddressChange} 
                                variant="outlined"
                                type="text" 
                                inputProps={{ name: 'mobile' }} 
                                value={addressFormState.mobile}
                                error={errorAddressState.mobile ? true : false}
                                helperText={errorAddressState.mobile}
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{ t('address.email') }</Typography>
                            <TextField 
                                onChange={handleAddressChange} 
                                variant="outlined"
                                type="text" 
                                inputProps={{ name: 'email' }} 
                                value={addressFormState.email}
                                error={errorAddressState.email ? true : false}
                                helperText={errorAddressState.email}
                            />
                        </FormControl>
                        { shouldUploadIC && 
                            <>
                                <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                    <Typography>{ t('address.identity_no') }</Typography>
                                    <TextField 
                                        onChange={handleAddressChange} 
                                        variant="outlined"
                                        type="text" 
                                        inputProps={{ name: 'identity_no' }} 
                                        value={addressFormState.identity_no}
                                        error={errorAddressState.identity_no ? true : false}
                                        helperText={errorAddressState.identity_no}
                                    />
                                </FormControl>
                                <FormControl fullWidth variant="outlined" className={styles.formControl}>
                                    <Typography variant="body2">{ t('checkout.chinaRecipientNeedIC') }</Typography>
                                    <Box display="flex" flexDirection="row">
                                        <input hidden accept="image/*" type="file" ref={icFrontRef} name="icFront" onChange={handleAddressChange} />
                                        <input hidden accept="image/*" type="file" ref={icBackRef} name="icBack" onChange={handleAddressChange} />
                                        <Button
                                            className={styles.uploadButtonContainer}
                                            onClick={() => icFrontRef.current.click() }
                                            variant="outlined"
                                            // startIcon={ addressFormState.icFront ? <Avatar src={ addressFormState.icFront } /> : <ArtTrackOutlined /> }
                                        >
                                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                                { addressFormState.icFront ? 
                                                    <img src={ addressFormState.icFront } alt="ic front" />
                                                : <ArtTrackOutlined fontSize="large" /> }
                                                <Typography variant="body2">{ t('checkout.icFront') }</Typography>
                                            </Box>
                                        </Button>
                                        <Button
                                            className={styles.uploadButtonContainer}
                                            onClick={() => icBackRef.current.click() }
                                            variant="outlined"
                                            // startIcon={ addressFormState.icBack ? <Avatar src={ addressFormState.icBack } /> : <AspectRatioOutlined /> }
                                        >
                                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                                { addressFormState.icBack ? 
                                                    <img src={ addressFormState.icBack } alt="ic back" />
                                                : <AspectRatioOutlined fontSize="large" /> }
                                                <Typography variant="body2">{ t('checkout.icBack') }</Typography>
                                            </Box>
                                        </Button>
                                    </Box>
                                    <Typography variant="caption" color="error">{errorAddressState.icFront}</Typography>
                                    <Typography variant="caption" color="error">{errorAddressState.icBack}</Typography>
                                </FormControl> 
                            </>
                        }
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{ t('address.country') }</Typography>
                            <Select
                                native
                                value={addressFormState.country}
                                onChange={handleAddressChange}
                                inputProps={{ name: 'country' }}
                                error={errorAddressState.country ? true : false}
                            >
                                <option value="">{ t('address.pleaseSelect') }{ t('address.country') }</option>
                                { _.map(countries, countryItem => (
                                    <option key={countryItem.code} value={countryItem.code}>{ countryItem.name_display }</option>
                                ) ) }
                            </Select>
                            <FormHelperText>{errorAddressState.country}</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{ t('address.zip') }</Typography>
                            <TextField 
                                inputProps={{ name: 'zip' }} 
                                onChange={handleAddressChange} 
                                type="text" 
                                variant="outlined"
                                value={addressFormState.zip} 
                                error={errorAddressState.zip ? true : false}
                                helperText={errorAddressState.zip}
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{ t('address.state') }</Typography>
                            { _.includes(addressGroup.stateGroup, addressFormState.country) ?
                                <>
                                    <Select
                                        native
                                        value={addressFormState.state}
                                        onChange={handleAddressChange}
                                        inputProps={{ name: 'state' }}
                                        error={errorAddressState.state ? true : false}
                                    >
                                        <option value="">{ t('address.pleaseSelect') }{ t('address.state') }</option>
                                        {
                                            _.map(addressState, stateItem => {
                                                return (
                                                    <option key={stateItem.id} value={stateItem.id}>{ stateItem.name_display }</option>
                                                )
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>{errorAddressState.state}</FormHelperText>
                                </>
                                : 
                                <TextField 
                                    onChange={handleAddressChange} 
                                    type="text" 
                                    variant="outlined"
                                    inputProps={{ name: 'state' }} 
                                    value={addressFormState.state}
                                    error={errorAddressState.state ? true : false}
                                    helperText={errorAddressState.state}
                                />
                            }
                        </FormControl>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{ t('address.city') }</Typography>
                            { _.size(cities) > 0 ?
                                <>
                                    <Select
                                        native
                                        value={addressFormState.city}
                                        onChange={handleAddressChange}
                                        inputProps={{ name: 'city' }}
                                        error={errorAddressState.city ? true : false}
                                    >
                                        <option value="">{ t('address.pleaseSelect') }{ t('address.city') }</option>
                                        {
                                            _.map(cities, cityItem => {
                                                return (
                                                    <option key={cityItem.id} value={cityItem.id}>{ cityItem.name_display }</option>
                                                )
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>{errorAddressState.city}</FormHelperText>
                                </>
                                :
                                <TextField 
                                    onChange={handleAddressChange} 
                                    type="text" 
                                    variant="outlined"
                                    inputProps={{ name: 'city' }} 
                                    value={addressFormState.city}
                                    error={errorAddressState.city ? true : false}
                                    helperText={errorAddressState.city}
                                />
                            }
                        </FormControl>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{ t('address.address2') }</Typography>
                            { _.size(district) > 0 ?
                                <>
                                    <Select
                                        native
                                        value={addressFormState.address2}
                                        onChange={handleAddressChange}
                                        inputProps={{ name: 'address2' }}
                                        error={errorAddressState.address2 ? true : false}
                                    >
                                        <option value="">{ t('address.pleaseSelect') }{ t('address.address2') }</option>
                                        {
                                            _.map(district, districtItem => {
                                                return (
                                                    <option key={districtItem.id} value={districtItem.id}>{ districtItem.name_display }</option>
                                                )
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>{errorAddressState.address2}</FormHelperText>
                                </>
                                : 
                                <TextField 
                                    onChange={handleAddressChange} 
                                    type="text" 
                                    variant="outlined"
                                    inputProps={{ name: 'address2' }} 
                                    value={addressFormState.address2}
                                    error={errorAddressState.address2 ? true : false}
                                    helperText={errorAddressState.address2}
                                />
                            }
                        </FormControl>
                        <FormControl fullWidth variant="outlined" className={styles.formControl}>
                            <Typography>{ t('address.address') }</Typography>
                            <TextField 
                                onChange={handleAddressChange} 
                                type="text" 
                                variant="outlined"
                                inputProps={{ name: 'address' }} 
                                value={addressFormState.address}
                                error={errorAddressState.address ? true : false}
                                helperText={errorAddressState.address}
                                multiline
                                rows={3}
                            />
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions className={styles.dialogActionRoot}>
                    <Button variant="contained" onClick={submitAddress} color="secondary">
                        { state.mode === 'add' ? t('button.add') : t('button.update') }
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    </Box>
);
}

const DialogTitleWrapper = (props) => {
const { children, onClose, ...other } = props;
const classes = useStyles();

return (
    <DialogTitle disableTypography className={classes.dialogTitleWrapperRoot} {...other} >
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseOutlined style={{color:'#fff'}}/>
            </IconButton>
        ) : null}
    </DialogTitle>
);
};

const useStyles = makeStyles(theme => ({
breadcrumbRoot: {
    padding: '10px 0 10px 0',
    backgroundColor: 'light',
},
root: {
    padding: '30px 0 10px 0',
},
icon: {
    color: `${theme.palette.button.step} !important `,
},
priceStyle: {
    fontSize: 15,
    color: theme.palette.price.main,
    fontWeight: 'bold'
},
paymentContainer: { 
    paddingLeft: 5, 
    paddingRight: 5,
},
whiteText: {
    fill: '#fff'
},
stepperStyle: {
    backgroundColor: 'transparent',
    padding: 0,
},
stickyFooter: {
    width: '100%',
    maxWidth: 420,
    position: 'fixed',
    // Top: 'calc(5% + 60px)',
    bottom: 0,
    backgroundColor:'#f2f2f2',
    boxShadow: '0 -5px 11px 0 #0000001a',
    zIndex: 1,
    minHeight: 70,
},
itemBoxStyle: {  
    borderColor: theme.palette.gray.border,
    overflow: 'hidden'
},
firstItemBoxStyle: {  
    marginBottom: 20, 
    borderColor: theme.palette.gray.border,
    overflow: 'hidden'
},
formControl: {
    padding: 5
},
dialogContentRoot: {
    padding: theme.spacing(2),
},
dialogActionRoot: {
    margin: 0,
    padding: theme.spacing(1),
},
dialogTitleWrapperRoot: {
    margin: 0,
    padding: theme.spacing(2),
},
closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
},
uploadButtonContainer: {
    flex: 1,
    margin: theme.spacing(1),
    padding: theme.spacing(1)
},
imgStyle: {
    height: '100%',
    width: '100%'
},
root2: {
    // padding: '10px 0px 30px 0px',
    background: theme.palette.white.mobileBkg,
    // padding:'0 0 80px',
    minHeight: '100%'
},
container:{
    padding:'0 15px'
},
shippingLine: {
    height: 3,
    width: '100%',
    backgroundPositionX: '-30px',
    backgroundSize: '116px 3px',
    backgroundImage: 'repeating-linear-gradient(45deg,#0f386b,#5388c9 33px,transparent 0,transparent 41px,#e91d0e 0,#f15b50 74px,transparent 0,transparent 82px)',

}
}));
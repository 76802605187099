import React  from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Container, Link, Box} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import TitleBarWhite from './Layouts/TitleBarWhite';
import useNotificationLoading from '../helper/useNotificationLoading';
import './css/styles.css';

export default function TermOfUse() {
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    setLoading(false);

    return (
        <Box>
            <div className='mobile-width-fixed'>
                {/* <TitleBarWhite height={0} title={t('support.termOfUse')}  back  displayInfo={false} /> */}
            </div>
            <div style={{ textAlign: 'center', padding: '60px 0 0' }}>
                <Typography style={{ fontSize: '28px', color: '#777' }}>{t('title.migrate')}</Typography>
            </div>

            <div style={{ backgroundColor: '#fff', padding: '5px 0', color: '#666', lineHeight: '1.7' }}>
                <Container>
                    <p>{t('general.migrate')}</p>
                </Container>
            </div>
        </Box>
    );
}

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// import { loginUser } from '../helper/ApiAction';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { authSuccess, authFail, updateShareLinkRef ,changeLanguage as reduxChangeLanguage } from '../actions';
import { makeStyles, useTheme,  } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider, MuiThemeProvider } from '@material-ui/core/styles';


import { Card, CardHeader, CardContent, Divider, TextField, Button, Container, Grid, Box, Typography, Link, MenuItem, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { postUrl, getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';
import WebpImg from './Layouts/WebpImg';
import { BACKEND_URL, CLIENT_SECRET } from '../configs/Config';
import axios from 'axios';
import jwt_decode from "jwt-decode";

import { FiChevronLeft } from 'react-icons/fi';

function useInput({ type, label, val, helperText, disabled=false }) {
    const [value, setValue] = useState(val ? val : "");
    const [error, setErrorValue] = useState("");
    const input = 
    <Grid container spacing={3} justify="center">
        <Grid item xs={12}>
        <MuiThemeProvider theme={fieldStyle}>
            <TextField fullWidth style={{color:'#fff'}} label={label} error={error ? true : false}  helperText={error} variant="filled"  value={value} onChange={e => setValue(e.target.value)} type={type} InputProps={{ readOnly: disabled }}/>
            {helperText && <FormHelperText style={{color:'#EAEAEA'}}>{helperText}</FormHelperText>}
            </MuiThemeProvider>
        </Grid>
    </Grid>;                            
    return [value, input, setErrorValue, setValue];
}

function useSelect({ data, label, val, disabled=false }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid container spacing={3} justify="center">
            
            <Grid item xs={12}>
            <MuiThemeProvider theme={fieldStyle}>
                <TextField
                    select
                    label={label}
                    value={value}
                    onChange={({ target }) => setValue(target.value)}
                    variant="filled"
                    fullWidth
                    error={error ? true : false}
                    helperText={error}
                    disabled={disabled}
                >
                    {data.map((option, key) => (
                        <MenuItem key={key} value={option.value} style={{color:'#222'}}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                </MuiThemeProvider>
            </Grid>
        </Grid>
    return [value, select, setErrorValue, setValue];
}

function useCheckbox({ label, val, note='' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const checkbox =
        <Grid item xs={12} sm={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={e => setValue(e.target.checked)}
                        color="primary"
                    />
                }
                label={label}
            />
            <Typography variant="body2" >{ note }</Typography>
        </Grid>;
    return [value, checkbox, setErrorValue];
}

export default function Register(){
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    let history = useHistory();
    const query = useQuery();
    const encoded_ref_id = query.get('r');
    const queryPlacement = query.get('placement') ? query.get('placement') : "";
    const queryPosition = query.get('position') ? query.get('position') : 0;
    const queryName = query.get("name") ? query.get("name") : "";
    const queryUsername = query.get("username") ? query.get("username") : "";
    const queryMobile = query.get("mobile") ? query.get("mobile") : "";
    const queryNric = query.get("nric") ? query.get("nric") : "";
    const queryEmail = query.get("email") ? query.get("email") : "";
    const source = query.get("src") ? query.get("src") : "";
    const sourceUserId = query.get("uid") ? query.get("uid") : "";

    const isMountedRef = useRef(null);

    const placementPosition = [{value: 0, label: t("register.placementPosition.no")}, {value: 1, label: t("register.placementPosition.left")}, {value: 2, label: t("register.placementPosition.right")}];
    const [name, nameInput, nameValidate] = useInput({ type: "text", label: t('register.name'), val: queryName });
    const [email, emailInput, emailValidate] = useInput({ type: "text", label: t('register.email'), val: queryEmail});
    const [mobile, mobileInput, mobileValidate] = useInput({ type: "text", label: t('register.mobile'), val: queryMobile});
    const [nric, nricInput, nricValidate] = useInput({ type: "text", label: t('register.nric_passport'), val: queryNric});
    const [username, usernameInput, usernameValidate] = useInput({ type: "text", label: t('register.username'), val: queryUsername });
    const [referral, referralInput, referralValidate, setReferral] = useInput({ type: "text", label: t('register.referral') });
    const [placement, placementInput, placementValidate] = useInput({ type: "text", label: t('register.placement'), val: queryPlacement, disabled: true });
    const [position, positionInput, positionValidate] = useSelect({ data: placementPosition, label: t('register.position'), val: queryPosition, disabled: true });
    const [autoPlacement, autoPlacementInput, autoPlacementValidate] = useCheckbox({ label: t('register.autoPlacement'), val: queryPlacement ? false : true });
    const [password, passwordInput, passwordValidate] = useInput({ type: "password", label: t('register.password'), helperText: t('profile.passwordValidation') });
    const [passwordConfirmation, passwordConfirmationInput, passwordConfirmationValidate] = useInput({ type: "password", label: t('register.passwordConfirmation') });

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const validate = { name: nameValidate, email: emailValidate, mobile: mobileValidate, nric: nricValidate, password: passwordValidate, password_confirmation: passwordConfirmationValidate, username: usernameValidate, referral: referralValidate, placement: placementValidate, position: positionValidate, autoPlacement: autoPlacementValidate };
    
    const { accessToken, linkReferralCode } = useSelector(state => state.general);
    const [refId, setRefId] = useState("");

    useEffect(() => {
        if(accessToken) {
            history.replace('/');
        }
    }, [accessToken, history]);

    useEffect(() => {
        isMountedRef.current = true;
        if(encoded_ref_id) {
            getUrl(`ref_username/${encoded_ref_id}`).then(response => {
                if (isMountedRef.current) {
                    if(response.data.ref_id){
                        setRefId(response.data.ref_id);
                        setReferral(response.data.ref_username);
                    }
                }
    
            }).catch(error => {
                // addAlert(JSON.stringify(error.message));
            });
        }
        return () => { isMountedRef.current = false };
    }, [encoded_ref_id]);

    useEffect(() => {
        if(!encoded_ref_id && linkReferralCode){
            history.replace(`register?r=${linkReferralCode}`);
        }
        if(encoded_ref_id == linkReferralCode) {
            dispatch(updateShareLinkRef(""));
        }
        // eslint-disable-next-line
    }, [encoded_ref_id, linkReferralCode]);

    const loginUser = () => {
        setLoading(true);
        const loginState = {
            grant_type: 'password',
            client_id: '4',
            client_secret: CLIENT_SECRET,
            username: username,
            password: password,
            scope: '*',
        }
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.post(`${BACKEND_URL}/oauth/token`, loginState)
        .then((response) => {
            setLoading(false);
            if(response.status === 200) {
                const decoded_jwt = jwt_decode(response.data.access_token);
                const userRole = decoded_jwt ? decoded_jwt.role : [];
                if(_.size(_.intersection(userRole, ['member']))){
                    dispatch(authSuccess(response.data));
                }else{
                    addAlert(t('general.incorrectUsernamePassword'));
                }
            }
        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                addAlert(t('general.incorrectUsernamePassword'));
            }else if (error.response && error.response.status === 401) {
                let errorResponse = JSON.parse(error.request.response);
                addAlert(errorResponse.message);
            }else{
                addAlert(JSON.stringify(error.message));
            }            
            dispatch(authFail());         
        }); 
    }

    const linkUser = () => {
        setLoading(true);
        let params = {
            username: username,
            source: source,
            source_user_id: sourceUserId,
            source_username: queryUsername,
        }
        postUrl('account-link/register', params).then(response => {
            setLoading(false);
            if(response.status){
                loginUser();
            }else{
                if(response.error){
                    addAlert(JSON.stringify(_.map(response.error).join(' ')));
                }else{
                    addAlert(JSON.stringify(response.message));
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const handleSubmit = (evt) => {
        setLoading(true);
        evt.preventDefault();

        setErrorMessage("");
        _.map(validate, (valid) => {
            valid("");
        })
        let postData = {
            name,
            email,
            username,
            mobile,
            nric,
            // referral,
            placement: autoPlacement?'':placement,
            position:autoPlacement?null:(position>0?position:null),
            auto_placement: autoPlacement?1:0,
            password,
            password_confirmation: passwordConfirmation
        }
        
        if(refId){
            postData.ref_id = refId;
        }else{
            postData.referral = referral;
        }
        postUrl(`register`, postData)
        .then((response) => {
            // console.log(response);
            setLoading(false);
            let {status, message, error} = response;
            if(status === 0){
                setErrorMessage(message);
                _.map(error, (value, key) => {
                    validate[key](value[0]);
                })
            }else{
                setSuccessMessage(message);
                // linkUser();
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }
    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };

    return(
        <Box style={{background: 'rgb(0,0,0)', position:'absolute',left:0,right:0, paddingBottom:120,
            background: 'linear-gradient(29deg, rgba(0,0,0,1) 44%, rgba(117,72,0,1) 100%)'}}>
            {/* <img src={`/images/register_event/register-${i18n.language}.png`} style={{width:'100%'}} alt="registerevent"/> */}
            <div style={{padding:25, paddingTop:0 }}>
            <Link underline='none' to="/login" component={RouterLink} >
                <Box style={{color: theme.palette.silver.text, paddingLeft:20, paddingTop:20, display:'flex', alignItems:'center', position:'absolute', top:0}}>
                    <FiChevronLeft style={{marginRight:10, color:'#fff'}}/>
                    <Typography style={{color:'#fff'}}>{t('button.login')}</Typography>
                </Box>
            </Link>
            <Card style={{boxShadow:'none', border:'none', background:'transparent'}}>
                {/* <center><WebpImg src="/images/logo-mistore.png" style={{ width:'20%', paddingLeft: 10, paddingRight: 10 }} alt="logo" /></center> */}
                <Typography style={{fontSize:21, textAlign:'center',fontWeight:'bold', textTransform:'uppercase', color:'#eaeaea'}}>
                    {t('title.register')}
                </Typography>
                {/* <Divider /> */}
                <CardContent style={{ padding: 20 }}>
                    {
                        successMessage ?
                            <Grid container spacing={2} justify="center" align="center">
                                <Grid item xs={12}>
                                    <CheckCircleOutlineIcon style={{ color: 'green' }} fontSize="large" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4" style={{ textAlign: 'center' }}>{successMessage}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Link style={{ textDecoration: 'none' }} to="/login" component={RouterLink} >
                                        <Button /* onClick={() => loginUser(dispatch)} */ fullWidth variant="contained" color="#fff" size="large">{t('button.login')}</Button>
                                    </Link>
                                </Grid>
                            </Grid>
                            :
                            <Box>
                                <Typography style={{ fontSize: 12, color: 'red', textAlign: 'center' }}>{errorMessage}</Typography>
                                <form onSubmit={handleSubmit}>
                                    {nameInput}
                                    {emailInput}
                                    {mobileInput}
                                    {nricInput}
                                    {usernameInput}
                                    {!refId ? referralInput
                                        :
                                        <Grid container spacing={3} justify="center">
                                            <Grid item xs={12}>
                                            <MuiThemeProvider theme={fieldStyle}>
                                                <TextField style={{color:'#fff', borderColor:'#fff'}} fullWidth label={t('register.referral')} variant="filled" value={referral} type="text" InputProps={{ readOnly: true }} />
                                            </MuiThemeProvider>
                                            </Grid>
                                        </Grid>
                                    }
                                    {/* {autoPlacementInput} */}
                                    {
                                        autoPlacement ? null :
                                            <>
                                                {placementInput}
                                                {positionInput}
                                            </>
                                    }
                                    {passwordInput}
                                    {passwordConfirmationInput}
                                    <Grid container spacing={3} justify="center" style={{padding:'0 15px'}}>
                                        <Button type="submit" fullWidth variant="contained" color="secondary" style={{ borderRadius:8, marginTop:30, padding:'10px 15px'}} size="large">{t('button.register')}</Button>
                                    </Grid>
                                    <Grid container spacing={3} justify="center" style={{marginTop:20,padding:'0 15px'}}>
                                        <Link underline='none' onClick={() => changeLanguage('en')} component={RouterLink} style={{marginRight: 5 }}>
                                            <Typography>{t('Eng')}</Typography> 
                                        </Link>
                                        <Typography style={{color:'#FFFFFF'}}>{t('|')}</Typography> 
                                        <Link underline='none' onClick={() => changeLanguage('cn')} component={RouterLink} style={{marginLeft: 5 }}>
                                            <Typography>{t('中文')}</Typography>
                                        </Link>
                                    </Grid>
                                </form>
                            </Box>
                    }
                </CardContent>
            </Card>
            </div>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0 10px 0',
        backgroundColor: 'white',
    }
}));

// const textStyles = createMuiTheme({
//     overrides: {
//       MuiOutlinedInput: {
//         root: {
//           "& $notchedOutline": {
//             borderColor: "#fff",
//             backgroundColor:'##fff3'
//           },
//           "&:hover $notchedOutline": {
//             borderColor: "red"
//           },
//           "&$focused $notchedOutline": {
//             borderColor: "purple"
//           }
//         }
//       },
//       MuiInputBase: {
//         root: {
//             color:'#fff'
//         }
//       }
//     }
//   });

    const textFieldStyle = createMuiTheme();
    const fieldStyle = createMuiTheme({
        overrides: {
            MuiInputBase: {
                root: {
                    color: "#fff",
                    "&$focused": {
                        color: '#ffdd39'
                        },
                        '&.$Mui-disabled': {
                            color: '#ffffff54'
                          }
                },
               
            },
            MuiFormControl: {
                root: {
                    backgroundColor:'#fff5',
                    borderRadius:'8px 8px 0 0',
                    "&:hover": {
                        backgroundColor:'#fff7',
                    },
                    "&$focused": {
                        backgroundColor:'#fff',
                    },
                },
            },
            MuiFormLabel: {
                root: {
                    color:'#EAEAEA',
                    "&$focused": {
                        color: '#ffdd39'
                        },
                    '&.$Mui-disabled': {
                        color: '#ffffff54'
                        }
                }
            },
            MuiFilledInput: {
                underline: {
                    '&:after': {
                        borderBottomColor: '#ffdd39',
                      },
                }
            },
            MuiOutlinedInput: {
                root: {
                    "& $notchedOutline": {
                    borderColor: "transparent",
                    backgroundColor:'#fff3'
                    },
                    "&:hover $notchedOutline": {
                    borderColor: 'transparent'
                    },
                    "&$focused $notchedOutline": {
                    borderColor: 'transparent'
                    }
                }
                },
        },
    });








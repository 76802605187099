import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles, Box, useTheme, Typography, Paper, TextField, Button, Select, InputLabel, FormControl, MenuItem } from '@material-ui/core';
import { RedoOutlined } from '@material-ui/icons';

import TitleBar from './Layouts/TitleBar';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { currencyFormat } from '../helper/Tools'
import { storeWalletBalance } from '../actions';

const DEFAULT_ERROR_FIELD = { amount: "", wallet: "", remark: "", security_password: "" };

export default function CapConvert() {
    const [walletInfo, setWalletInfo] = useState([]);
    const [convertWalletInfo, setConvertWalletInfo] = useState([]);
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [state, setState] = useState({ amount: "", wallet: "", remark: "", security_password: "", dateTime: "", id: "" });
    const [errorField, setErrorField] = useState(DEFAULT_ERROR_FIELD);
    const convertWalletList = ["PS"];
    const [convertStatus, setConvertStatus] = useState("insert"); // insert -> review -> result
    const [maxcap, setMaxcap] = useState(0);

    const classes = useStyles();
    const { t } = useTranslation();
    const { wallet } = useParams();
    const { walletList } = useSelector(state => ({
        walletList: state.wallet.walletList,
    }));
    const theme = useTheme();
    const { addAlert } = useNotificationLoading();
    const history = useHistory();
    const dispatch = useDispatch();

    const isMountedRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;
        refetchMaxcap();

        return () => isMountedRef.current = false;
    }, [maxcap, addAlert]);

    useEffect(() => {
        if (state.wallet && walletList) {
            const selectedWallet = _.find(walletList, { code: state.wallet });
            setConvertWalletInfo(selectedWallet);
        }
    }, [state.wallet, walletList]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        if (name === 'amount') {
            if (parseFloat(value) > 0) {
                const patt = /^\d+\.{0,1}\d{0,2}$/;
                newValue = patt.test(newValue) ? newValue : state.amount;
            }
        }

        setState({ ...state, [name]: newValue });
    }

    const reviewConvert = () => {
        let error = false;
        let newErrorField = errorField;
        _.map(["amount", "security_password"], field => {
            if (_.size(state[field]) === 0) {
                error = true;
                newErrorField[field] = t('convert.isRequired', { field: t(`convert.${field}`) });
            } else {
                newErrorField[field] = "";
            }
        })

        setErrorField(errorField => ({ ...errorField, newErrorField }));
        if (!error) {
            setConvertStatus('review')
        }
    }

    const convertUser = () => {
        const { wallet, amount, remark, security_password } = state;
        const convertData = {
            to_wallet_id: convertWalletInfo.id,
            amount,
            remark,
            security_password,
        };

        postUrl(`user/cap_convert`, convertData).then(response => {
            // console.log("response", response)
            if (response.status) {
                setConvertStatus('result');
                setState({ ...state, id: response.transaction_id });
                setErrorField(DEFAULT_ERROR_FIELD);
                refetchMaxcap();
            } else {
                if (response.errors) {
                    let newErrorField = errorField;
                    _.map(response.errors, (errorMsg, errorField) => {
                        newErrorField[errorField] = errorMsg[0];
                    })
                    setErrorField(newErrorField);
                }
                if (response.data) {
                    addAlert(JSON.stringify(response.data));
                }
                setConvertStatus('insert')
            }
        }).catch(error => {
            setConvertStatus('insert')
            addAlert(JSON.stringify(error.message));
        })
    }

    const refetchMaxcap = () => {
        getUrl('user/total/profit_limit').then(response => {
            if (response.data && isMountedRef.current) {
                setMaxcap(response.data);
            }
        })
        .catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }

    return (
        <Box className={classes.root} style={{ background: theme.palette.white.mobileBkg, padding: '0 0 80px', minHeight: '100%' }}>

            <TitleBar height={70} title={t('title.walletConvert')} displayInfo currencyButton displayCart back pagetitle />
            <div className='fixed-header-padding'>
            <Box style={{ background:theme.palette.primary.main, paddingBottom:70 }} display="flex" alignItems="center" justifyContent="center" >
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography className={classes.balanceStyle}>{currencyFormat(maxcap)}</Typography>
                    <Typography className={classes.infoStyle} style={{ color: '#fff', fontSize: 13 }}>{t('convert.convertableBalance')}</Typography>
                </Box>
            </Box>
            <Paper elevation={3} classes={{ root: classes.paperContainer }}>
                {convertStatus === 'result' ?
                    <Box className={classes.boxContainer}>
                        <Typography variant="h6" style={{ color: theme.palette.secondary.main }}>{t('convert.successfullyConvert')}</Typography>
                        <Box paddingY={2} paddingX={1} display="flex" width="100%" flexDirection="row" >
                            <Box flex={1}>
                                <Typography variant="h6" style={{ color: theme.palette.silver.text, fontSize: 16 }}>{t('convert.from')}</Typography>
                                <Typography variant="h6" style={{ color: theme.palette.silver.text, fontSize: 16 }}>{t('convert.to')}</Typography>
                            </Box>
                            <Box flex={2}>
                                <Typography variant="h6" style={{ color: theme.palette.silver.text, fontSize: 16 }}><span style={{ fontWeight: 'bold' }}>{currencyFormat(state.amount)}</span> {t('package.reentryCap')}</Typography>
                                <Typography variant="h6" style={{ color: theme.palette.secondary.main, fontSize: 20 }}><span style={{ fontWeight: 'bold' }}>{currencyFormat(state.amount)}</span> {_.size(convertWalletInfo) ? convertWalletInfo.wallet_name : ''}</Typography>
                            </Box>
                        </Box>
                        <Box paddingY={2} paddingX={1} display="flex" width="100%" flexDirection="column" borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
                            <Typography variant="h6" style={{ fontSize: 12 }}>{t('convert.remark')}</Typography>
                            <Typography variant="h6" style={{ fontSize: 12, wordWrap: "break-word" }}>{state.remark}</Typography>
                        </Box>
                        {/* <Box paddingY={2} paddingX={1} display="flex" width="100%" flexDirection="row" borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
                            <Box paddingRight={1}>
                                {<Typography variant="h6" style={{ fontSize: 12 }}>{t('convert.dateTime')}</Typography>}
                                <Typography variant="h6" style={{ fontSize: 12 }}>{t('convert.idNo')}</Typography>
                            </Box>
                            <Box flex={1}>
                                <Typography variant="h6" style={{ fontSize: 12 }}>{state.dateTime}</Typography>
                                <Typography variant="h6" style={{ fontSize: 12 }}>{state.id}</Typography>
                            </Box>
                        </Box> */}
                    </Box>
                    : <Box className={classes.boxContainer}>
                        <Typography variant="h6" style={{ color: theme.palette.secondary.main }}>{t('convert.convertAmount')}</Typography>
                        <Box paddingY={2} paddingX={1} display="flex" width="100%" flexDirection="row">
                            <Box flex={1} className={classes.boxContainer}>
                                <Typography variant="h6" style={{ color: theme.palette.silver.text, fontSize: 18 }}></Typography>
                                <Typography variant="h6" style={{ color: theme.palette.silver.text, fontSize: 24, fontWeight: 'bold' }}>{currencyFormat(parseFloat(_.size(state.amount) ? state.amount : '0'))}</Typography>
                            </Box>
                            <Box className={classes.boxContainer}>
                                <RedoOutlined style={{ color: theme.palette.secondary.main }} />
                            </Box>
                            <Box flex={1} className={classes.boxContainer}>
                                <Typography variant="h6" style={{ color: theme.palette.secondary.main, fontSize: 18 }}>{state.wallet}</Typography>
                                <Typography variant="h6" style={{ color: theme.palette.secondary.main, fontSize: 24, fontWeight: 'bold' }}>{currencyFormat(parseFloat(_.size(state.amount) ? state.amount : '0'))}</Typography>
                            </Box>
                        </Box>
                    </Box>}
            </Paper>
            {convertStatus !== 'result' && <Box paddingY={2} paddingX={2} marginTop={2} width="100%">
                <TextField
                    type="text"
                    variant="outlined"
                    value={state.amount}
                    name="amount"
                    onChange={handleChange}
                    classes={{ root: classes.textFieldRoot }}
                    InputProps={{ readOnly: convertStatus === 'review' ? true : false }}
                    label={t('convert.convertAmount')}
                    fullWidth
                    error={_.size(errorField.amount) ? true : false}
                    helperText={errorField.amount}
                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                />
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="wallet-to-convert">{t('convert.wallet')}</InputLabel>
                    <Select
                        labelId="wallet-to-convert"
                        value={state.wallet}
                        name="wallet"
                        onChange={handleChange}
                        label={t('convert.wallet')}
                        readOnly={convertStatus === 'review' ? true : false}
                    >
                        {_.map(convertWalletList, (walletItem, index) => (
                            <MenuItem key={index} value={walletItem}>{walletItem}</MenuItem>
                        ))}
                    </Select>
                    <span style={{ fontSize: 12, color: 'red' }}>{errorField.wallet}</span>
                </FormControl>

                <TextField
                    type="text"
                    variant="outlined"
                    value={state.remark}
                    name="remark"
                    onChange={handleChange}
                    classes={{
                        root: classes.textFieldRoot
                    }}
                    InputProps={{
                        readOnly: convertStatus === 'review' ? true : false,
                    }}
                    label={t('convert.remark')}
                    fullWidth
                    multiline
                    rowsMax={5}
                    error={_.size(errorField.remark) ? true : false}
                    helperText={errorField.remark}
                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                />

                <TextField
                    type="password"
                    variant="outlined"
                    value={state.security_password}
                    name="security_password"
                    onChange={handleChange}
                    classes={{
                        root: classes.textFieldRoot
                    }}
                    InputProps={{
                        readOnly: convertStatus === 'review' ? true : false,
                    }}
                    label={t('convert.security_password')}
                    fullWidth
                    error={_.size(errorField.security_password) ? true : false}
                    helperText={errorField.security_password}
                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                />
            </Box>}
            <Box className={classes.boxContainer}>
                {convertStatus === 'insert' && <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={reviewConvert}>
                    {t('convert.convertNow')}
                </Button>}
                {convertStatus === 'review' && <Box display="flex" width="100%" flexDirection="row" justifyContent="space-around">
                    <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={() => setConvertStatus('insert')}>
                        {t('convert.changeDetail')}
                    </Button>
                    <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={convertUser}>
                        {t('convert.confirmConvert')}
                    </Button>
                </Box>}
                {convertStatus === 'result' && <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={() => history.push('/myPackage')}>
                    {t('convert.complete')}
                </Button>}
            </Box>
            </div>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80
    },
    paperContainer: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: -58,
        padding: 10,
        borderRadius: 20
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        padding: '0 10px',
    },
    balanceStyle: {
        fontSize: 30,
        color: "white",
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    infoStyle: {
        color: theme.palette.secondary.white,
        fontSize: 11,
        letterSpacing: 1
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 15,
    },
    buttonRoot: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 20,
        marginTop: 15,
        marginBottom: 30
    },
}));

const styles = {
    inputPropsStyle: {
        textAlign: 'center',
        fontSize: 36,
        fontWeight: 'bold',
    }
}